import { useState } from "react";

import InvoiceItemEdit from "./InvoiceItemEdit";
import JobcardModal from "./JobcardModal";

export default function DocumentLines({ service }: any) {
  const [isVisible, setVisible] = useState(false);

  return (
    <form>
      <div className="border border-black  my-2 text-xs">
        <div className="grid grid-cols-12 border-b  border-black  bg-gray-200">
          <div className="col-span-4 p-1 font-bold border-r border-black  text-left">
            Description
          </div>
          <div className="col-span-1 p-1 font-bold border-r border-black  text-center">
            Qty
          </div>
          <div className="col-span-2 p-1 font-bold border-r border-black  text-center">
            Unit Price
          </div>
          <div className="col-span-1 p-1 font-bold border-r border-black  text-center">
            Unit
          </div>
          <div className="col-span-1 p-1 font-bold border-r border-black  text-center">
            Disc
          </div>
          <div className="col-span-2 p-1 font-bold border-r border-black  text-center">
            Amount
          </div>
          <div className="col-span-1 p-1 font-bold  text-right"></div>
        </div>
        <div className="">
          {service?.lines?.map((item: any, index: number) => (
            <InvoiceItemEdit
              style={{ color: "red" }}
              key={index}
              index={index}
              service_id={service?._id}
              item={item}
            />
          ))}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          className="flex justify-center items-center border border-gray-400 p-1 mb-2 rounded-sm text-sm focus:outline-none"
          onClick={() => setVisible(true)}
        >
          + Add Item
        </button>
        <JobcardModal
          isVisible={isVisible}
          setVisible={setVisible}
          service_id={service?._id}
        />
      </div>
    </form>
  );
}
