import { useState } from "react";
import ConvWidget from "./ConvWidget";
import { handleFetchLeadStatusReport } from "../../../../api/reports";
import { useQuery } from "@tanstack/react-query";
import StatusWidget from "./StatusWidget";
import LeadPanel from "./LeadPanel";

export default function TopWrapper({ dates }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const { data: leads } = useQuery({
    queryKey: ["leadStatusReport"],
    queryFn: handleFetchLeadStatusReport,
  });

  const handleSelect = ({ item }: any) => {
    setSelected(item);
    setVisible(true);
  };
  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-2">
        <ConvWidget leads={leads} />
      </div>
      {leads?.map((item: any, index: number) => (
        <StatusWidget item={item} key={index} handleSelect={handleSelect} />
      ))}

      <LeadPanel
        title="LEADS BY STATUS"
        subtitle={selected?._id}
        leads={selected?.list || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
