import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useState } from "react";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import Manage from "..";
import CreateContact from "./components/CreateContact";
import UpdateContact from "./components/UpdateContact";
import { postDataMail } from "../../../services/mail";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { handleFetchContacts } from "../../../api/contacts";
import { useNavigate } from "react-router-dom";

export default function AllContacts() {
  const [selected, setSelected] = useState<any>({});
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);

  const { data, refetch, isLoading, isFetching, isError } = useQuery({
    queryKey: ["contacts"],
    queryFn: handleFetchContacts,
  });

  const [emailForm] = Form.useForm();

  const handleRefetch = () => {
    refetch();
  };

  const navigate = useNavigate();

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.name ? record?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Mobile",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.mobile ? record?.mobile : "-"}
          </span>
        );
      },
    },
    {
      title: "Email",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.email ? record?.email : "-"}
          </span>
        );
      },
    },
    {
      title: "TIN",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.tin ? record?.tin : "-"}
          </span>
        );
      },
    },
    {
      title: "Status",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.isExisting ? "Existing Customer" : "New Customer"}
          </span>
        );
      },
    },
    {
      title: "Category",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.isCompany ? (
              <div className="">Company</div>
            ) : (
              <div className="">Individual</div>
            )}
          </span>
        );
      },
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const mapped = data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Email: item?.email === null ? "-" : item?.email,
        Address: item?.address === null ? "-" : item?.address,
        TIN: item?.tin === null ? "-" : item?.tin,
        Category: item?.isCompany ? "Organization" : "Individual",
      };
    });

    const raw = getExportData(mapped);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  return (
    <Manage
      title={"Contacts"}
      subtitle={"All"}
      description={"List of all contacts"}
      actions={
        <div className="flex">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  data?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Name: item?.name === null ? "-" : item?.name,
                      Mobile: item?.mobile === null ? "-" : item?.mobile,
                      Email: item?.email === null ? "-" : item?.email,
                      Address: item?.address === null ? "-" : item?.address,
                      TIN: item?.tin === null ? "-" : item?.tin,
                      Category: item?.isCompany ? "Organization" : "Individual",
                    };
                  }),
                  "Contacts.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
        </div>
      }
    >
      <>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="grid gap-x-0 grid-cols-12">
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <Table
                size="small"
                dataSource={data === undefined ? [] : data}
                columns={columns}
                scroll={{ y: 760, x: 0 }}
                rowKey="id"
                onRow={(record) => {
                  return {
                    onClick: () => {
                      navigate(`/manage/crm/contacts/${record?._id}`);
                    },
                  };
                }}
                rowClassName={"hover:cursor-pointer hover:bg-red-50"}
                loading={isLoading}
              />

              <CreateContact
                isVisible={createModal}
                setVisible={setCreateModal}
                refetch={handleRefetch}
              />

              <UpdateContact
                isVisible={updateModal}
                setVisible={setUpdateModal}
                contact={selected}
              />
            </div>
          </div>
        )}
      </>
    </Manage>
  );
}
