import { Button, Modal, Form, Input, message, Row, Col, Select } from "antd";
import { useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import getBase64 from "../../../../utils/getBase64";
import { handleUploadVehicleDocument } from "../../../../api/vehicles";

const { Option } = Select;

export default function AddDocument({ isVisible, setVisible, chassis }: any) {
  const queryClient = useQueryClient();
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [isOther, setIsOther] = useState<boolean>(false);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSelect = (e: any) => {
    if (e === "Others") {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUploadVehicleDocument,
    onSuccess: () => {
      message.success("Uploaded Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
      setVisible(false);
      clearForm()
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { description, category, otherCategory } = form.getFieldsValue();
    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const data = {
      description,
      category: isOther ? otherCategory : category,
      document: base64,
    };
    await mutate({ data, chassis });
  };

  const categories = [
    { id: 1, name: "Import Documents" },
    { id: 2, name: "Local Document" },
    { id: 3, name: "Tax Invoice" },
    { id: 4, name: "Warrant Certificate" },
    { id: 5, name: "Terms & Condition" },
    { id: 6, name: "Gate Pass" },
    { id: 7, name: "Others" },
  ];

  const categoryOptions = categories?.map((location: any, index: number) => (
    <Option value={location?.name} key={index}>
      {location?.name}
    </Option>
  ));

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  return (
    <Modal
      open={isVisible}
      title={`Vehicle Document :  [ ${chassis} ]`}
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="AddVehicleDocument"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="AddVehicleDocument"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="category"
              label={"Category"}
              rules={[
                {
                  required: false,
                  message: "Please enter category",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select category"
                optionFilterProp="children"
                onSelect={(e) => handleSelect(e)}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {categoryOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {isOther && (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="otherCategory"
                label={"Other Category ( Specify )"}
                rules={[
                  {
                    required: false,
                    message: "Please enter category",
                  },
                ]}
              >
                <Input type="text" placeholder="Category" />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label={"Description ( Optional )"}
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <Input type="text" placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <BsCheck2Circle color={"#16a34a"} size={30} />
                <div className="text-gray-600 mt-2">Document Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Document"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Document"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
