import Moment from "react-moment";
import RecordItem from "./RecordItem";
import { RiFileExcel2Line } from "react-icons/ri";
import { Tooltip as Tip } from "antd";

export default function LeadSources({ dates }: any) {
  const data = Array(0).fill({
    id: 1,
    name: "Won",
    count: 7,
    value: 3500,
  });

  const consultants: any = [
    // { id: 1, name: "Bakari Mwapachu", deals: data },
    // { id: 2, name: "Neema Makelemo", deals: data },
  ];

  //   const { data, refetch, isLoading, isFetching, isError } = useQuery({
  //     queryKey: ["dealSources"],
  //     queryFn: handleFetchDealSourcesReport,
  //   });

  return (
    <div className="border bg-white text-gray-500 mb-2">
      <div className="flex flex-row justify-between items-center  border-b border-gray-200 pl-2">
        <div className="py-2">
          <h4 className="text-gray-800">
            <span className="text-gray-900 font-bold text-lg">
              Leads By Source
            </span>
          </h4>
          <p className="text-gray-600 text-sm">
            Lead Sources by Sales Consultant
          </p>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center text-gray-500 text-xs">
            {dates?.isRange ? (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment> -{" "}
                <Moment format={"Do MMM, YYYY"}>{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment>
              </div>
            )}
          </div>
          <div className="mx-2">
            <Tip title="Export To Excel">
              <button
                color="#E50000"
                className="flex justify-center items-center border px-4 py-1 hover:bg-gray-100"
                // onClick={() => exportStockByFuel({ data: stock })}
              >
                <RiFileExcel2Line size={18} />
              </button>
            </Tip>
          </div>
        </div>
      </div>
      <div className="p-2">
        {consultants?.map((consultant: any, index: number) => (
          <div className="mb-2">
            <div className="text-black">
              {index + 1}. {consultant?.name}
            </div>
            <div className="ml-6">
              {data?.map((item: any, index: number) => (
                <RecordItem item={item} index={index} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
