import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchDealStages = async () => {
  const uri = `api/crm/stages`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleFetchdealStages = async () => {
  const uri = `api/crm/deals/pipeline`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateDealStage = async (data: any) => {
  const uri = `api/crm/stages`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateDealStage = async ({ data, _id }: any) => {
  const uri = `api/crm/stages/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleReorderDealStage = async ({ data }: any) => {
  const uri = `api/support/pipeline`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteDealStage = async ({ _id }: any) => {
  const uri = `api/crm/stages/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchDealStages,
  handleCreateDealStage,
  handleUpdateDealStage,
  handleDeleteDealStage,
  handleReorderDealStage,
  handleFetchdealStages,
};
