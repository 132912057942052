import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchAppointments = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/appointments?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchAllAppointments = async () => {
  const uri = `api/crm/appointments/get/all`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleFetchNewAppointments = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/appointments/new?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDisAppointments = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/appointments/discarded?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchConAppointments = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/appointments/contacted?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleFetchArcAppointments = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/appointments/archived?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchAppointment = async ({ _id }: any) => {
  if (_id === undefined) {
    return {};
  }
  const uri = `api/crm/appointments/${_id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateAppointment = async (data: any) => {
  const uri = `api/crm/appointments`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleAssignAppointment = async ({ data, inquiry_id }: any) => {
  const uri = `api/crm/appointments/${inquiry_id}/assign`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleRespondAppointment = async ({ data, _id }: any) => {
  const uri = `api/crm/appointments/${_id}/respond`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateInquiry = async ({ data, _id }: any) => {
  const uri = `api/crm/appointments/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteInquiry = async ({ _id }: any) => {
  const uri = `api/crm/appointments/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchAppointments,
  handleFetchAllAppointments,
  handleFetchAppointment,
  handleAssignAppointment,
  handleRespondAppointment,
  handleCreateAppointment,
  handleUpdateInquiry,
  handleDeleteInquiry,


  handleFetchNewAppointments,
  handleFetchDisAppointments,
  handleFetchConAppointments,
  handleFetchArcAppointments,
};
