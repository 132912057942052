import { useQuery } from "@tanstack/react-query";
import Conversion from "./Conversion";
import DealWidget from "./DealWidget";
import { handleFetchDealStatusReport } from "../../../api/reports";

export default function DealsWrapper({ dates }: any) {
  const { data: deals } = useQuery({
    queryKey: ["dealStatusReport"],
    queryFn: handleFetchDealStatusReport,
  });

  return (
    <div className="grid grid-cols-12 gap-2 mt-2.5">
      {deals?.map((item: any, index: number) => (
        <div className="col-span-12 sm:col-span-2">
          <DealWidget item={item} dates={dates} key={index} />
        </div>
      ))}
      <div className="col-span-12 sm:col-span-2">
        <Conversion deals={deals} />
      </div>
    </div>
  );
}
