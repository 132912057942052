import {
  Button,
  Form,
  Row,
  Col,
  message,
  Select,
  Drawer,
  Space,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleCreateLead } from "../../../../api/leads";
import { handleFetchContacts } from "../../../../api/contacts";
import { handleFetchAllVehicles, handleFetchUnfilteredVehicles, handleFetchVehiclesBySource } from "../../../../api/vehicles";
import { handleFetchCrmSources } from "../../../../api/crmSource";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import formatMoney from "../../../../utils/formatMoney";
import roundTZS from "../../../../utils/roundTZS";
import { RiDeleteBin4Line } from "react-icons/ri";
import { GoCheck } from "react-icons/go";
import { FiEdit, FiUserPlus } from "react-icons/fi";
import { handleFetchAllInquiries, handleFetchInquiries } from "../../../../api/inquiries";
import moment from "moment";
import Moment from "react-moment";
import CreateContact from "../../../stock/components/CreateContact";

const { Option } = Select;

export default function CreateLeadPanel({ isVisible, setVisible }: any) {
  const { auth } = useContext(AuthContext);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<any>([]);
  const [picked, setPicked] = useState<any>(null);
  const [source, setSource] = useState<String>("");

  const [selectedVat] = useState<number>(18);

  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleCreateLead,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      clearForm();
      queryClient.invalidateQueries({ queryKey: ["newLeads"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: contacts } = useQuery({
    queryKey: ["contacts"],
    queryFn: handleFetchContacts,
  });

  const { data: stock, refetch } = useQuery({
    queryKey: ["sourceVehicles", { source_id: source }],
    queryFn: handleFetchUnfilteredVehicles,
  });

  const { data: sources } = useQuery({
    queryKey: ["crmSources"],
    queryFn: handleFetchCrmSources,
  });

  const { data: inquiries } = useQuery({
    queryKey: ["allInquiries"],
    queryFn: handleFetchAllInquiries,
  });

  const contactOptions = contacts?.map((contact: any, index: number) => (
    <Option value={contact?._id} key={index}>
      {contact?.name}
    </Option>
  ));

  const inquiryOptions = inquiries?.map((inquiry: any, index: number) => (
    <Option value={inquiry?._id} key={index}>
      {`
       Inquiry: ${inquiry?.name} , Created :
      ${moment(inquiry?.createdAt).format("Do MMM, YYYY")}, Requirement : 
      ${inquiry?.requirements || ""}
      `}
    </Option>
  ));

  const vehicleOptions = stock?.vehicles?.map((vehicle: any, index: number) => (
    <Option value={vehicle?.chassis} key={index}>
      {`${index + 1}. ${vehicle?.year} ${vehicle.model?.name} - Chassis No:  ${vehicle.chassis
        } ( ${vehicle?.source?.name} )`}
    </Option>
  ));

  const sourceOptions = sources?.map((vehicle: any, index: number) => (
    <Option value={vehicle?._id} key={index}>
      {vehicle?.name}
    </Option>
  ));

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const extracted = vehicles.map((item: any) => item.chassis);
    const data = {
      ...values,
      value: Number(net),
      user: auth?._id,
      vehicles: extracted,
    };
    await mutate(data);
    setVisible(false);
  };

  const [contactModal, setContactModal] = useState<boolean>(false);

  const clearForm = async () => {
    form.resetFields();
  };

  const handleAddVehicle = async () => {
    if (picked === null) {
      return;
    }
    const v = stock?.vehicles?.filter((item: any) => item?.chassis === picked);
    addVehicle(v[0]);
    if (!source) {
      setSource(v[0]?.source?.entity?._id);
      await refetch();
    }
  };

  const handleRemVehicle = async (item: any) => {
    remVehicle(item);
  };

  const addVehicle = async (item: any) => {
    try {
      let a = vehicles;
      if (a.some((e: any) => e.chassis === item?.chassis)) {
        message.error("Vehicle already Added");
        return;
      } else {
        a.push(item);

        setVehicles(a);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const remVehicle = async (item: any) => {
    try {
      const filtered = vehicles.filter((i: any) => i.chassis !== item?.chassis);
      setVehicles(filtered);
    } catch (e) {
      console.error(e);
    }
  };

  const getTotal = () => {
    let total = 0;
    if (vehicles) {
      vehicles.forEach((vehicle: any) => {
        total = total + vehicle?.prices?.exclusive;
      });
    }
    return total;
  };

  const getCalculations: any = () => {
    let discount = 0;
    let vat = 0;
    let unit = 0;
    let net = 0;
    let exchange = 0;

    const total = getTotal();
    if (vehicles) {
      vat = ((total - discount) * selectedVat) / 100;

      exchange = vehicles[0]?.source?.exchange;

      unit = total - discount;

      net = unit + vat;
    }

    return { total, discount, vat, unit, net, exchange };
  };

  const { vat, unit, net, exchange } = getCalculations();

  useEffect(() => { }, [vehicles]);
  return (
    <Drawer
      title={`CREATE LEAD `}
      placement={"right"}
      width={window.innerWidth > 1200 ? "48%" : "auto"}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="createLead"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="contact"
              label="Contact"
              rules={[
                {
                  required: true,
                  message: "Please select contact",
                },
              ]}
            >
              {/* <Select
                showSearch
                placeholder="Search and Select Contact"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {contactOptions}
              </Select> */}

              <Col xs={{ span: 24 }} lg={{ span: 19 }}
                style={{
                  display: "flex",
                  gap: 10
                }}
              >
                <Form.Item
                  name="contact"
                  rules={[
                    {
                      required: true,
                      message: "Please select contact",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search and select contact"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contactOptions}
                  </Select>
                </Form.Item>

                <Button onClick={() => setContactModal(true)}>
                  <FiUserPlus />
                </Button>
              
              </Col>
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="source"
              label="Lead Source"
              rules={[
                {
                  required: true,
                  message: "Please select source",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and Select Source"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {sourceOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="inquiry"
              label="Inquiry"
              rules={[
                {
                  required: false,
                  message: "Please select inquiry",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and Select Inquiry"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {inquiryOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="mb-8 border-b">
          <div className="flex justify-between w-full p-2 bg-gray-100 items-center border-b">
            <div className="">Vehicles</div>
            <Button
              onClick={() => setEditMode(!editMode)}
              className="border px-2 bg-white"
            >
              {editMode ? (
                <GoCheck color="#16a34a" />
              ) : (
                <FiEdit color="#ca8a04" />
              )}
            </Button>
          </div>

          {editMode && (
            <div className="mt-4">
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 20 }} lg={{ span: 22 }}>
                  <Form.Item
                    name="chassis"
                    rules={[
                      {
                        required: true,
                        message: "Please select vehicle",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search and select vehicle"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(e: any) => {
                        setPicked(e);
                      }}
                    >
                      {vehicleOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                  <Button
                    onClick={() => {
                      handleAddVehicle();
                      setPicked(null);
                    }}
                    type="primary"
                    htmlType="button"
                    className="bg-gray-400"
                  >
                    <AiOutlinePlus />
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          <div className="">
            <div className="border-b flex justify-between bg-gray-50 p-2">
              <div className="flex">
                <div className="">S/n. </div>
                <div className="ml-2">Vehicle</div>
              </div>

              <div className="flex">
                <div className="w-40 text-right"></div>
                <div className="w-32 text-right">Amount ( USD )</div>
                <div className="w-36 text-right">Amount ( TZS )</div>
                {editMode && <div className="w-12 ml-2">Action</div>}
              </div>
            </div>
            {vehicles?.map((item: any, index: number) => (
              <div className="border-b flex justify-between p-2" key={index}>
                <div className="flex">
                  <div className="">{index + 1}. </div>
                  <div className="ml-2">
                    {item?.model?.name} - {item?.chassis}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-40 text-left"></div>
                  <div className="w-32 text-right">
                    {formatMoney(item?.prices?.exclusive)}
                  </div>
                  <div className="w-36 text-right">
                    {roundTZS(item?.prices?.exclusive * exchange)}
                  </div>

                  {editMode && (
                    <div className="w-12 ml-2">
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() => handleRemVehicle(item)}
                          className="border p-1 rounded-md ml-2 bg-red-500"
                          type="button"
                        >
                          <RiDeleteBin4Line color={"#FFF"} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="border-t  flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Sub Total</div>
              <div className="w-32 text-right">{formatMoney(unit)}</div>
              <div className="w-36 text-right">
                {roundTZS(unit * exchange || 0)}
              </div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>

            <div className="border-t border-gray-300 flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Vat ( {selectedVat} %)</div>
              <div className="w-32 text-right">{formatMoney(vat)}</div>
              <div className="w-36 text-right">
                {roundTZS(vat * exchange || 0)}
              </div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>

            <div className="border-t-2 border-b border-gray-600  flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Grand Total</div>
              <div className="w-32 text-right">{formatMoney(net)}</div>
              <div className="w-36 text-right">
                {roundTZS(net * exchange || 0)}
              </div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>
          </div>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit">
            Create
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>

      <CreateContact isVisible={contactModal} setVisible={setContactModal} />
    </Drawer>
  );
}
