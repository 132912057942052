import formatMoney from "../../../../utils/formatMoney";

export default function RecordItem({ item, index, handleViewDeals }: any) {
  return (
    <div className="border-b py-2 px-4 flex justify-between items-center">
      <div className="">
        {index + 1}. {item?.name}
      </div>
      <div className="flex">
        <span className="text-right mr-4">{item?.count} Proformas</span>
        <span className="font-bold w-32">
          $ {formatMoney(Number(item?.value))} /=
        </span>
        <button
          onClick={() => {
            handleViewDeals(item);
          }}
          className="flex justify-center items-center border bg-gray-200 px-4 py-1 text-xs ml-2 hover:bg-green-200 focus:outline-none"
        >
          View Proforma
        </button>
      </div>
    </div>
  );
}
