import StatusWidget from "./StatusWidget";

export default function StatusWrapper({ dates }: any) {
  const data = Array(6).fill({ id: 1 });

  //   const { data, refetch, isLoading, isFetching, isError } = useQuery({
  //     queryKey: ["dealAgeing"],
  //     queryFn: handleFetchDealAgeingReport,
  //   });

  return (
    <div className="grid grid-cols-12 gap-2 mb-2">
      {data?.map((item: any, index: number) => (
        <div className="col-span-12 md:col-span-2" key={index}>
          <StatusWidget item={item} key={index} />
        </div>
      ))}
    </div>
  );
}
