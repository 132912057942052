import Wrap from "./Wrap";
import Something from "../../components/shared/Something";
import ServiceItem from "./components/ServiceItem";
import ServiceSearch from "./components/ServiceSearch";
import { useQuery } from "@tanstack/react-query";
import { handleFetchPendingServices } from "../../api/services";
import NoRecords from "../../components/shared/NoRecords";

export default function PendingServices() {
  const {
    data: services,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["pendingServices"],
    queryFn: handleFetchPendingServices,
  });

  return (
    <Wrap active="Pending" actions={<></>}>
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <div className="">
          <ServiceSearch />
          {services?.length === 0 ? (
            <NoRecords />
          ) : (
            <div className="grid grid-cols-12 gap-2 p-2">
              {services?.map((item: any, index: number) => (
                <ServiceItem key={index} service={item || {}} />
              ))}
            </div>
          )}
        </div>
      )}
    </Wrap>
  );
}
