import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function Services() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/services/all");
  });
  return <div className="h-screen flex"></div>;
}
