import { AiOutlineUnorderedList } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Wrap({ children, actions, active }: any) {
  const menus = [
    { id: 1, name: "All", link: "/services/all" },
    { id: 2, name: "Pending", link: "/services/pending" },
    { id: 3, name: "Rejected", link: "/services/rejected" },
    { id: 4, name: "Inprogress", link: "/services/inprogress" },
    { id: 5, name: "Completed", link: "/services/completed" },
  ];
  return (
    <div className="">
      <div className="border-b  bg-white flex justify-between px-2">
        <div className="flex items-center">
          {menus.map((menu: any, index: number) => (
            <Link
              to={menu?.link}
              key={index}
              className={`flex items-center mr-6 text-sm py-3 ${
                active === menu?.name
                  ? "border-b border-automark-dark text-black"
                  : "border-b border-white text-gray-600"
              }`}
            >
              <AiOutlineUnorderedList />
              <span className="ml-1">{menu?.name}</span>
            </Link>
          ))}
        </div>
        <div className="flex items-center">{actions}</div>
      </div>
      <div className="">{children}</div>
    </div>
  );
}
