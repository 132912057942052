import { useContext } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function Wrap({ children, child, actions, active }: any) {
  const { auth } = useContext(AuthContext);
  const menus = [
    {
      id: 1,
      name: "Inquiries",
      link: "/crm/inquiries/new",
      access: [0, 1, 2, 4],
    },
    { id: 2, name: "Leads", link: "/crm/leads/new", access: [0, 2, 4] },
    { id: 3, name: "Deals", link: "/crm/deals/new", access: [0, 2, 4] },
    { id: 4, name: "Contacts", link: "/crm/contacts", access: [0, 1, 2, 3, 4] },
    { id: 5, name: "Suppliers", link: "/crm/suppliers", access: [0, 2, 4] },
  ];
  return (
    <div className="">
      <div className="border-b  bg-white flex justify-between px-2">
        <div className="flex items-center">
          {menus.map((menu: any, index: number) => {
            if (menu?.access?.includes(auth?.accessLevel)) {
              return (
                <Link
                  to={menu?.link}
                  className={`flex items-center mr-4 text-sm py-3 ${
                    active == menu?.name ? "border-b border-automark-dark" : ""
                  }`}
                  key={index}
                >
                  <AiOutlineUnorderedList />
                  <span className="ml-1">{menu?.name}</span>
                </Link>
              );
            }
          })}
        </div>
        <div className="flex items-center">{actions}</div>
      </div>
      <div className="">{children}</div>
      <div className="">{child}</div>
    </div>
  );
}
