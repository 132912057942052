import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div className="flex flex-col items-center mt-32">
      <div className="font-bold text-6xl">404</div>
      <div className="">Page Not Found</div>
      <Link to={"/"}>
        <div
          className="border px-8 py-2 rounded-md bg-automark-dark text-white mt-4"
          style={{ fontSize: 12 }}
        >
          Go Home
        </div>
      </Link>
    </div>
  );
}
