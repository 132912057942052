import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { handleUpdateDealTitle } from "../../../../api/dealTitles";

export default function UpdateDealTitle({
  isVisible,
  setVisible,
  selected,
}: any) {
  const [isActive, setActive] = useState<boolean>(selected?.isActive);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateDealTitle,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["dealTitles"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
      isActive,
    };
    await mutate({ data, _id: selected?._id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [selected, form]);

  return (
    <Modal
      open={isVisible}
      title="Update Deal Title"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateDealTitle"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateDealTitle"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="title"
              label="Deal Title"
              initialValue={selected?.title}
              rules={[
                {
                  required: true,
                  message: "Please enter deal title",
                },
              ]}
            >
              <Input type="" placeholder="Deal Title" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Checkbox
              onChange={(e: any) => {
                setActive(e.target.checked);
              }}
              defaultChecked={isActive}
            >
              Is Active
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
