import FeatureItem from "../../pages/manage/vehicles/components/FeatureItem";
import { formatNumber } from "../../utils/formatNumber";

export default function FeatureSetOne({ vehicle }: any) {
  return (
    <div className="">
      <div className="border-t-4 mt-1"></div>
      <FeatureItem title={"Chassis"} detail={vehicle?.chassis || "-"} />
      <FeatureItem
        title={"Registration"}
        detail={vehicle?.registration || "-"}
      />
      <FeatureItem title={"Model"} detail={vehicle?.model?.name || "-"} />
      <FeatureItem title={"Year"} detail={vehicle?.year || "-"} />
      <FeatureItem title={"Vsb"} detail={vehicle?.vsb || "-"} />
      <FeatureItem
        title={"Mileage"}
        detail={formatNumber(vehicle?.mileage || 0)}
      />
      <FeatureItem
        title={"Exterior Color"}
        detail={vehicle?.colors?.exterior || "-"}
      />
      <FeatureItem
        title={"Interior Color"}
        detail={vehicle?.colors?.interior || "-"}
      />

      <FeatureItem
        title={"Auction Grade"}
        detail={vehicle?.auctionGrade || "-"}
      />
    </div>
  );
}
