import NoResults from "../../../../components/shared/NoResults";
import pdf_avatar from "../../../../assets/images/pdf.png";
import formatSize from "../../../../utils/formatSize";
import { BsDownload, BsEye, BsEyeFill } from "react-icons/bs";
import { useState } from "react";
import AddDocument from "./AddDocument";

export default function Documents({ documents, chassis, editable }: any) {
  const [documentModal, setDocumentModal] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>("");
  return (
    <div className="mb-8">
      <div className="border-b border-t px-4 py-2 flex justify-between bg-gray-100">
        <div className=""> Documents </div>
        {editable && (
          <button
            onClick={() => setDocumentModal(true)}
            className="border px-2 rounded-md"
          >
            +
          </button>
        )}
      </div>
      <div className="border-t-4"></div>

      <div className="px-4">
        {documents?.length === 0 ? (
          <div className="mt-12">
            <NoResults title={"No Documents"} />
          </div>
        ) : null}
        {documents?.map((document: any, index: number) => (
          <div className="flex justify-between mt-2" key={index}>
            <div className="flex">
              <div className="mr-2">
                <img
                  src={
                    document.filetype === "application/pdf"
                      ? pdf_avatar
                      : pdf_avatar
                  }
                  alt="Logo"
                  height={25}
                  width={18}
                />
              </div>
              <div className="flex flex-col justify-center">
                <div className="">{document?.category}</div>
                <div className="text-xs">{formatSize(document?.filesize)}</div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              {document?.filetype === "application/pdf" ? (
                <a
                  href={`${document?.url}`}
                  target="_blank"
                  className="ml-4 hover:text-green-600 text-black"
                >
                  <BsDownload />
                </a>
              ) : (
                <>
                  {preview?._id === document?._id ? (
                    <button onClick={() => setPreview("")} className="">
                      <BsEyeFill color="#16a34a" />
                    </button>
                  ) : (
                    <button onClick={() => setPreview(document)} className="">
                      <BsEye />
                    </button>
                  )}
                  <a
                    href={`${document?.url}`}
                    target="_blank"
                    className="ml-4 hover:text-green-600 text-black"
                  >
                    <BsDownload />
                  </a>
                </>
              )}
            </div>
          </div>
        ))}
      </div>

      <AddDocument
        isVisible={documentModal}
        setVisible={setDocumentModal}
        chassis={chassis}
      />
    </div>
  );
}
