import { Button, Modal, Form, Row, Col, Select, Input, message } from "antd";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchRoles } from "../../../../api/roles";
import { handleUpdateUser } from "../../../../api/users";

const { Option } = Select;

export default function UpdateUser({ isVisible, setVisible, selected }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: handleFetchRoles,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateUser,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      setVisible(false);
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const roleOptions = roles?.map((role: any, index: number) => (
    <Option value={role?._id} key={index}>
      {role?.name}
    </Option>
  ));

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = { ...values };
    await mutate({ _id: selected?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ role: selected?.role?.entity });
    form.setFieldsValue({ accessLevel: selected?.accessLevel });
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update User"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateUser"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateUser"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={selected?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Mobile"
              initialValue={selected?.mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="role"
              label={"Designation"}
              initialValue={selected?.role}
              rules={[
                {
                  required: true,
                  message: "Please select level",
                },
              ]}
            >
              <Select placeholder="Designation">{roleOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="accessLevel"
              label={"Access Level"}
              initialValue={selected?.accessLevel}
              rules={[
                {
                  required: true,
                  message: "Please select access level",
                },
              ]}
            >
              <Select placeholder="Access Level">
                <Option value={1}>Level 1</Option>
                <Option value={2}>Level 2</Option>
                <Option value={3}>Level 3</Option>
                <Option value={4}>Level 4</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
