import { Button, Drawer, Space } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { PrinterOutlined } from "@ant-design/icons";
import { useRef } from "react";
import "../../assets/styles/custom.css";
import logo from "../../assets/images/logo-red.svg";
import formatMoney from "../../utils/formatMoney";
import ReactToPrint from "react-to-print";
import image from "../../assets/images/image.png";
import { GoCheck } from "react-icons/go";

export default function SpecSheet({ isVisible, setVisible, vehicle }: any) {
  const docRef = useRef(null);

  const prepareImages = () => {
    let f1: any = {};
    let f2: any = {};
    let b1: any = {};
    let b2: any = {};
    let s1: any = {};
    let s2: any = {};
    let i1: any = {};
    let i2: any = {};
    let i3: any = {};
    let i4: any = {};
    vehicle?.images?.forEach((item: any) => {
      switch (item?.location) {
        case "F1":
          f1 = item;
          break;
        case "F2":
          f2 = item;
          break;
        case "B1":
          b1 = item;
          break;
        case "B2":
          b2 = item;
          break;
        case "S1":
          s1 = item;
          break;
        case "S2":
          s2 = item;
          break;
        case "I1":
          i1 = item;
          break;
        case "I2":
          i2 = item;
          break;
        case "I3":
          i3 = item;
          break;
        case "I4":
          i4 = item;
          break;
        default:
      }
    });

    return {
      f1,
      f2,
      b1,
      b2,
      s1,
      s2,
      i1,
      i2,
      i3,
      i4,
    };
  };

  const { f1, f2, b1, b2, s1, s2, i1, i2, i3, i4 } = prepareImages();

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">SPEC SHEET :</span>
          </span>
          <div className="flex text-gray-500 px-2">
            [ VEHICLE - {vehicle?.chassis} ]
          </div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <ReactToPrint
            trigger={() => {
              return (
                <Button className="flex justify-start items-center focus:outline-none hover:text-red-500 w-full">
                  <PrinterOutlined /> Print
                </Button>
              );
            }}
            content={() => docRef.current}
            documentTitle={`Spec-Sheet`}
          />
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="book -mt-10" ref={docRef} id="specsheet">
        <div className="page">
          <div className="" style={{ fontSize: 11 }}>
            <div className="p-1">
              <div className="flex justify-center mt-4">
                <img src={logo} width={400} />
              </div>

              <div className="flex justify-center mt-8">
                <img
                  src={vehicle?.thumbnail || image}
                  className="w-56 aspect-[4/4] p-1 object-contain"
                  style={{ width: 400, backgroundColor: "#FFFFFF" }}
                />
              </div>

              <div className="border-t border-r border-l border-black mt-10">
                <LineItem title="Model" value={vehicle?.model?.name} />
                <LineItem
                  title="Body Type"
                  value={vehicle?.specifications?.body?.body_type}
                />
                <LineItem title="Year of Manufacturer" value={vehicle?.year} />
                <LineItem title="Mileage" value={`${vehicle?.mileage} Km`} />
                <LineItem title="Color" value={vehicle?.colors?.exterior} />
                <LineItem
                  title="Engine Type"
                  value={vehicle?.specifications?.engine?.engine_type}
                />
                <LineItem
                  title="Engine Capacity"
                  value={vehicle?.specifications?.engine?.size}
                />
                <LineItem
                  title="Fuel Type"
                  value={vehicle?.specifications?.fuel?.fuel_type}
                />
                <LineItem
                  title="Transmission"
                  value={vehicle?.specifications?.transmission}
                />
                <LineItem title="Auction Grade" value={vehicle?.auctionGrade} />
                <EmptyItem />
                <LineItem
                  title="Interior Trim"
                  value={vehicle?.specifications?.interior_trim}
                />
                <LineItem
                  title="Seating Capacity"
                  value={vehicle?.specifications?.seats}
                />
                <LineItem
                  title="No of Speakers"
                  value={vehicle?.specifications?.speakers}
                />
                <EmptyItem />
                <LineItem
                  title="Drive-Train"
                  value={vehicle?.specifications?.drive?.drive_type}
                />
                <LineItem
                  title="Tyre Size"
                  value={vehicle?.specifications?.drive_train}
                />
                <LineItem
                  title="Fuel Tank Capacity"
                  value={vehicle?.specifications?.fuel?.size}
                />
                <LineItem
                  title="Turning Radius"
                  value={vehicle?.specifications?.turning_radius}
                />
                <LineItem
                  title="Ground Clearance"
                  value={vehicle?.specifications?.ground_clearance}
                />
              </div>
              <div className="grid grid-cols-12 gap-2 mt-2">
                {vehicle?.specifications?.features?.map(
                  (item: any, index: number) => (
                    <div
                      className="col-span-3 sm:col-span-3 md:col-span-3 border border-gray-600 "
                      key={index}
                    >
                      <div className="flex items-center justify-center">
                        <GoCheck />
                        <div className="p-1 text-center">{item?.name}</div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="mt-4 font-semibold">
                <TotalItem vehicle={vehicle} />
              </div>

              <div className="mt-8">
                <div className="mb-1">
                  *Price is inclusive of all duties and taxes.
                </div>
                <div className="mb-1">
                  *Price and specifications for vehicle are subject to change
                  without prior notice.
                </div>
                <div className="mb-1">
                  *Accessories and equipment are supplied at cost upon order
                  confirmation. The image depicted above is for reference
                  purposes only. The actual vehicle might differ slightly.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="">
            <div className="grid gap-2 grid-cols-12 mb-4">
              <div className="col-span-12">
                {/* <div className="bg-gray-100 text-center mb-1 py-2">Front</div> */}
                <div className="flex">
                  <ImageItem image={f1.url} />
                  <ImageItem image={f2.url} />
                </div>
              </div>
            </div>
            <div className="grid gap-2 grid-cols-12">
              <div className="col-span-6">
                {/* <div className="bg-gray-100 text-center mb-1 py-2">Side</div> */}
                <div className="flex">
                  <ImageItem image={s1.url} />
                  <ImageItem image={s2.url} />
                </div>
              </div>
              <div className="col-span-6">
                {/* <div className="bg-gray-100 text-center p-1 mb-1 py-2">
                  Back
                </div> */}
                <div className="flex">
                  <ImageItem image={b1.url} />
                  <ImageItem image={b2.url} />
                </div>
              </div>
            </div>
          </div>
          <div className="grid gap-2 grid-cols-12">
            <div className="col-span-6">
              {/* <div className="bg-gray-100 text-center mb-1 py-2">Interiors</div> */}
              <div className="flex">
              <ImageItem image={i1.url} />
                <ImageItem image={i2.url} />
              </div>
              <div className="flex mt-1">

              <ImageItem image={i3.url} />
                <ImageItem image={i4.url} />
        
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

const LineItem = ({ title, value }: any) => {
  if (!value) return null;
  return (
    <div className="grid grid-cols-12 border-b border-black">
      <div className="col-span-8 border-r border-black px-4">{title}</div>
      <div className="col-span-4 text-center">{value}</div>
    </div>
  );
};

const EmptyItem = () => {
  return (
    <div className="grid grid-cols-12 border-b border-black">
      <div className="col-span-8 px-4 text-white">-</div>
      <div className="col-span-4 text-center text-white">-</div>
    </div>
  );
};

const TotalItem = ({ vehicle }: any) => {
  return (
    <div className="grid grid-cols-12 border border-black">
      <div className="col-span-4 px-4 border-r border-black text-center">
        Price
      </div>
      <div className="col-span-4 px-4 border-r border-black text-center">
        TZS{" "}
        {formatMoney(vehicle?.prices?.inclusive * vehicle?.source?.exchange)}
      </div>
      <div className="col-span-4 text-center">
        USD {formatMoney(vehicle?.prices?.inclusive)}
      </div>
    </div>
  );
};

const ImageItem = ({ image }: any) => {
  if (!image) return null;
  return (
    <div className="cursor-pointer">
      <img
        src={image}
        className="w-full aspect-[4/4] border p-1 object-contain"
        style={{ width: 600, backgroundColor: "#FFFFFF" }}
      />
    </div>
  );
};
