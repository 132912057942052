import { FiEdit } from "react-icons/fi";
import formatShort from "../../../../utils/formatShort";

export default function MonthBudget({
  month,
  year,
  number,
  handleUpdateMonth,
}: any) {
  return (
    <div className="col-span-12 sm:col-span-6 md:col-span-4">
      <div className="border mb-4 m-4">
        <div className="flex justify-between p-4 border-b bg-gray-100">
          <div className="font-bold text-xl">
            {number}. {month?.name} : {year}
          </div>

          <button onClick={() => handleUpdateMonth({month, number})} className="flex items-center border border-gray-300 rounded-md p-2"> <FiEdit /></button>
        </div>

        <div className="py-1  px-4">
          <div className="flex justify-between font-bold">
            <div className="">DESCRIPTION</div>
            <div className="flex items-center w-1/2">
              <div className="w-24 text-end">COUNT</div>
              <div className="w-24 text-end">VALUE</div>
            </div>
          </div>
        </div>
        <div className="px-4 pb-2">
          <div className="py-1">
            <div className="flex justify-between">
              <div className="">1. Sales</div>
              <div className="flex items-center">
                <div className="w-24 text-end">{month?.sales?.count}</div>
                <div className="w-24 text-end">
                  {formatShort(month?.sales?.value)}
                </div>
              </div>
            </div>
          </div>
          <div className="py-1">
            <div className="flex justify-between">
              <div className="">2. Leads</div>
              <div className="flex items-center">
                <div className="w-24 text-end">{month?.leads?.count}</div>
                <div className="w-24 text-end">
                  {formatShort(month?.leads?.value)}
                </div>
              </div>
            </div>
          </div>
          <div className="py-1">
            <div className="flex justify-between">
              <div className="">3. Deals</div>
              <div className="flex items-center">
                <div className="w-24 text-end">{month?.deals?.count}</div>
                <div className="w-24 text-end">
                  {formatShort(month?.deals?.value)}
                </div>
              </div>
            </div>
          </div>
          <div className="py-1">
            <div className="flex justify-between">
              <div className="">4. Proforma</div>
              <div className="flex items-center">
                <div className="w-24 text-end">{month?.proforma?.count}</div>
                <div className="w-24 text-end">
                  {formatShort(month?.proforma?.value)}
                </div>
              </div>
            </div>
          </div>
          <div className="py-1">
            <div className="flex justify-between">
              <div className="">5. Contacts</div>
              <div className="flex items-center">
                <div className="w-24 text-end">{month?.contacts}</div>
                <div className="w-24 text-end">N/A</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
