import { Link } from "react-router-dom";

export default function IMain({ children, actions, active, inCRM }: any) {
  const menus = [
    {
      id: 1,
      name: "New Inquiries",
      link: `${inCRM ? "/crm/inquiries/new" : "/inquiries"}`,
    },
    {
      id: 2,
      name: "Contacted Inquiries",
      link: `${inCRM ? "/crm/inquiries/contacted" : "/inquiries/contacted"}`,
    },
    {
      id: 3,
      name: "Appointments",
      link: `${inCRM ? "/crm/inquiries/appointments" : "/inquiries/appointments"}`,
    },
  ];
  return (
    <div className="">
      <div className="border-b  bg-white flex justify-between px-2">
        <div className="flex items-center">
          {menus.map((menu: any, index: number) => (
            <Link
              to={menu?.link}
              className={`flex items-center mr-4 text-xs py-2 ${
                active === menu?.name ? "text-automark-dark" : ""
              }`}
              key={index}
            >
              <span className="ml-1">{menu?.name}</span>
            </Link>
          ))}
        </div>
        <div className="flex items-center py-1">{actions}</div>
      </div>
      <div className="p-2">{children}</div>
    </div>
  );
}
