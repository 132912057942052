import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "antd";
import Something from "../../../components/shared/Something";
import DealColumns from "./components/DealColumns";
import Wrap from "../Wrap";
import Main from "./Main";
import { handleFetchWonDeals } from "../../../api/deals";
import { useState } from "react";
import AdvancedSearch from "../leads/components/AdvancedSearch";
import { RiSearch2Line } from "react-icons/ri";

export default function WonDeals() {
  const [advSearch, setAdvSearch] = useState<boolean>(false);

  const initials = {
    customer: "",
    consultant: "",
    model: "",
    source: "",
    status: "",
    startDate: "",
    endDate: "",
    minValue: "",
    maxValue: "",
  };
  const [filters, setFilters] = useState<any>({ ...initials });

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 24,
    total_pages: 1,
    total_count: 60,
  });

  const clearAllFilters = () => {
    setFilters({ ...initials });
  };

  const params = `&customer=${filters?.customer}&consultant=${filters?.consultant}&source=${filters?.source}&status=${filters?.status}&startDate=${filters?.startDate}&endDate=${filters?.endDate}&model=${filters?.model}&minValue=${filters?.minValue}&maxValue=${filters?.maxValue}`;
  const {
    data: deals,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["wonDeals", { pagination, params }],
    queryFn: handleFetchWonDeals,
  });

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };
    const navigate = useNavigate();

    return (
      <Wrap active="Deals" actions={<></>}>
        <Main
          active="Won Deals"
          actions={
            <Button
              className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
              onClick={() => {
                setAdvSearch(!advSearch);
              }}
            >
              <div className="flex justify-center items-center">
                <div className="mr-2">Advanced Search</div>
                <div className="border-l px-2">
                  <RiSearch2Line color="gray" size={14} />
                </div>
              </div>
            </Button>
          }
        >
          {advSearch && (
            <AdvancedSearch
              setVisible={setAdvSearch}
              clearAllFilters={clearAllFilters}
              handleAdvancedSearch={handleAdvancedSearch}
            />
          )}
          {isError ? (
            <Something refetch={refetch} isFetching={isFetching} />
          ) : (
            <div className="grid gap-x-0 grid-cols-12 border bg-white">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  size="small"
                  dataSource={deals === undefined ? [] : deals?.records}
                  columns={DealColumns}
                  scroll={{ y: 660, x: 0 }}
                  rowKey="id"
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        navigate(`/crm/deals/${record?._id}/detailed`);
                      },
                    };
                  }}
                  loading={isLoading}
                  rowClassName={"hover:cursor-pointer hover:bg-red-50"}
                />
              </div>
            </div>
          )}
        </Main>
      </Wrap>
    );
}
