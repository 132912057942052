import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  message,
  Checkbox,
  Select,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  handleCreateDealStage,
  handleFetchDealStages,
} from "../../../../api/dealStages";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

const { Option } = Select;

export default function CreateDealStage({ isVisible, setVisible }: any) {
  const [isActive, setActive] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { data: stages } = useQuery({
    queryKey: ["dealStages"],
    queryFn: handleFetchDealStages,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateDealStage,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["dealStages"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  const stageOptions = stages?.map((level: any, index: number) => (
    <Option value={level?._id} key={index}>
      {level?.name}
    </Option>
  ));

  return (
    <Modal
      open={isVisible}
      title="Create Deal Stage"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createDealStage"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createDealStage"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="" placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Checkbox
              onChange={(e: any) => {
                setActive(e.target.checked);
              }}
              defaultChecked={isActive}
            >
              Is Active
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
