import PropTypes from "prop-types";
import { FiEdit } from "react-icons/fi";
import { IoTrashBinOutline } from "react-icons/io5";

function StageLine({ index, item, handleSetUpdate, handleSetDelete }: any) {
  return (
    <div className="grid grid-cols-12 border-t justify-center items-center">
      <div className="col-span-1 p-2 h-full  border-r text-center ">
        {index + 1}
      </div>
      <div className="col-span-4 p-2 h-full  border-r">{item?.name}</div>
      <div className="col-span-6 p-2 h-full border-r">{item?.description}</div>
      <div className="col-span-1 p-2 h-full">
        <div className="flex justify-center">
          <button
            onClick={() => {
              handleSetUpdate({ item });
            }}
            type="button"
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <FiEdit />
          </button>

          <button
            onClick={() => {
              handleSetDelete({ item });
            }}
            type="button"
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <IoTrashBinOutline />
          </button>
        </div>
      </div>
    </div>
  );
}

export default StageLine;

StageLine.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
