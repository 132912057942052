import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateVehicleSpecs } from "../../api/vehicles";
import { useEffect } from "react";

const { Option } = Select;

export default function SpecsUpdate({
  isVisible,
  setVisible,
  chassis,
  vehicle,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateVehicleSpecs,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ data, chassis });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ drive_type: vehicle?.specifications?.drive?.drive_type});
    form.setFieldsValue({ body_type: vehicle?.specifications?.body?.body_type });
    form.setFieldsValue({ transmission: vehicle?.specifications?.transmission});
    form.setFieldsValue({ dimensions: vehicle?.specifications?.body?.dimensions});
    form.setFieldsValue({ engine_type: vehicle?.specifications?.engine?.engine_type});
    form.setFieldsValue({ engine_size: vehicle?.specifications?.engine?.engine_size});
    form.setFieldsValue({ fuel_type: vehicle?.specifications?.fuel?.fuel_type});
    form.setFieldsValue({ steering: vehicle?.specifications?.steering});
    form.setFieldsValue({ tank_size: vehicle?.specifications?.fuel?.size});
    form.setFieldsValue({ doors: vehicle?.specifications?.doors});
    form.setFieldsValue({ seats: vehicle?.specifications?.seats});
  }, [isVisible]);

  return (
    <Modal
      open={isVisible}
      title="Update Specs"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateVehicleSpecs"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleSpecs"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="drive_type"
              label="Drive Type"
              initialValue={vehicle?.specifications?.drive?.drive_type}
              rules={[
                {
                  required: false,
                  message: "Please select drive type",
                },
              ]}
            >
              <Select placeholder="Drive Type">
                <Option value="Front Wheel Drive">Front Wheel Drive</Option>
                <Option value="Rear Wheel Drive">Rear Wheel Drive</Option>
                <Option value="Four Wheel Drive">Four Wheel Drive</Option>
                <Option value="Two Wheel Drive">Two Wheel Drive</Option>
                <Option value="All Wheel Drive">All Wheel Drive</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="transmission"
              label="Transmission"
              initialValue={vehicle?.specifications?.transmission}
              rules={[
                {
                  required: false,
                  message: "Please enter transmission",
                },
              ]}
            >
              <Select placeholder="Transmission">
                <Option value="Automatic">Automatic</Option>
                <Option value="Manual">Manual</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="body_type"
              label="Body Type"
              initialValue={vehicle?.specifications?.body?.body_type}
              rules={[
                {
                  required: false,
                  message: "Please select body type",
                },
              ]}
            >
              <Select placeholder="Body Type">
                <Option value="Cross-Over SUV">Cross-Over SUV</Option>
                <Option value="Hatchback">Hatchback</Option>
                <Option value="Minivan">Minivan</Option>
                <Option value="Compact">Compact</Option>
                <Option value="Pick-Up">Pick-Up</Option>
                <Option value="SUV">SUV</Option>
                <Option value="Sedan">Sedan</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="engine_type"
              label="Engine Type"
              initialValue={vehicle?.specifications?.engine?.engine_type}
              rules={[
                {
                  required: false,
                  message: "Please enter engine type",
                },
              ]}
            >
              <Select placeholder="Engine Type">
                <Option value="Internal Combustion Engine (ICE)">
                  Internal Combustion Engine (ICE)
                </Option>
                <Option value="Hybrid Electric">
                  Hybrid Electric
                </Option>
          
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={[16, 0]}>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="engine_size"
              label="Engine Size (cc)"
              initialValue={vehicle?.specifications?.engine?.size}
              rules={[
                {
                  required: false,
                  message: "Please enter engine size",
                },
              ]}
            >
              <Input type="number" placeholder="Engine Size" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fuel_type"
              label="Fuel Type"
              initialValue={vehicle?.specifications?.fuel?.fuel_type}
              rules={[
                {
                  required: false,
                  message: "Please select fuel type",
                },
              ]}
            >
              <Select placeholder="Fuel Type">
                <Option value="Petrol">Petrol</Option>
                <Option value="Diesel">Diesel</Option>
                <Option value="Electric">Electric</Option>
                <Option value="Hybrid">Hybrid</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="steering"
              label="Steering"
              initialValue={vehicle?.specifications?.steering}
              rules={[
                {
                  required: false,
                  message: "Please select steering type",
                },
              ]}
            >
              <Select placeholder="Steering">
                <Option value="Left">Left</Option>
                <Option value="Right">Right</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="tank_size"
              label="Tank Size"
              initialValue={vehicle?.specifications?.fuel?.size}
              rules={[
                {
                  required: false,
                  message: "Please enter tank size",
                },
              ]}
            >
              <Input type="number" placeholder="Litres" />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={[16, 0]}>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="doors"
              label="Doors"
              initialValue={vehicle?.specifications?.doors}
              rules={[
                {
                  required: false,
                  message: "Please enter number of doors",
                },
              ]}
            >
              <Input type="number" placeholder="No of doors" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="seats"
              label="Seats"
              initialValue={vehicle?.specifications?.seats}
              rules={[
                {
                  required: false,
                  message: "Please enter number  of seats",
                },
              ]}
            >
              <Input type="number" placeholder="No of seats" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
