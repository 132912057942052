import moment from "moment";
import { ExportToExcel } from "./exportExcel";
import { capitalize } from "lodash";
import formatMoney from "./formatMoney";

const getTotal = ({ list }: any) => {
  let total = 0;
  list.forEach((item: any) => {
    total = total + item?.value;
  });

  return formatMoney(isNaN(total) ? 0 : total);
};

const exportAllDealsData = ({ data, search }: any) => {
  ExportToExcel(
    data
      ?.filter((deal: any) => deal?.contact?.name?.includes(search))
      ?.map((item: any, index: number) => {
        return {
          "S/N": index + 1,
          Contact: item?.contact?.name === null ? "-" : item?.contact?.name,
          Title: item?.title === null ? "-" : item?.title,
          Vehicles: item?.vehicles === null ? "-" : item?.vehicles?.length,
          Value:
            item?.value === null
              ? "-"
              : formatMoney(isNaN(item?.value) ? 0 : item?.value),
          "Lead Reference":
            item?.lead === null
              ? "-"
              : item?.lead?.substr(item?.lead?.length - 5),
          "Created By":
            item?.creator?.name === null ? "-" : item?.creator?.name,
          "Created At": item?.dates?.created
            ? moment(item?.dates?.created).format("DD/MM/YYYY")
            : "-",
          "Closed At": item?.dates?.closed
            ? moment(item?.dates?.closed).format("DD/MM/YYYY")
            : "-",
          "Archived At": item?.dates?.archived
            ? moment(item?.dates?.archived).format("DD/MM/YYYY")
            : "-",
          Status:
            item?.status?.state === null
              ? "-"
              : capitalize(item?.status?.state),
        };
      }),
    "Deals.xlsx"
  );
};

const exportDealsByModel = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Model: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "DealsByModel.xlsx"
  );
};

const exportDealsByStatus = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Status: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "DealsByStatus.xlsx"
  );
};

const exportDealsByConsultant = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?._id === null ? "-" : item?._id,
        Deals: item?.total === null ? "-" : item?.total,
        Value:
          item?.total === null ? "-" : getTotal({ list: item?.list || [] }),
      };
    }),
    "DealsByConsultant.xlsx"
  );
};

const exportDealsBySource = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Source: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "DealsBySource.xlsx"
  );
};

export {
  exportAllDealsData,
  exportDealsByModel,
  exportDealsByStatus,
  exportDealsByConsultant,
  exportDealsBySource,
};
