import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, message } from "antd";
import { useEffect } from "react";
import {
  handleDeleteLeadActivity,
  handleRemoveLeadVehicle,
} from "../../../../api/leads";

export default function RemoveVehicle({
  isVisible,
  setVisible,
  activity,
  lead_id,
  prepick,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleRemoveLeadVehicle,
    onSuccess: () => {
      message.success("Removed Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const data = { vehicles: prepick?.chassis };
    await mutate({ vehicle: prepick?.chassis, lead_id });
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [activity, form]);

  return (
    <Modal
      open={isVisible}
      title="Remove Vehicle"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteLeadVehicle"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        id="deleteLeadVehicle"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center justify-center my-8">
          <div>
            Are you Sure you want to
            <span className="text-automark-dark font-bold mx-1">Remove</span>
            this Vehicle
          </div>
          <div className="font-bold text-lg mt-6">{prepick?.chassis}</div>
          <div className="">{prepick?.model?.name}</div>
          <div className="">{prepick?.car?.year}?</div>
        </div>
      </Form>
    </Modal>
  );
}
