import { BsClockHistory } from "react-icons/bs";
import Moment from "react-moment";
import { IoLocationOutline } from "react-icons/io5";

export default function LocationLine({ item }: any) {
  return (
    <div className="px-4 flex">
      <div className="w-12 flex flex-col justify-center items-center">
        <div
          className="flex justify-center items-center w-8 h-8 rounded-full border"
          style={{ backgroundColor: "#E2E2E2" }}
        >
          <IoLocationOutline className="#FFF" />
        </div>
        <div className="">
          <div className="border-l border-gray-300 h-8"></div>
          <div className=""></div>
        </div>
      </div>
      <div className="flex-1">
        <div className="mt-1">
          Date: <Moment format="DD/MM/YYYY">{item?.date}</Moment>
        </div>
        <div className="flex flex-col" style={{ fontSize: 11 }}>
          <div className="flex justify-between">
            <div className="">{item?.location}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
