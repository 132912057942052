import { DatePicker, Popover, Tooltip } from "antd";
import moment from "moment";
import { ExportToExcel } from "../../../../utils/exportExcel";

import { RiFileExcel2Line } from "react-icons/ri";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { AiOutlineCheck } from "react-icons/ai";
import { AUTO_BASE_URL } from "../../../../utils/urls";
const { RangePicker } = DatePicker;

export default function ExportInquiries() {
  const { token } = useContext(AuthContext);
  const [isOpen, setOpen] = useState<boolean>(false);
  const today = moment();
  const startOfWeek = today.clone().startOf("week").add(1, "days");
  const endOfWeek = today.clone().endOf("week").add(1, "days");
  const [isLoading, setLoading] = useState<boolean>(false);
 

  const [weekRange, setWeekRange] = useState<any>([startOfWeek, endOfWeek]);

  const options = [
      { name: "Only Contacted Inquiries", code: 1 },
      { name: "Only UnContacted Inquiries" , code: 2},
      { name: "Both Contacted & UnContacted", code: 0 },
  ];

  const [option, setOption] = useState<any>({ ...options[2] });

  const handleExportInquiries = async () => {
    setLoading(true)
    const startDate = weekRange[0].format("YYYYMMDD");
    const endDate = weekRange[1].format("YYYYMMDD");

    const uri = `${AUTO_BASE_URL}/api/crm/inquiries/export/data?startDate=${startDate}&endDate=${endDate}&code=${option?.code}`;
    const result = await fetch(uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const response = await result.json();
    await ExportToExcel(
      response?.data?.map((item: any, index: number) => {
        return {
          "S/N": index + 1,
          Name: item?.name || "-",
          Mobile: item?.mobile || "-",
          Email: item?.email || "-",
          Source: item?.source || "-",
          Medium: item?.medium || "-",
          Created:
            item?.createdAt === null
              ? "-"
              : moment(item?.createdAt).format("DD/MM/YYYY"),
          Consultant: item?.assigned?.to,
          Requirements: item?.requirements,
          Interested: item?.engagement?.isInterested ? "Yes" : "No",
          Contacted: item?.engagement?.isContacted ? "Yes" : "No",
          "Require Follow Up": item?.engagement?.requireFollowUp ? "Yes" : "No",
          "Contacted At":
            item?.engagement?.contactedAt === null
              ? "-"
              : moment(item?.engagement?.contactedAt).format("DD/MM/YYYY"),
          "Contacted By": item?.engagement?.consultant?.name || "-",
          Feedback: item?.feedback,
        };
      }),
      "Inquiries.xlsx"
    );
    setLoading(false)
  };

  const contactOptions = (
    <div>
      {options?.map((item: any, index: number) => (
        <div
          className="flex flex-col justify-start items-start w-full"
          key={index}
        >
          <button
            onClick={() => {
              setOption(item);
            }}
            className="py-2 border-t focus:outline-none w-full flex items-start"
          >
            <div
              className={`${
                option?.code === item?.code ? "bg-green-600" : ""
              } w-6 h-6 border mr-2 flex items-center justify-center`}
            >
              <AiOutlineCheck color="#FFFFFF" />
            </div>
            {item?.name}
          </button>
        </div>
      ))}

    </div>
  );

  const content = (
    <div>
      <RangePicker onChange={(dates) => setWeekRange(dates)} format={"DD/MM/YYYY"} />
        <div className="my-4">

      {contactOptions}
        </div>
      
      <button
        onClick={() => handleExportInquiries()}
        className="flex justify-center items-center my-2 w-full"
      >
        <div className="py-2 px-2 bg-automark-pale text-white w-full text-center">
          {isLoading ? "Export ..." : "Export"}
        </div>
      </button>
    </div>
  );
  return (
    <div className="">
      <Tooltip title="Export To Excel">
        <Popover
          placement="bottom"
          title={"Export Inquiries"}
          content={content}
          open={isOpen}
        >
          <button
            color="#E50000"
            onClick={() => setOpen(!isOpen)}
            className="flex mx-2 justify-center items-center border px-4 py-2 hover:bg-gray-100"
          >
            <RiFileExcel2Line size={18} />
          </button>
        </Popover>
      </Tooltip>
    </div>
  );
}
