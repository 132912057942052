import Mainchart from "./components/MainChart";
import { useState } from "react";
import moment from "moment";
import DateTool from "../../components/toolbar/DateTool";
import TopModels from "./components/TopModels";
import TopCustomers from "./components/TopCustomers";
import SourceWrapper from "./components/SourceWrapper";
import DealsWrapper from "./components/DealsWrapper";
import AgedWrapper from "./components/AgedWrapper";

export default function Home() {
  const initialYear = new Date().getFullYear();
  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });
  const [year, setYear] = useState<any>(initialYear)

  const handleDateChange = ({ startDate, endDate, isRange }: any) => {
    setDates({
      startDate,
      endDate,
      isRange,
    });
  };

  const handleYearChange = ({year}: any) => {
    setYear(year)
  }

  return (
    <div className="">
      <DateTool stateHandler={handleDateChange} handleYearChange={handleYearChange} />
      <div className="p-2 h-full">
        <SourceWrapper dates={dates} />
        <DealsWrapper dates={dates} />

        <div className="grid grid-cols-12 gap-2 mt-2">
          <div className="col-span-6 bg-white  border">
            <Mainchart dealStats={[]} dates={dates} year={year} />
          </div>
          <div className="col-span-3 bg-white h-auto border">
            <TopModels dates={dates} />
          </div>
          <div className="col-span-3 bg-white h-auto border">
            <TopCustomers dates={dates} />
          </div>
        </div>

        <div className="">
          <AgedWrapper dates={dates} />
        </div>
      </div>
    </div>
  );
}
