export default function DocumentVehicle({ vehicle, service_type }: any) {
  return (
    <div className="border border-black  text-xs">
      <div className="grid grid-cols-12 gap-0">
        <div className="col-span-4 p-1 border-r border-b border-black">
          Job order description
        </div>
        <div className="col-span-4 p-1 border-r border-b  border-black">
          {service_type || "-"}
        </div>
        <div className="col-span-2 py-1 px-2 border-r border-b  border-black">
          VSB No.
        </div>
        <div className="col-span-2 p-1 border-b  border-black font-bold">
          {vehicle?.vsb || "-"}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-0">
        <div className="col-span-2 p-1 border-r border-b border-black font-bold">
          Reg No.
        </div>
        <div className="col-span-3 p-1 border-r border-b  border-black font-bold">
          Model
        </div>
        <div className="col-span-3 p-1 border-r border-b  border-black font-bold">
          Chassis
        </div>
        <div className="col-span-2 p-1 border-r border-b  border-black font-bold">
          Engine Number
        </div>

        <div className="col-span-2 p-1  border-b  border-black font-bold">
          Mileage ( Kms)
        </div>
      </div>
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-2 p-1 border-r border-black  text-center">
          {vehicle?.registration || "-"}
        </div>
        <div className="col-span-3 p-1 border-r border-black  text-left">
          {vehicle?.model?.name || "-"}
        </div>
        <div className="col-span-3 p-1 border-r border-black  px-2">
          {vehicle?.chassis || "-"}
        </div>
        <div className="col-span-2 p-1 border-r border-black  px-2">
          {vehicle?.specifications?.engine?.engine_type || "-"}
        </div>
        <div className="col-span-2 p-1  border-black  px-2">
          {vehicle?.mileage || "-"}
        </div>
      </div>
    </div>
  );
}
