export default function ConvWidget({ leads }: any) {
  const getMetrics = () => {
    let converted = 0;
    let all = 0;
    leads?.forEach((item: any) => {
      if (item?._id === "won") {
        converted = item?.total;
      }
      all = all + item?.total;
    });

    return { converted, all };
  };

  const { converted, all } = getMetrics();

  return (
    <div className="col-span-3 border border-gray-300 p-2 bg-green-100 h-full">
      <div className="">Convertion Rate - [ Leads to Deals ] </div>
      <div className="text-lg">
        <span className="text-3xl">{converted}</span> / {all} -{" "}
        <span className="font-bold">
          {Math.round((converted / all) * 100)} %
        </span>
      </div>
    </div>
  );
}
