import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchCrmSources = async () => {
  const uri = `api/crm/sources`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateCrmSource = async (data: any) => {
  const uri = `api/crm/sources`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateCrmSource = async ({ data, _id }: any) => {
  const uri = `api/crm/sources/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteCrmSource = async ({ _id }: any) => {
  const uri = `api/crm/sources/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchCrmSources,
  handleCreateCrmSource,
  handleUpdateCrmSource,
  handleDeleteCrmSource,
};
