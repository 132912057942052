import { useState } from "react";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Col, Form, Row, Select, message } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleFetchRemarks } from "../../../api/remarks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleUpdateServiceRemarks } from "../../../api/services";
import Moment from "react-moment";

const { Option } = Select;

export default function ServiceRemarks({
  service_id,
  remark,
  status,
  service,
  auth,
  serviceAccess,
}: any) {
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data: remarks } = useQuery({
    queryKey: ["remarks"],
    queryFn: handleFetchRemarks,
  });

  const { mutate } = useMutation({
    mutationFn: handleUpdateServiceRemarks,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["services", service_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const data = await form.validateFields();
    await mutate({ service_id, data });
    setEdit(false);
  };

  const remarkOptions = remarks?.map((item: any, index: number) => {
    return (
      <Option value={item?.description} key={index}>
        {item?.description}
      </Option>
    );
  });

  return (
    <div className="bg-white text-xs">
      <div className="flex justify-between items-center px-2 py-2 border-b">
        <div className="">Service Remarks</div>

        {status !== "completed" && (
          <div className="">
            {serviceAccess?.includes(auth?.accessLevel) && (
              <div className="">
                {edit ? (
                  <div className="flex">
                    <button
                      onClick={() => setEdit(false)}
                      className="flex justify-center items-center border rounded-md p-2 focus:outline-none hover:bg-red-50 text-red-800"
                    >
                      <AiOutlineClose />
                    </button>

                    <button
                      onClick={() => onSubmit()}
                      className="flex justify-center items-center border rounded-md p-2 ml-1 focus:outline-none hover:bg-green-50 text-green-800"
                    >
                      <SaveOutlined />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => setEdit(true)}
                    className="flex justify-center items-center border rounded-md p-2 focus:outline-none hover:bg-green-50"
                  >
                    <EditOutlined />
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {edit && (
        <div className="p-2">
          <Form
            id="UpdateServiceRemarks"
            form={form}
            name="normal_login"
            className="login-form"
            onFinish={onSubmit}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="remark"
                  initialValue={remark?.id}
                  rules={[
                    {
                      required: true,
                      message: "Please enter remarks",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Remarks"
                    style={{ width: "100%" }}
                  >
                    {remarkOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      <div className="p-2">
        <div className="">
          {service?.approval?.user !== null ? (
            <div className="mb-2">
              <div className="">
                Current Remark :{" "}
                {service?.approval?.isApproved ? "Approved" : "Rejected"}
              </div>
              <div className="">{service?.approval?.remark}</div>
              <div className="">
                <Moment format="Do MMM, YYYY">{service?.approval?.date}</Moment>
              </div>
            </div>
          ) : null}
        </div>
        {service?.history?.map((item: any, index: number) => (
          <div key={index} className="mb-2 border-l-2 border-blue-400 px-2">
            <div className="flex justify-between">
              <div className="w-full">
                <div className="capitalize border-b font-bold py-1">
                  {item?.action}
                </div>
                <Moment format="Do MMM, YYYY">{item?.date}</Moment>
              </div>
            </div>
            <div className="">{item?.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
