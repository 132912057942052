import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchProformas = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 24 },
      params,
    },
  ] = queryKey;
  const uri = `api/proforma?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateProforma = async (data: any) => {
  const uri = `api/proforma`;
  const response = postDataAuto({ data, uri });
  return response;
};


const handleFetchProforma = async ({ _id }: any) => {
  const uri = `api/proforma/${_id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleApproveProforma = async ({ data, _id }: any) => {
  const uri = `api/proforma/${_id}/approve`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleRejectProforma = async ({ data, _id }: any) => {
  const uri = `api/proforma/${_id}/reject`;
  const response = patchDataAuto({ data, uri });
  return response;
};
const handleUpdateProforma = async ({ data, _id }: any) => {
  const uri = `api/proforma/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteProforma = async ({ _id }: any) => {
  const uri = `api/proforma/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchProformas,
  handleFetchProforma,
  handleCreateProforma,
  handleUpdateProforma,
  handleDeleteProforma,
  handleApproveProforma,
  handleRejectProforma,
};
