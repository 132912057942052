import { Form, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleDeleteQuestion } from "../../../../api/checkpoints";
import { AiOutlineDelete } from "react-icons/ai";

export default function DeleteQuestion({ checkpoint_id, question_id }: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: handleDeleteQuestion,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["checkpoints"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const data = {};
    await mutate({ checkpoint_id, question_id, data });
  };

  return (
    <div className="bg-white text-xs">
      <button
        onClick={() => onSubmit()}
        className="flex justify-center items-center  rounded-md py-2 ml-1 focus:outline-none hover:bg-green-50 text-red-800"
      >
        <AiOutlineDelete size={15} />
      </button>
    </div>
  );
}
