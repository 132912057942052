import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { handleFetchVehicle } from '../../../api/vehicles';
import Documents from '../../manage/vehicles/components/Documents';

export default function VehicleDocuments({chassis}: any) {
    const {
        data: vehicle,
      } = useQuery({
        queryKey: ["vehicles", chassis],
        queryFn: () => handleFetchVehicle(chassis),
      });
      
  return (
    <div>
                     <Documents
                    documents={vehicle?.documents}
                    chassis={vehicle?.chassis}
                    editable={true}
                  />
    </div>
  )
}
