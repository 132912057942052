function convertImagesToBase64(imagesArray: any) {
  // Function to read a single image file and return a Promise
  function readImageFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      // Set up the onload event handler
      reader.onload = () => {
        // Resolve the Promise with the base64 data URL
        resolve(reader.result);
      };

      // Read the image file as a data URL (base64 encoded)
      reader.readAsDataURL(file);
    });
  }

  // Loop through the array of image files asynchronously
  const promises = imagesArray.map((image: any) => readImageFile(image?.file));

  // Wait for all Promises to resolve
  return Promise.all(promises).then((results) => {
    // results is an array containing the base64 encoded images
    return results;
  });
}

export default convertImagesToBase64;
