import { useState } from "react";
import { handleFetchAfterSourceReport } from "../../../../api/reports";
import ServicePanel from "./ServicePanel";
import SourceWidget from "./SourceWidget";
import { useQuery } from "@tanstack/react-query";

export default function SourceWrapper() {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const { data: sources } = useQuery({
    queryKey: ["afterSourceReport"],
    queryFn: handleFetchAfterSourceReport,
  });

  const handleSelect = ({ item }: any) => {
    setSelected(item);
    setVisible(true);
  };

  return (
    <div className="">
      <div className="grid grid-cols-12 gap-2">
        {sources?.map((item: any, index: number) => (
          <SourceWidget item={item} key={index} handleSelect={handleSelect} />
        ))}
      </div>
      <ServicePanel
        isVisible={isVisible}
        setVisible={setVisible}
        title={selected?._id}
        subtitle={"Source"}
        vehicles={selected?.list}
      />
    </div>
  );
}
