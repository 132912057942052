import Wrap from "../Wrap";
import IArcInquiries from "./IArcInquiries";

export default function ArchivedInquiries() {
  return (
    <Wrap active="Inquiries" child={<></>} actions={<></>}>
      <IArcInquiries inCRM={true} />
    </Wrap>
  );
}
