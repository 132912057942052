import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { handleFetchLeadModelsReport } from "../../../../api/reports";
import { useQuery } from "@tanstack/react-query";
import LeadPanel from "./LeadPanel";
import { Tooltip as Tip } from "antd";
import Moment from "react-moment";
import { RiFileExcel2Line } from "react-icons/ri";

export default function LeadChart({ dates }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({ _id: 0, list: [] });
  const { data: stock } = useQuery({
    queryKey: ["leadModelReport"],
    queryFn: handleFetchLeadModelsReport,
  });

  const DataFormater = (number: any) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  function handleShowDetails(e: any) {
    setVisible(true);
    setSelected(e.payload);
  }
  const getTotal = ({ list }: any) => {
    let total = 0;
    if (list) {
      list.forEach((item: any, index: number) => {
        total = total + item?.value;
      });
    }
    return total;
  };

  const prepareData = () => {
    let deals: any = [];
    if (stock) {
      stock?.forEach((item: any) => {
        let d = {
          name: item?._id,
          count: Number(item?.total),
          value: getTotal({ list: item?.list }),
          list: item?.list,
        };
        deals.push(d);
      });

      return deals;
    }

    return [];
  };

  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;

    return (
      <text
        x={x + 17}
        y={y}
        dy={-4}
        fill={stroke}
        fontSize={10}
        textAnchor={"middle"}
      >
        {DataFormater(value)}
      </text>
    );
  };

  const deals = prepareData();

  return (
    <div className="">
      <div className="flex flex-row justify-between items-center  border-b border-gray-200 pl-2">
        <div className="py-2">
          <h4 className="text-gray-800">
            <span className="text-gray-900 font-bold text-lg">
              Leads By Model
            </span>
          </h4>
          <p className="text-gray-600 text-sm">
            Lead Values and Counts by Model
          </p>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center text-gray-500 text-xs">
            {dates?.isRange ? (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment> -{" "}
                <Moment format={"Do MMM, YYYY"}>{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment>
              </div>
            )}
          </div>
          <div className="mx-2">
            <Tip title="Export To Excel">
              <button
                color="#E50000"
                className="flex justify-center items-center border px-4 py-1 hover:bg-gray-100"
                // onClick={() => exportStockByFuel({ data: stock })}
              >
                <RiFileExcel2Line size={18} />
              </button>
            </Tip>
          </div>
        </div>
      </div>
      <div className="" style={{ width: "100%", height: 400 }}>
        <ResponsiveContainer width="100%" height={450}>
          <BarChart
            width={500}
            height={300}
            data={deals}
            margin={{
              top: 50,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis
              yAxisId="right"
              orientation="right"
              stroke="#82ca9d"
              tickFormatter={DataFormater}
            />
            <Tooltip />
            <Legend />
            <Bar
              yAxisId="left"
              dataKey="count"
              fill="#8884d8"
              label={CustomizedLabel}
              name="Deals By Count"
              onClick={handleShowDetails}
            />
            <Bar
              yAxisId="right"
              dataKey="value"
              fill="#82ca9d"
              label={CustomizedLabel}
              name="Leads By Value"
              onClick={handleShowDetails}
            />
          </BarChart>
        </ResponsiveContainer>
        <LeadPanel
          title="LEADS BY VEHICLE MODEL"
          subtitle={selected?._id}
          leads={selected?.list || []}
          isVisible={isVisible}
          setVisible={setVisible}
        />
      </div>
    </div>
  );
}
