import {
  Button,
  Form,
  Row,
  Col,
  message,
  Select,
  Drawer,
  Space,
  Input,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchCrmSources } from "../../../../api/crmSource";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { AiOutlineClose } from "react-icons/ai";
import TextArea from "antd/es/input/TextArea";
import { handleCreateInquiry } from "../../../../api/inquiries";

const { Option } = Select;

export default function CreateInquiryPanel({ isVisible, setVisible }: any) {
  const { auth } = useContext(AuthContext);

  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleCreateInquiry,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      clearForm();
      queryClient.invalidateQueries({ queryKey: ["newInquiries"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: sources } = useQuery({
    queryKey: ["crmSources"],
    queryFn: handleFetchCrmSources,
  });

  const sourceOptions = sources?.map((source: any, index: number) => (
    <Option value={source?.name} key={index}>
      {source?.name}
    </Option>
  ));

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
      user: auth?._id,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ isExisting: false });
  }, []);

  return (
    <Drawer
      title={`CREATE INQUIRY`}
      placement={"right"}
      width={window.innerWidth > 1200 ? "48%" : "auto"}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="createInquiry"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="text" placeholder="Full Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="mobile"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile number",
                },
              ]}
            >
              <Input type="text" placeholder="Full Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                {
                  required: false,
                  message: "Please enter email address",
                },
              ]}
            >
              <Input type="text" placeholder="Email Address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="source"
              label="Source"
              rules={[
                {
                  required: false,
                  message: "Please select source",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Source"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {sourceOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="medium"
              label="Medium"
              rules={[
                {
                  required: false,
                  message: "Please enter medium",
                },
              ]}
            >
              <Input type="text" placeholder="Medium" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="location"
              label="Location"
              rules={[
                {
                  required: false,
                  message: "Please enter location",
                },
              ]}
            >
              <Input type="text" placeholder="Location" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="requirements"
              label="Requirements"
              rules={[
                {
                  required: true,
                  message: "Please enter requirements",
                },
              ]}
            >
              <TextArea placeholder="Requirements" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="comments"
              label="Comments"
              rules={[
                {
                  required: false,
                  message: "Please enter comments",
                },
              ]}
            >
              <TextArea placeholder="Comments" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="isExisting"
              label="Existing Customer?"
              rules={[
                {
                  required: false,
                  message: "Please select one option",
                },
              ]}
            >
              <Select placeholder="Existing Customer?" defaultValue={false}>
                <Option value={true}>Yes - Existing Customer</Option>
                <Option selected value={false}>
                  No - New Contact
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button danger type="primary" htmlType="submit">
            Create
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
