import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, message, Select } from "antd";
import { handleFetchSources } from "../../api/sources";
import { handleUpdateVehicleSource } from "../../api/vehicles";
const { Option } = Select;

export default function UpdateSource({
  isVisible,
  setVisible,
  vehicle,
  chassis,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { data: sources } = useQuery({
    queryKey: ["sources"],
    queryFn: handleFetchSources,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateVehicleSource,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const sourceOptions = sources?.map((source: any, index: number) => (
    <Option value={source?._id} key={index}>
      {source?.name}
    </Option>
  ));

  const onSubmit = async () => {
    const { source } = await form.getFieldsValue();
    const data = {
      source,
    };
    await mutate({ data, chassis: vehicle?.chassis });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ ...vehicle });
  }, [vehicle]);

  return (
    <Modal
      open={isVisible}
      title="Vehicle Source"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateVehicleSource"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleSource"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="source"
              label="Select Source"
              rules={[
                {
                  required: true,
                  message: "Please select vehicle source",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select vehicle source"
                optionFilterProp="children"
                defaultValue={vehicle?.source?.entity}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {sourceOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
