import { Table } from "antd";
import { FiEdit } from "react-icons/fi";
import { handleFetchVehicleServices } from "../../api/services";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import ServiceBudget from "./ServiceBudget";
import formatMoney from "../../utils/formatMoney";

export default function ServiceHistory({ chassis }: any) {
  const { data: services } = useQuery({
    queryKey: ["services", chassis],
    queryFn: () => handleFetchVehicleServices(chassis),
  });

  const navigate = useNavigate()

  const getServiceActual = (service: any) => {
    let actual = 0;
    let budget = service?.vehicle?.source?.budget;
    service?.lines?.forEach((line: any) => {
      actual = actual + Number(line?.quantity) * Number(line?.price);
    });

    return actual

  }

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Service Type",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">{record?.service_type || "-"}</span>
        );
      },
    },
    {
      title: "Location",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">{record?.location?.name || "-"}</span>
        );
      },
    },
    {
      title: "Customer",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.mobile ? record?.mobile : "-"}
          </span>
        );
      },
    },
    {
      title: "Status",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700 capitalize">
            {record?.status ? record?.status : "-"}
          </span>
        );
      },
    },
    {
      title: "Technician",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">{record?.technician?.name || "-"}</span>
      ),
    },
    {
      title: "Budget",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">{formatMoney(record?.vehicle?.source?.budget) || "-"}</span>
      ),
    },
    {
      title: "Actual",
      width: 140,
      render: (record: any) => (
        <div className="text-gray-700 text-right">{formatMoney(getServiceActual(record))}</div>
      ),
    },
  ];
  return (
    <div className="">
      <div className="p-2 border-b-2 bg-gray-100">Service History</div>
      <div className="border-t-4"></div>

      <div className="grid gap-x-0 grid-cols-12">
        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <Table
            size="middle"
            dataSource={services === undefined ? [] : services}
            columns={columns}
            scroll={{ y: 660, x: 0 }}
            rowKey="id"
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () => {
                 navigate(`/manage/stock/services/${record?._id}`)
                },
              };
            }}
            rowClassName={"hover:cursor-pointer hover:bg-red-50"}
          />
           <ServiceBudget services={services} />
        </div>
      </div>
    </div>
  );
}
