import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Drawer, Form, Input, Row, Space, message } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleUpdateContact } from "../../../../api/contacts";
import { useEffect } from "react";

export default function UpdateAddress({ isVisible, setVisible, contact }: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateContact,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      addressInfo: {
        ...values,
      },
    };
    await mutate({ data, _id: contact?._id });
  };

  const clearForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ physical: contact?.addressInfo?.physical });
    form.setFieldsValue({ postal: contact?.addressInfo?.postal });
    form.setFieldsValue({ city: contact?.addressInfo?.city });
    form.setFieldsValue({ latitude: contact?.addressInfo?.latitude });
    form.setFieldsValue({ longitude: contact?.addressInfo?.longitude });
  }, [contact]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">UPDATE : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">Address</div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateContactAddress"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="physical"
                label="Physical Address"
                initialValue={contact?.addressInfo?.physical}
                rules={[
                  {
                    required: true,
                    message: "Please enter physical",
                  },
                ]}
              >
                <Input type="" placeholder="Physical" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="postal"
                label="Postal Address"
                initialValue={contact?.addressInfo?.postal}
                rules={[
                  {
                    required: true,
                    message: "Please enter postal",
                  },
                ]}
              >
                <Input type="" placeholder="Postal" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="city"
                label="City / Region"
                initialValue={contact?.addressInfo?.city}
                rules={[
                  {
                    required: true,
                    message: "Please enter city",
                  },
                ]}
              >
                <Input type="" placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="latitude"
                label="Latitude"
                initialValue={contact?.addressInfo?.latitude}
                rules={[
                  {
                    required: true,
                    message: "Please enter latitude",
                  },
                ]}
              >
                <Input type="" placeholder="Latitude" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="longitude"
                label="Longitude"
                initialValue={contact?.addressInfo?.longitude}
                rules={[
                  {
                    required: true,
                    message: "Please enter longitude",
                  },
                ]}
              >
                <Input type="" placeholder="Longitude" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit" loading={isLoading}>
            Update
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
