import Moment from "react-moment";
import logo from "../../assets/images/sfl_logo.png";
export default function DocumentHeader({proforma}: any) {
  const year = new Date().getFullYear()
  return (
    <div className="">
      <div className="flex justify-end mb-2">
        <div className="font-bold text-lg">PROFORMA INVOICE</div>
      </div>
      <div className="flex justify-between">
        {/* LEFT SIDE */}
        <div className="">
          <div className="" style={{ marginLeft: -1 }}>
            <img src={logo} width={150} alt="" />
          </div>
          <div className="text-md text-automark-dark font-bold">
            SALUTE FINANCE LTD
          </div>
          <div className="text-md mb-1">No. 19 Sokoine</div>
          <div className="text-md mb-1">P.O Box 9060, Dar es Salaam</div>
          <div className="text-md mb-1">Tel: +255 222 86615-9</div>
          <div className="text-md mb-1">VRN 10-006645-E</div>
          <div className="text-md">TIN 100-146-304</div>
        </div>
        {/* RIGHT SIDE */}
        <div className="">
          {/* REFERENCE */}
          <div className="">
            <div className="flex justify-between">
              <div className="mr-1 font-bold">Proforma Invoice Number : </div>
              <div className="w-40 border-t border-r border-l border-gray-300 flex justify-center">
                SFL/SSBCL/{proforma?.pfi}/{year}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mr-1 font-bold">Date : </div>
              <div className="w-40 border border-gray-300 flex justify-center">
                <Moment format="DD/MM/YYYY">{proforma?.createdAt}</Moment>
              </div>
            </div>
          </div>
          {/* CUSTOMER */}
          <div className="flex justify-between items-center mt-2">
            <div className="font-bold">Customer Name : </div>
            <div className="border border-gray-300 w-52">
              <div className="border-b px-2">
              {proforma?.contact?.name || "-"}
              </div>
              <div className="border-b px-2">{proforma?.contact?.email || "-"}</div>
              <div className="px-2">{proforma?.contact?.mobile || "-"}</div>
            </div>
          </div>
          {/* ADDRESS */}
          <div className="flex justify-between items-center mt-2">
            <div className="font-bold">Address : </div>
            <div className="border border-gray-300 w-52">
              <div className="px-2">{proforma?.contact?.address || "-"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
