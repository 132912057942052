import { Button, Modal, Form, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateVehiclePublishment } from "../../api/vehicles";
import moment from "moment";

export default function UpdatePublish({
  isVisible,
  setVisible,
  chassis,
  vehicle,
  flags,
}: any) {
  const state = flags?.isPublished ? "Unpublish From" : "Publish To";
  const action = flags?.isPublished ? "Unpublish" : "Publish";

  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateVehiclePublishment,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { date, arrival, ...rest } = await form.getFieldsValue();
    const data = {
      date: date ? moment(new Date(date)).format("YYYYMMDD") : null,
      arrival: arrival ? moment(new Date(arrival)).format("YYYYMMDD") : null,
      ...rest,
    };
    await mutate({ data, chassis });
    setVisible(false);
  };


  return (
    <Modal
      open={isVisible}
      title={`${state} Automark Website`}
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateVehiclePublishment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          {action}
        </Button>,
      ]}
    >
      <Form
        id="updateVehiclePublishment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex">
            Are you sure you want to
            {flags?.isPublished ? (
              <div className="text-red-600 font-bold px-1">Unpublish</div>
            ) : (
              <div className="text-green-600 font-bold px-1">Publish</div>
            )}
            the Vehicle
          </div>
          <div className="">
            {vehicle?.model?.name} - {vehicle?.chassis} ?
          </div>
        </div>
      </Form>
    </Modal>
  );
}
