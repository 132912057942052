export default function ConvWidget({ deals }: any) {
  const getMetrics = () => {
    let won = 0;
    let all = 0;
    deals?.forEach((item: any) => {
      if (item?._id === "won") {
        won = item?.total;
      }
      all = all + item?.total;
    });

    let per = Math.round((won / all) * 100);

    return { won, all, per };
  };

  const { won, all, per } = getMetrics();

  return (
    <div className="col-span-3 border border-gray-300 p-2 bg-green-100 h-20">
      <div className="">Convertion Rate - [ Won Deals ] </div>
      <div className="text-lg">
        <span className="text-3xl">{won}</span> / {all} -{" "}
        <span className="font-bold">{isNaN(per) ? 0 : per} %</span>
      </div>
    </div>
  );
}
