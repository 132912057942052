import { Link } from "react-router-dom";

export default function Main({ children, actions, active }: any) {
  const menus = [
    { id: 1, name: "New Deals", link: "/crm/deals/new" },
    { id: 2, name: "Won Deals", link: "/crm/deals/won" },
    { id: 3, name: "Lost Deals", link: "/crm/deals/lost" },
    { id: 4, name: "Archieved Deals", link: "/crm/deals/archived" },
  ];
  return (
    <div className="">
      <div className="border-b  bg-white flex justify-between px-2">
        <div className="flex items-center">
          {menus.map((menu: any, index: number) => (
            <Link
              to={menu?.link}
              className={`flex items-center mr-4 text-xs py-2 ${
                active == menu?.name ? "text-automark-dark" : ""
              }`}
              key={index}
            >
              <span className="ml-1">{menu?.name}</span>
            </Link>
          ))}
        </div>
        <div className="flex items-center py-1">{actions}</div>
      </div>
      <div className="p-2">{children}</div>
    </div>
  );
}
