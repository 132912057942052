import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchAllDeals = async () => {
  const uri = `api/crm/deals`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchNewDeals = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/deals/new?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchPipelineDeals = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/deals/pipeline?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchArchivedDeals = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/deals/archived?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchWonDeals = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/deals/won?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchLostDeals = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/deals/lost?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateDeal = async (data: any) => {
  const uri = `api/crm/deals`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleConvertDeal = async ({ deal_id, data }: any) => {
  const uri = `api/crm/deals/${deal_id}/convert`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleCreateDealTask = async ({ data, _id }: any) => {
  const uri = `api/crm/deals/${_id}/tasks`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateDealTask = async ({ data, deal_id, task_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/tasks/${task_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteDealTask = async ({ deal_id, task_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/tasks/${task_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleCompleteDealTask = async ({ deal_id, task_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/tasks/${task_id}/complete`;
  const response = patchDataAuto({ uri });
  return response;
};

const handleCreateDealActivity = async ({ data, deal_id, action }: any) => {
  const uri = `api/crm/deals/${deal_id}/${action}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateDealActivity = async ({
  data,
  deal_id,
  activity_id,
}: any) => {
  const uri = `api/crm/deals/${deal_id}/activities/${activity_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDoneDealActivity = async ({ data, deal_id, activity_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/activities/${activity_id}/done`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteDealActivity = async ({
  data,
  deal_id,
  activity_id,
}: any) => {
  const uri = `api/crm/deals/${deal_id}/activities/${activity_id}`;
  const response = deleteDataAuto({ data, uri });
  return response;
};

const handleCreateDealMeeting = async ({ data, _id }: any) => {
  const uri = `api/crm/deals/${_id}/meeting`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUploadDealDoc = async ({ data, deal_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/file`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleCreateDealSMS = async ({ data, _id }: any) => {
  const uri = `api/crm/deals/${_id}/sms`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateDealWarm = async ({ data, deal_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/warm`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateDealCold = async ({ data, deal_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/cold`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleFetchDeal = async ({ _id }: any) => {
  const uri = `api/crm/deals/${_id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleUpdateDeal = async ({ data, _id }: any) => {
  const uri = `api/crm/deals/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteDeal = async ({ _id }: any) => {
  const uri = `api/crm/deals/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleArchiveDeal = async ({ data, deal_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/archive`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleGoToNextDealStage = async ({ data, deal_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/next`;
  const response = patchDataAuto({ data, uri });
  return response;
};
const handleGoToPrevDealStage = async ({ data, deal_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/previous`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleGoToAnyDealStage = async ({ data, deal_id, stage_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/stage/${stage_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleMakeDealWon = async ({ data, deal_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/won`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleMakeDealLost = async ({ data, deal_id }: any) => {
  const uri = `api/crm/deals/${deal_id}/lost`;
  const response = patchDataAuto({ data, uri });
  return response;
};

export {
  handleFetchAllDeals,
  handleFetchNewDeals,
  handleFetchPipelineDeals,
  handleFetchArchivedDeals,
  handleFetchWonDeals,
  handleFetchLostDeals,
  handleFetchDeal,
  handleCreateDeal,
  handleConvertDeal,
  handleUpdateDeal,
  handleDeleteDeal,
  handleArchiveDeal,
  handleCreateDealTask,
  handleUpdateDealTask,
  handleDeleteDealTask,
  handleUpdateDealWarm,
  handleUpdateDealCold,
  handleCompleteDealTask,
  handleCreateDealActivity,
  handleUpdateDealActivity,
  handleDeleteDealActivity,
  handleCreateDealMeeting,
  handleCreateDealSMS,
  handleUploadDealDoc,
  handleDoneDealActivity,
  handleGoToNextDealStage,
  handleGoToPrevDealStage,
  handleGoToAnyDealStage,
  handleMakeDealWon,
  handleMakeDealLost,
};
