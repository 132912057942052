import FeatureItem from "../../pages/manage/vehicles/components/FeatureItem";

export default function FeatureSetTwo({ vehicle }: any) {
  return (
    <div className="">
      <div className="border-t-4 mt-1"></div>
      <FeatureItem
        title={"Drive Type"}
        detail={vehicle?.specifications?.drive?.drive_type || "-"}
      />
      <FeatureItem
        title={"Body Type"}
        detail={vehicle?.specifications?.body?.body_type || "-"}
      />
      <FeatureItem
        title={"Transmission"}
        detail={vehicle?.specifications?.transmission || "-"}
      />
      <FeatureItem
        title={"Engine Type"}
        detail={vehicle?.specifications?.engine?.engine_type || "-"}
      />
      <FeatureItem
        title={"Engine Size"}
        detail={vehicle?.specifications?.engine?.size || "-"}
      />

      <FeatureItem
        title={"Fuel Type"}
        detail={vehicle?.specifications?.fuel?.fuel_type || "-"}
      />
      <FeatureItem
        title={"Fuel Tank Size"}
        detail={vehicle?.specifications?.fuel?.size || "-"}
      />
      <FeatureItem
        title={"Steering"}
        detail={vehicle?.specifications?.steering || "-"}
      />
      <FeatureItem
        title={"Doors"}
        detail={vehicle?.specifications?.doors || "-"}
      />
      <FeatureItem
        title={"Seats"}
        detail={vehicle?.specifications?.seats || "-"}
      />
    </div>
  );
}
