import InvoiceItemRead from "./InvoiceItemRead";

export default function InvoiceItemsRead({ service }: any) {
  return (
    <>
      <div className="border border-black  my-2 text-xs">
        <div className="grid grid-cols-12 border-b  border-black  bg-gray-200">
          <div className="col-span-5 p-1 font-bold border-r border-black  text-left">
            Description
          </div>
          <div className="col-span-1 p-1 font-bold border-r border-black  text-center">
            Qty
          </div>
          <div className="col-span-2 p-1 font-bold border-r border-black  text-center">
            Unit Price
          </div>
          <div className="col-span-1 p-1 font-bold border-r border-black  text-center">
            Unit
          </div>
          <div className="col-span-1 p-1 font-bold border-r border-black  text-center">
            Disc
          </div>
          <div className="col-span-2 p-1 font-bold  text-center">Amount</div>
        </div>
        <div className="">
          {service?.lines?.map((item: any, index: number) => (
            <InvoiceItemRead key={index} index={index} item={item} />
          ))}
        </div>
      </div>
    </>
  );
}
