import Wrap from "../Wrap";
import IConInquiries from "./IConInquiries";

export default function ContactedInquiries() {
  return (
    <Wrap active="Inquiries" child={<></>} actions={<></>}>
      <IConInquiries inCRM={true} />
    </Wrap>
  );
}
