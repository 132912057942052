import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleUploadContactFile } from "../../../../api/contacts";
import { useEffect, useState } from "react";
import getBase64 from "../../../../utils/getBase64";

const { TextArea } = Input;
const { Option } = Select;

export default function CreateFile({ isVisible, setVisible, contact }: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [isOther, setOther] = useState<boolean>(false);

  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUploadContactFile,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { category, ocategory, name, description } =
      await form.getFieldsValue();
    const document = await getBase64(selectedFile);
    const data = {
      name,
      category: isOther ? ocategory : category,
      description,
      document,
    };
    await mutate({ data, _id: contact?._id });
  };

  const clearForm = () => {
    form.resetFields();
  };

  const handleSelectCategory = (e: any) => {
    if (e === "Other") {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  useEffect(() => {
    form.setFieldsValue({ dhdg: contact?.dates?.nationalID });
    form.setFieldsValue({ passport: contact?.dates?.passport });
    form.setFieldsValue({ company: contact?.dates?.company });
  }, [contact]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">UPLOAD : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">File</div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="UploadContactFile"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        {contact?.name}
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}
            >
              <Select
                placeholder="Category"
                onChange={(e: any) => handleSelectCategory(e)}
              >
                <Option value="TIN">TIN</Option>
                <Option value="VRN">VRN</Option>
                <Option value="Certificate of Incorporation">
                  Certificate of Incorporation
                </Option>
                <Option value="Business License">Business License</Option>
                <Option value="Other">Other ( Specify )</Option>
              </Select>
            </Form.Item>
          </Col>
          {isOther && (
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="ocategory"
                label="Specify Category"
                rules={[
                  {
                    required: true,
                    message: "Please enter category",
                  },
                ]}
              >
                <Input
                  placeholder="Specify Category"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description (Optional)"
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="file"
              rules={[
                {
                  required: true,
                  message: "Please select file",
                },
              ]}
            >
              <Input
                type="file"
                placeholder="Document"
                onChange={changeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
        {isSelected ? (
          <div>
            <p>Filename: {selectedFile?.name}</p>
            <p>Size : {selectedFile?.size / 1000} kb</p>
          </div>
        ) : null}

        <Form.Item>
          <Button danger type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
