import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Checkbox, Col, Form, Input, message, Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  handleApproveProforma,
  handleRejectProforma,
} from "../../../../api/proforma";
import formatMoney from "../../../../utils/formatMoney";
import { BsCheck2 } from "react-icons/bs";
import getProformaTotals from "../../../../utils/proformaTotals";
import { AuthContext } from "../../../../context/AuthContext";

export default function ApproveProforma({
  isVisible,
  setVisible,
  selected,
}: any) {
  const { auth } = useContext(AuthContext);
  const [requireChange, setRequireChange] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [approval, setApproval] = useState<any>(null);
  const { _id } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { total, exchange } = getProformaTotals(selected);

  const approvals = [
    { id: 1, name: "Accept" },
    { id: -1, name: "Decline" },
  ];

  const { mutate: approve } = useMutation({
    mutationFn: handleApproveProforma,
    onSuccess: () => {
      message.success("Approved Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["proformas"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: reject } = useMutation({
    mutationFn: handleRejectProforma,
    onSuccess: () => {
      message.success("Rejected Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["proformas"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    const { remarks } = await form.getFieldsValue();
    let data = { user: auth?._id, remarks, requireChange };
    if (approval?.id === 1) {
      await approve({ data, _id });
    } else {
      await reject({ data, _id });
    }
    setLoading(false);
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected, form]);

  return (
    <Modal
      open={isVisible}
      title="Approve Proforma"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="approveProforma"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="approveProforma"
        form={form}
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="px-4">
          <div className="flex justify-between items-center mb-4 border-b pb-4">
            {approvals?.map((item: any) => (
              <div
                onClick={() => setApproval(item)}
                className="flex items-center hover:bg-gray-50 hover:cursor-pointer p-2 w-full bg-white border"
              >
                {approval?.id === item?.id ? (
                  <div className="flex justify-center items-center h-8 w-8  border-green-300 bg-green-600">
                    <BsCheck2 color="#FFF" />
                  </div>
                ) : (
                  <div className="h-8 w-8 border"></div>
                )}
                <div className="ml-2">{item?.name}</div>
              </div>
            ))}
          </div>
          {approval?.id === -1 ? (
            <div className="">
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Please enter remarks",
                  },
                ]}
              >
                <Input placeholder="Remarks" className="w-full" />
              </Form.Item>

              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Checkbox
                  onChange={(e: any) => {
                    setRequireChange(e.target.checked);
                  }}
                  defaultChecked={requireChange}
                >
                  Require Change
                </Checkbox>
              </Col>
            </div>
          ) : (
            <Form.Item
              name="remarks"
              label="Remarks ( Optional )"
              rules={[
                {
                  required: false,
                  message: "Please enter remarks",
                },
              ]}
            >
              <Input placeholder="Remarks" className="w-full" />
            </Form.Item>
          )}

          <div className="pb-4 flex flex-col justify-center items-center">
            <div className="flex text-black text-lg">Total Amount</div>
            <div className="flex items-center">
              <div className="flex text-xl font-bold">
                {formatMoney(total)} USD
              </div>
              <div className="ml-2">
                [ {formatMoney(total * exchange)} TZS ]
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
