import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import StageLine from "./StageLine";
import UpdateDealStage from "./UpdateDealStage";
import DeleteDealStage from "./DeleteDealStage";
import { useState } from "react";

function StageLines(props: any) {
  const [selected, setSelected] = useState<any>({});

  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const handleSetUpdate = ({ item }: any) => {
    setSelected(item);
    setUpdateModal(true);
  };

  const handleSetDelete = ({ item }: any) => {
    setSelected(item);
    setDeleteModal(true);
  };
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorder = (list: any, startIndex: any, endIndex: any) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };
    const lineItems = reorder(
      props.items,
      result.source.index,
      result.destination.index
    );
    props.reorderHandler(lineItems);
  };

  const { items, addHandler, reorderHandler, ...functions } = props;

  return (
    <div className="">
      <form>
        <div className="text-xs">
          <div className="grid grid-cols-12 border-b-2">
            <div className="col-span-1 p-2 font-bold border-r  text-center">
              S/n
            </div>
            <div className="col-span-4 p-2 font-bold border-r  text-left">
              Stage Name
            </div>
            <div className="col-span-6 p-2 border-r  font-bold text-left">
              Stage Description
            </div>
            <div className="col-span-1 p-2 font-bold text-center">Action</div>
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} className="border-b">
                  {props?.items?.map((item: any, i: any) => (
                    <Draggable key={item.sn} draggableId={item?._id} index={i}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                          className={""}
                        >
                          <StageLine
                            style={{ color: "red" }}
                            key={i + item.sn}
                            index={i}
                            item={item}
                            handleSetUpdate={handleSetUpdate}
                            handleSetDelete={handleSetDelete}
                            {...functions}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </form>
      <UpdateDealStage
        isVisible={updateModal}
        setVisible={setUpdateModal}
        selected={selected}
      />

      <DeleteDealStage
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        selected={selected}
      />
    </div>
  );
}

export default StageLines;
