import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Tooltip as Tip } from "antd";
import Moment from "react-moment";
import { useQuery } from "@tanstack/react-query";
import { handleFetchAfterTechnicianReport } from "../../../../api/reports";
import { RiFileExcel2Line } from "react-icons/ri";
import spinner from "../../../../assets/images/spin-red.svg";
import { useState } from "react";
import StockPanel from "./StockPanel";
import { exportStockByStatus } from "../../../../utils/stockDataExport";

export default function TechnicianChart({ dates }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({ _id: 0, list: [] });

  const { data, isLoading } = useQuery({
    queryKey: ["afterTechnicianReport"],
    queryFn: handleFetchAfterTechnicianReport,
  });

  const getAllVehicles = () => {
    if (!data) {
      return [];
    }
    let allVehicles = [].concat(...data?.map((item: any) => item?.list));
    return allVehicles;
  };

  const allVehicles = getAllVehicles();

  function handleShowDetails(e: any) {
    setVisible(true);
    setSelected(e.payload);
  }

  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;

    return (
      <text
        x={x + 17}
        y={y}
        dy={-4}
        fill={stroke}
        fontSize={10}
        textAnchor={"middle"}
      >
        {value}
      </text>
    );
  };

  return (
    <div className="col-span-12 sm:col-span-12 md:col-span-12 mr-4 rounded-lg">
      <div className="flex flex-col justify-between">
        <div className="flex flex-row justify-between items-center  border-b border-gray-200 pl-6 mb-4">
          <div className="py-2">
            <h4 className="text-gray-800">
              <span className="text-gray-900 font-bold text-lg">
                Services By Technician
              </span>
            </h4>
            <p className="text-gray-600 text-sm">
              Vehicles Grouped By Technician
            </p>
          </div>

          <div className="flex items-center justify-center">
            <div className="flex items-center text-gray-500 text-xs">
              {dates?.isRange ? (
                <div className="font-bold">
                  <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment> -{" "}
                  <Moment format={"Do MMM, YYYY"}>{dates?.endDate}</Moment>
                </div>
              ) : (
                <div className="font-bold">
                  <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment>
                </div>
              )}
            </div>
            <div className="ml-2">
              <Tip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() => exportStockByStatus({ data: data })}
                >
                  <RiFileExcel2Line size={18} />
                </button>
              </Tip>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center mt-16">
            <img src={spinner} width={30} />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              data={data}
              margin={{
                top: 0,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="_id" />
              <XAxis
                dataKey="_id"
                axisLine={false}
                tickLine={false}
                interval={0}
                height={1}
                scale="band"
                xAxisId="quarter"
              />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" />
              <Bar
                dataKey="total"
                fill="#16a34a"
                label={CustomizedLabel}
                name="Actual"
                onClick={handleShowDetails}
              />
              <Bar
                dataKey="budget"
                fill="#0369a1"
                label={CustomizedLabel}
                name="Budget"
                onClick={handleShowDetails}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>

      <StockPanel
        title="STOCK BY STATUS"
        subtitle={selected?._id}
        vehicles={selected?.list || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
