import { useQuery } from "@tanstack/react-query";
import AgeingWidget from "./AgeingWidget";
import { handleFetchStockAgeSourceReport } from "../../../../api/reports";
import StockPanel from "./StockPanel";
import { useState } from "react";

export default function AgeingWrapper({ dates }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({ _id: 0, list: [] });
  const { data: stock } = useQuery({
    queryKey: ["stockAgaingReport"],
    queryFn: handleFetchStockAgeSourceReport,
  });


  const ordered = stock?.sort((a: any, b: any) => {
    const aFirstPart = a?._id.split(' ')[0];
    const bFirstPart = b?._id.split(' ')[0];
    
    if (aFirstPart.startsWith('>=')) return 1;
    if (bFirstPart.startsWith('>=')) return -1;
    
    return parseInt(aFirstPart, 10) - parseInt(bFirstPart, 10);
  });

  const handleSelect = ({ item }: any) => {
    setSelected(item);
    setVisible(true);
  };

  const allVehicles = [].concat(
    ...selected?.list?.map((item: any) => item?.list)
  );

  return (
    <div className="">
      <div className="grid grid-cols-12 gap-2">
        {ordered?.map((item: any, index: number) => (
          <div className="col-span-12 md:col-span-2" key={index}>
            <AgeingWidget item={item} key={index} handleSelect={handleSelect} />
          </div>
        ))}
      </div>
      <StockPanel
        title="STOCK BY AGEING"
        subtitle={selected?._id || "-"}
        vehicles={allVehicles || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
