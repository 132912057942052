import {
  BarChartOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
} from "@ant-design/icons";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Wrap from "./Wrap";
import { Button } from "antd";
import { FiEdit, FiLock } from "react-icons/fi";
import UpdatePassword from "./components/UpdatePassword";
import UpdateDetails from "./components/UpdateDetails";

export default function MyProfile() {
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const [detailsModal, setDetailsModal] = useState<boolean>(false);
  const { user } = useContext(AuthContext);

  const shortname =
    user.firstname === null
      ? "--"
      : `${user.firstname.charAt(0)}${user.lastname.charAt(0)} `;

  return (
    <Wrap
      active="My Profile"
      actions={
        <>
          <Button
            className="flex items-center"
            onClick={() => setDetailsModal(true)}
          >
            <FiEdit />
            <span className="ml-1">Details</span>
          </Button>
          <Button
            className="ml-2 flex items-center"
            onClick={() => setPasswordModal(true)}
          >
            <FiLock />
            <span className="ml-1">Password</span>
          </Button>
        </>
      }
    >
      <div className="overflow-x-hidden bg-white border rounded-md">
        <div className="flex flex-col  border-gray-300 mx-4 rounded-lg mt-2 bg-white"></div>
        <div className="grid grid-cols-12 gap-4 m-4">
          <div className="col-span-12 sm:col-span-6 md:col-span-3 bg-white border rounded-lg mb-4">
            <div className="flex flex-col justify-between items-center py-2">
              <div className="flex items-center justify-center h-24 w-24 rounded-full bg-gray-300">
                <span className="text-lg text-blue-900">{shortname}</span>
              </div>
              <span className="text-blue-800 text-sm mt-2 font-bold">
                {user.firstname} {user.lastname}
              </span>
              <span className="text-gray-600 text-sm ">{user.role?.name}</span>
              <span className="text-gray-600 text-sm ">{user?.mobile}</span>
              <h3>
                <span className="font-bold my-1">{user.site?.name} </span>
              </h3>
              <span className="text-blue-500 text-sm bg-gray-200 rounded-lg px-4 py-1 my-2">
                {user.email}
              </span>
            </div>
            <div className="flex justify-between items-center border-t bg-gray-100 rounded-br-lg rounded-bl-lg">
              <div className="flex items-center justify-center py-2 w-full text-center text-xs">
                <span className="mr-2">Account Status:</span>
                {user.isActive ? (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <CheckSquareTwoTone twoToneColor="#52c41a" />
                    </div>
                    <div className="ml-1 text-green-500">Active</div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <CloseSquareTwoTone twoToneColor="#ff0000" />
                    </div>
                    <div className="ml-1 text-red-500">In Active</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-9 bg-white border rounded-lg flex flex-col justify-center items-center mb-4">
            <div className="flex flex-col justify-center items-center text-gray-600 p-8">
              <BarChartOutlined />
              <div className="">Account Metrics</div>
            </div>
          </div>
        </div>
        <UpdatePassword
          isVisible={passwordModal}
          setVisible={setPasswordModal}
        />
        <UpdateDetails isVisible={detailsModal} setVisible={setDetailsModal} />
      </div>
    </Wrap>
  );
}
