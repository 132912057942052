import { Button, Modal, Form, Row, Col, Select, message } from "antd";
import { handleFetchLocations } from "../../../api/locations";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchTechnicians } from "../../../api/technicians";
import { handleCreateService } from "../../../api/services";
import { handleFetchServiceTypes } from "../../../api/serviceTypes";
import { handleFetchVehicles } from "../../../api/vehicles";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

const { Option } = Select;

export default function CreateService({ isVisible, setVisible, refetch }: any) {
  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: stock } = useQuery(
    ["vehicles", { pagination: { page: 1, per_page: 0 }, params: `` }],
    handleFetchVehicles
  );

  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: handleFetchLocations,
  });

  const { data: technicians } = useQuery({
    queryKey: ["technicians"],
    queryFn: handleFetchTechnicians,
  });

  const { data: serviceTypes } = useQuery({
    queryKey: ["serviceTypes"],
    queryFn: handleFetchServiceTypes,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateService,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["allServices"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values, state: "new", user: auth?._id };
    await mutate({ data });
    refetch();
    setVisible(false);
  };

  const serviceOptions = serviceTypes?.map((type: any, index: number) => {
    return (
      <Option value={type.name} key={index}>
        {type.name}
      </Option>
    );
  });

  const technicianOptions = technicians?.map(
    (technician: any, index: number) => {
      return (
        <Option value={technician._id} key={index}>
          {technician.name}
        </Option>
      );
    }
  );

  const siteOptions = locations?.map((site: any, index: number) => {
    return (
      <Option value={site._id} key={index}>
        {site.name}
      </Option>
    );
  });

  let vehicleOptions = stock?.vehicles?.map((vehicle: any, index: number) => {
    return (
      <Option value={vehicle.chassis} key={index}>
        {`${vehicle?.model?.name} ${vehicle?.chassis}`}
      </Option>
    );
  });

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Create Service"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" danger onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="CreateServiceForm"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="CreateServiceForm"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="service"
              label="Service Type"
              rules={[
                {
                  required: true,
                  message: "Please select service type",
                },
              ]}
            >
              <Select placeholder="Service">{serviceOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="technician"
              label="Chief Technician"
              rules={[
                {
                  required: true,
                  message: "Please select technician",
                },
              ]}
            >
              <Select placeholder="Technician">{technicianOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="site"
              label="Service Site"
              rules={[
                {
                  required: true,
                  message: "Please select site",
                },
              ]}
            >
              <Select placeholder="Service Site">{siteOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="chassis"
              label="Vehicle"
              rules={[
                {
                  required: true,
                  message: "Please select vehicle",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search to Select Vehicle"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {vehicleOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
