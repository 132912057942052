import RecordItem from "./RecordItem";

export default function SalesModel({ dates }: any) {
  const data = Array(0).fill({
    id: 1,
    name: "Hilux",
    count: 9,
    value: 3500,
  });
  return (
    <div className="border bg-white text-gray-500 mb-2">
      <div className="px-2 py-4 border-b-2 text-black">
        Proforma by Vehicle Model
      </div>
      <div className="">
        {data?.map((item: any, index: number) => (
          <RecordItem item={item} index={index} />
        ))}
      </div>
    </div>
  );
}
