import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchTechnicians = async () => {
  const uri = `api/support/technicians`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateTechnician = async (data: any) => {
  const uri = `api/support/technicians`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateTechnician = async ({ data, _id }: any) => {
  const uri = `api/support/technicians/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteTechnician = async ({ _id }: any) => {
  const uri = `api/support/technicians/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchTechnicians,
  handleCreateTechnician,
  handleUpdateTechnician,
  handleDeleteTechnician,
};
