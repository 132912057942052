import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";

export default function Crm() {
  const { auth } = useContext(AuthContext);
  const link = auth?.accessLevel === 3 ? "/crm/contacts" : "/crm/inquiries/new";
  const navigate = useNavigate();
  useEffect(() => {
    navigate(link);
  });
  return <div className="h-screen flex"></div>;
}
