import { useQuery } from "@tanstack/react-query";
import Something from "../../components/shared/Something";
import ProformaWidget from "./components/ProformaWidget";
import { handleFetchProformas } from "../../api/proforma";
import ProformaDetails from "./components/ProformaDetails";
import { useEffect, useState } from "react";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
  RiSearch2Line,
} from "react-icons/ri";
import {
  Button,
  Form,
  Input,
  Pagination,
  Popover,
  Tooltip,
  message,
} from "antd";
import { blobToDataUrl, getExportData } from "../../utils/exportExcel";
import { postDataMail } from "../../services/mail";
import AdvancedSearch from "./components/AdvancedSearch";
import NoRecords from "../../components/shared/NoRecords";

const { Search } = Input;

export default function Proformas() {
  const [selected, setSelected] = useState<any>({});
  const [detailsPanel, setDetailsPanel] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [advSearch, setAdvSearch] = useState(false);

  const initials = {
    pfi: "",
    chassis: "",
    vsb: "",
    status: "",
    model: "",
    source: "",
    customer: "",
    consultant: "",
    approver: "",
    exteriorColor: "",
    interiorColor: "",
    dateOfIssue: "",
    dateOfExpiry: "",
    minYear: "",
    maxYear: "",
    minPrice: "",
    maxPrice: "",
    minMileage: "",
    maxMileage: "",
    minDiscount: "",
    maxDiscount: "",
  };

  const [filters, setFilters] = useState<any>({ ...initials });

  const [form] = Form.useForm();

  const [emailForm] = Form.useForm();

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 24,
    total_pages: 1,
    total_count: 0,
  });

  const params = `&pfi=${filters?.pfi}&chassis=${filters?.chassis}&vsb=${filters?.vsb}&status=${filters?.status}&model=${filters?.model}&source=${filters?.source}&customer=${filters?.customer}&consultant=${filters?.consultant}&approver=${filters?.approver}&exteriorColor=${filters?.exteriorColor}&dateOfIssue=${filters?.dateOfIssue}&dateOfExpiry=${filters?.dateOfExpiry}&minYear=${filters?.minYear}&maxYear=${filters?.maxYear}&minPrice=${filters?.minPrice}&maxPrice=${filters?.maxPrice}&minMileage=${filters?.minMileage}&maxMileage=${filters?.maxMileage}&minDiscount=${filters?.minDiscount}&maxDiscount=${filters?.maxDiscount}`;
  const {
    data: proformas,
    refetch,
    isFetching,
    isFetched,
    isError,
  } = useQuery({
    queryKey: ["proformas", { pagination, params }],
    queryFn: handleFetchProformas,
  });

  const onChange = (page: any, per_page: any) => {
    setPagination({
      ...pagination,
      page,
      per_page,
      total_count: proformas?.total_count,
    });
  };

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };

  const clearAllFilters = () => {
    form.resetFields();
    setAdvSearch(false);
    setFilters({ ...initials });
  };

  const clearFilters = () => {
    form.resetFields();
    setFilters({ ...initials });
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = []?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Address: item?.address === null ? "-" : item?.address,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Active: item?.isActive ? "Yes" : "No",
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  useEffect(() => {
    if (proformas?.total_count) {
      setPagination({
        ...pagination,
        total_count: proformas?.total_count,
      });
    }
  }, [isFetched]);

  return (
    <div className="">
      <div className="min-h-screen">
        <div className="h-12 border-b flex justify-between items-center px-2 bg-white">
          <Form layout="inline" form={form}>
            <Form.Item name="proforma">
              <Search
                placeholder="Search by proforma no"
                allowClear
                className="rounded-none"
                onSearch={(e: any) => {
                  setFilters({ ...filters, pfi: e });
                }}
              />
            </Form.Item>
            <Form.Item name="chassis">
              <Search
                placeholder="Search by chassis no"
                allowClear
                onSearch={(e: any) => {
                  setFilters({ ...filters, chassis: e });
                }}
              />
            </Form.Item>
            <Form.Item name="vsb">
              <Search
                placeholder="Search by vsb no"
                allowClear
                onSearch={(e: any) => {
                  setFilters({ ...filters, vsb: e });
                }}
              />
            </Form.Item>
            <Button
              className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
              onClick={() => {
                setAdvSearch(!advSearch);
              }}
            >
              <div className="flex justify-center items-center">
                <div className="mr-2">Advanced Search</div>
                <div className="border-l px-2">
                  <RiSearch2Line color="gray" size={14} />
                </div>
              </div>
            </Button>
            <Button
              className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
              onClick={() => {
                clearAllFilters();
              }}
            >
              <RiFilterOffLine color="gray" />
            </Button>
          </Form>
          <div className="">
            <div className="flex">
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-end border-t">
                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                            danger
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
                          <Button type="primary" danger htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                open={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={18} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  // onClick={() =>
                  //   ExportToExcel(
                  //     []?.map((item: any, index: number) => {
                  //       return {
                  //         "S/N": index + 1,
                  //         Name: item?.name === null ? "-" : item?.name,
                  //         Address: item?.address === null ? "-" : item?.address,
                  //         Email: item?.email === null ? "-" : item?.email,
                  //         Mobile: item?.mobile === null ? "-" : item?.mobile,
                  //         Active: item?.isActive ? "Yes" : "No",
                  //       };
                  //     }),
                  //     "Proformas.xlsx"
                  //   )
                  // }
                >
                  <RiFileExcel2Line size={18} />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        {advSearch && (
          <AdvancedSearch
            setVisible={setAdvSearch}
            handleAdvancedSearch={handleAdvancedSearch}
            clearFilters={clearFilters}
          />
        )}
        <div className="">
          {isError ? (
            <Something refetch={refetch} isFetching={isFetching} />
          ) : (
            <div className="">
              {proformas?.results?.length === 0 ? (
                <NoRecords />
              ) : (
                <div className="">
                  <div className="p-2">
                    <div className="grid grid-cols-12 gap-2">
                      {proformas?.results?.map((item: any, index: number) => (
                        <ProformaWidget
                          item={item}
                          key={index}
                          setSelected={setSelected}
                          setVisible={setDetailsPanel}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="p-4 flex justify-start items-center w-full">
                    <Pagination
                      showQuickJumper
                      defaultCurrent={pagination?.page}
                      total={pagination?.total_count}
                      onChange={onChange}
                      pageSize={pagination?.per_page}
                      showPrevNextJumpers={true}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <ProformaDetails
          isVisible={detailsPanel}
          setVisible={setDetailsPanel}
          selected={selected || {}}
        />
      </div>
    </div>
  );
}
