import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "antd";
import { handleFetchQualifiedLeads } from "../../../api/leads";
import Something from "../../../components/shared/Something";
import LeadColumns from "./components/LeadColumns";
import Wrap from "../Wrap";
import Main from "./Main";
import { useEffect, useState } from "react";
import { RiSearch2Line } from "react-icons/ri";
import AdvancedSearch from "./components/AdvancedSearch";

export default function QualifiedLeads() {
  const [advSearch, setAdvSearch] = useState<boolean>(false);

  const initials = {
    customer: "",
    consultant: "",
    model: "",
    source: "",
    status: "",
    startDate: "",
    endDate: "",
    minValue: "",
    maxValue: "",
  };
  const [filters, setFilters] = useState<any>({ ...initials });

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 24,
    total_pages: 1,
    total_count: 60,
  });

  const clearAllFilters = () => {
    setFilters({ ...initials });
  };

  const params = `&customer=${filters?.customer}&consultant=${filters?.consultant}&source=${filters?.source}&status=${filters?.status}&startDate=${filters?.startDate}&endDate=${filters?.endDate}&model=${filters?.model}&minValue=${filters?.minValue}&maxValue=${filters?.maxValue}`;
  const {
    data: leads,
    refetch,
    isFetched,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["qualifiedLeads", { pagination, params }],
    queryFn: handleFetchQualifiedLeads,
  });

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };

  const navigate = useNavigate();

  const onChange = (params: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: leads?.total_count,
    });
  };

  useEffect(() => {
    if (leads?.total_count) {
      setPagination({
        ...pagination,
        total_count: leads?.total_count,
      });
    }
  }, [isFetched]);

  return (
    <Wrap active="Leads" actions={<></>}>
      <Main
        active="Qualified Leads"
        actions={
          <Button
            className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
            onClick={() => {
              setAdvSearch(!advSearch);
            }}
          >
            <div className="flex justify-center items-center">
              <div className="mr-2">Advanced Search</div>
              <div className="border-l px-2">
                <RiSearch2Line color="gray" size={14} />
              </div>
            </div>
          </Button>
        }
      >
        {advSearch && (
          <AdvancedSearch
            setVisible={setAdvSearch}
            clearAllFilters={clearAllFilters}
            handleAdvancedSearch={handleAdvancedSearch}
          />
        )}
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="grid gap-x-0 grid-cols-12 border bg-white">
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <Table
                size="small"
                dataSource={leads === undefined ? [] : leads?.results}
                columns={LeadColumns}
                scroll={{ y: 660, x: 0 }}
                rowKey="id"
                onRow={(record) => {
                  return {
                    onClick: () => {
                      navigate(`/crm/leads/${record?._id}/detailed`);
                    },
                  };
                }}
                pagination={{
                  current: pagination?.page,
                  pageSize: pagination?.per_page,
                  total: pagination?.total_count,
                }}
                onChange={onChange}
                loading={isLoading}
                rowClassName={"hover:cursor-pointer hover:bg-red-50"}
              />
            </div>
          </div>
        )}
      </Main>
    </Wrap>
  );
}
