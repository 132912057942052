import { capitalize } from "lodash";
import { proformaApprovalColors } from "../../../constants/colors";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import Moment from "react-moment";

export default function ProformaWidget({ item, setSelected, setVisible }: any) {
  const year = new Date(item?.dates?.created).getFullYear();
  return (
    <div
      className={`${
        proformaApprovalColors[item?.status?.state]
      } col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2 border cursor-pointer`}
      onClick={() => {
        setSelected(item);
        setVisible(true);
      }}
    >
      <div className="uppercase text-gray-600 flex items-center border-b mb-2 py-4 px-3">
        <HiOutlineBuildingOffice2 />
        <span className="ml-1 truncate">{item?.contact?.name}</span>
      </div>
      <div className="px-3">
        <div className="uppercase font-bold truncate">
          {item?.source?.name === "OFF-LEASE" ? "SFL/SSBCL" : "KJL/AUTOMARK"}/
          {item?.pfi}/{year}
        </div>
        <div className="">{item?.source?.name}</div>

        <div className="mt-2 text-gray-500 text-xs flex items-center">
          <div className={`${proformaApprovalColors[item?.status?.state]}`}>
            {item?.status?.state === "new"
              ? "Pending"
              : capitalize(item?.status?.state)}
          </div>
          <div className="ml-1">
            {item?.creator?.name ? (
              <div className="truncate w-36">{item?.creator?.name}</div>
            ) : (
              "-"
            )}
            <div className="">
              {item?.dates?.approved ? (
                <Moment format="Do MMM, YYYY">{item?.dates?.created}</Moment>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2 text-gray-500 text-xs px-3 py-1">
        <div className="flex flex-col justify-start">
          <div className="flex">
            <div className="w-16 text-black">Consultant:</div>
            <div className="truncate w-36">{item?.creator?.name || "-"}</div>
          </div>
          <div className="flex">
            <div className="w-16 text-black">Created:</div>
            <Moment fromNow>{item?.dates?.created}</Moment>
          </div>
        </div>
      </div>
    </div>
  );
}
