import { useState } from "react";
import UpdateLocation from "./UpdateLocation";
import LocationLine from "./LocationLine";
import { FiEdit } from "react-icons/fi";

export default function Locations({
  movement,
  vehicle,
  chassis,
  editable,
}: any) {
  const [locationModal, setLocationModal] = useState<boolean>(false);

  return (
    <div className="">
      <div className="border-b border-t px-4 py-2 flex justify-between bg-gray-100">
        <div className=""> Location </div>
        {editable && (
          <button
            onClick={() => setLocationModal(true)}
            className="border p-1 rounded-md"
          >
            <FiEdit />
          </button>
        )}
      </div>
      <div className="border-t-4"></div>
      <div className="px-4 py-2 flex justify-between">
        <div className="">Current Location: </div>
        <div className="">{vehicle?.location?.name}</div>
      </div>

      <div className="">
        {movement?.map((item: any, index: number) => (
          <LocationLine item={item} />
        ))}
      </div>
      <UpdateLocation
        isVisible={locationModal}
        setVisible={setLocationModal}
        vehicle={vehicle}
        chassis={chassis}
      />
    </div>
  );
}
