import formatMoney from "../../../utils/formatMoney";

export default function InvoiceTotals({ service }: any) {
  const calculateCategoryTotal = (category: any) => {
    let total = 0;
    service?.lines?.map((item: any) => {
      switch (item?.category) {
        case `${category}`:
          total = total + item?.quantity * item?.price;
          break;
        default:
      }
    });
    return total;
  };

  const calculateGrandTotal = () => {
    let total = 0;
    service?.lines?.map((item: any) => {
      total = total + item?.quantity * item?.price;
    });
    return total;
  };

  return (
    <div className="flex justify-between text-xs">
      <div className=""></div>
      <div className="border border-black w-1/3">
        <div className="">
          <div className="flex flex-row justify-between px-2  border-b border-black">
            <div className="">Labour</div>
            <div className="">
              {formatMoney(calculateCategoryTotal("Labour"))}
            </div>
          </div>
          <div className="flex flex-row justify-between px-2 border-b border-black">
            <div className="">Grease</div>
            <div className="">
              {formatMoney(calculateCategoryTotal("Grease"))}
            </div>
          </div>
          <div className="flex flex-row justify-between px-2 border-b border-black">
            <div className="">Consumable</div>
            <div className="">
              {formatMoney(calculateCategoryTotal("Consumable"))}
            </div>
          </div>
          <div className="flex flex-row justify-between px-2 border-b border-black">
            <div className="">Parts</div>
            <div className="">
              {formatMoney(calculateCategoryTotal("Parts"))}
            </div>
          </div>
          <div className="flex flex-row justify-between px-2 border-b border-black">
            <div className="">Sub-Total</div>
            <div className=""></div>
          </div>
          <div className="flex flex-row justify-between border-t border-black font-bold py-1 px-2">
            <div className="font-bold">Exclusive of VAT </div>
            <div className=" font-bold ">
              {formatMoney(calculateGrandTotal())}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
