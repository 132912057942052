import Wrap from "../Wrap";
import Something from "../../../components/shared/Something";
import DateTool from "../../../components/toolbar/DateTool";
import AgeingWrapper from "./components/AgeingWrapper";
import StockSource from "./components/StockSource";
import { AiOutlineBarChart } from "react-icons/ai";
import { useState } from "react";
import moment from "moment";
import StockColor from "./components/StockColor";
import ModelChart from "./components/ModelChart";
import StatusChart from "./components/StatusChart";
import YearChart from "./components/YearChart";
import FuelChart from "./components/FuelChart";

export default function StockReports() {
  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });

  const handleDateChange = ({ startDate, endDate, isRange }: any) => {
    setDates({
      startDate,
      endDate,
      isRange,
    });
  };

  const isError = false;
  const isFetching = false;

  const refetch = () => {};
  return (
    <Wrap active="Stock Reports" dates={dates}>
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <div className="flex flex-col w-full">
          <DateTool stateHandler={handleDateChange} />

          <div className="p-2">
            {/* TOP WIDGET */}
            <AgeingWrapper dates={dates} />

            {/* MID WIDGET */}
            <div className="grid grid-cols-12 gap-2 mt-2">
              <div className="col-span-12 md:col-span-6 border bg-white">
                <ModelChart dates={dates} />
              </div>
              <div className="col-span-12 md:col-span-6 border bg-white">
                <StatusChart dates={dates} />
              </div>
            </div>
            <div className="grid grid-cols-12 gap-2 mt-2">
              <div className="col-span-12 md:col-span-6 border bg-white">
                <YearChart dates={dates} />
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="grid grid-cols-12 gap-x-2">
                  <div className="col-span-12 md:col-span-6 border bg-white">
                    <FuelChart dates={dates} />
                  </div>
                  <div className="col-span-12 md:col-span-6 border  bg-white">
                    <StockSource dates={dates} />
                  </div>
                </div>
                {/* <ColorChart dates={dates} /> */}
              </div>
            </div>
            <div className="grid grid-cols-12 gap-2 mt-2">
              <div className="col-span-12 md:col-span-6">
                <StockColor dates={dates} />
              </div>
              <div className="col-span-12 md:col-span-6 bg-white">
                <div className="flex flex-col justify-center items-center h-full w-full">
                  <div className="">
                    <AiOutlineBarChart size={30} />
                  </div>
                  <div className="mt-2">No Records Found</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrap>
  );
}
