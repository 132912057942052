import Wrap from "../Wrap";
import Something from "../../../components/shared/Something";
import ConvWidget from "./components/ConvWidget";
import SoldValue from "./components/SoldValue";
import StatusWrapper from "./components/StatusWrapper";
import SalesConsultant from "./components/SalesConsultant";
import SalesModel from "./components/SalesModel";
import { useState } from "react";
import moment from "moment";
import DateTool from "../../../components/toolbar/DateTool";

export default function SalesReports() {
  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });

  const handleDateChange = ({ startDate, endDate, isRange }: any) => {
    setDates({
      startDate,
      endDate,
      isRange,
    });
  };

  const isError = false;
  const isFetching = false;

  const refetch = () => {};
  return (
    <Wrap active="Sales Reports" dates={dates}>
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <div className="flex flex-col w-full">
          <DateTool stateHandler={handleDateChange} />

          <div className="p-2">
            {/* TOP WIDGET */}
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-12 md:col-span-4">
                <ConvWidget dates={dates} />
              </div>

              <div className="col-span-12 md:col-span-8">
                <SoldValue dates={dates} />
              </div>
            </div>

            {/* MID WIDGET */}

            <div className="mt-2">
              <StatusWrapper dates={dates} />
              <SalesConsultant dates={dates} />
              <SalesModel dates={dates} />
            </div>
          </div>
        </div>
      )}
    </Wrap>
  );
}
