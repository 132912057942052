import Wrap from "../Wrap";
import Something from "../../../components/shared/Something";
import { useState } from "react";
import moment from "moment";
import DateTool from "../../../components/toolbar/DateTool";
import SourceWrapper from "./components/SourceWrapper";
import StatusChart from "./components/StatusChart";
import ModelChart from "./components/ModelChart";
import LocationChart from "./components/LocationChart";
import TechnicianChart from "./components/TechnicianChart";
import ApprovedChart from "./components/ApprovedChart";

export default function AfterReports() {
  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });

  const handleDateChange = ({ startDate, endDate, isRange }: any) => {
    setDates({
      startDate,
      endDate,
      isRange,
    });
  };

  const isError = false;
  const isFetching = false;

  const refetch = () => {};

  return (
    <Wrap active="Aftersales Reports" dates={dates}>
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <div className="flex flex-col w-full">
          <DateTool stateHandler={handleDateChange} />

          <div className="p-2">
            <SourceWrapper />

            {/* MID WIDGET */}
            <div className="grid grid-cols-12 gap-2 mt-2">
              <div className="col-span-12 md:col-span-6 border bg-white">
                <ModelChart dates={dates} />
              </div>
              <div className="col-span-12 md:col-span-6 border bg-white">
                <StatusChart dates={dates} />
              </div>
            </div>

            <div className="grid grid-cols-12 gap-2 mt-2">
              <div className="col-span-12 md:col-span-6 border bg-white">
                <LocationChart dates={dates} />
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-12 md:col-span-6  border bg-white">
                    <TechnicianChart dates={dates} />
                  </div>
                  <div className="col-span-12 md:col-span-6 border bg-white">
                    <ApprovedChart dates={dates} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrap>
  );
}
