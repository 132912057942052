const vehicleStatusColors: any = {
  available: "#22C55E",
  reserved: "#EAB308",
  returned: "#3b82f6",
  sold: "#F04D6A",
};

const proformaStatusColors: any = {
  new: "#6b7280",
  invalid: "#ea580c",
  approved: "#16a34a",
  rejected: "#dc2626",
  updated: "#2563eb",
};

const proformaApprovalColors: any = {
  new: "border border-gray-300 text-sky-800 bg-gray-50 p-1 text-xs",
  invalid: "border border-yellow-500 text-yellow-600 bg-yellow-50 p-1 text-xs",
  approved: "border border-green-400 text-green-600 bg-green-50 p-1 text-xs",
  rejected: "border border-red-300 text-red-600 bg-red-50 p-1 text-xs",
  updated: "border border-blue-300 text-blue-800 bg-blue-50 p-1 text-xs",
};

const manageApprovalColors: any = {
  new: "border border-gray-300 text-sky-800 white p-1 text-xs",
  invalid: "border border-yellow-500 text-yellow-600 bg-yellow-50 p-1 text-xs",
  approved: "border border-green-400 text-green-600 bg-green-50 p-1 text-xs",
  rejected: "border border-red-300 text-red-600 bg-red-50 p-1 text-xs",
  updated: "border border-blue-300 text-blue-800 bg-blue-50 p-1 text-xs",
};

const proformaApprovalColor: any = {
  new: "border border-gray-400 text-gray-800 p-1 text-xs",
  invalid: "border border-yellow-300 text-yellow-600 p-1 text-xs",
  approved: "border border-green-400 text-green-600 p-1 text-xs",
  rejected: "border border-red-300 text-red-600 p-1 text-xs",
  updated: "border border-blue-300 text-blue-800 p-1 text-xs",
};

// const status: any = {
//   available: "#2CB663",
//   reserved: "#FFDB45",
//   returned: "#5766ED",
//   sold: "#F04D6A",
// };

export {
  vehicleStatusColors,
  proformaStatusColors,
  manageApprovalColors,
  proformaApprovalColors,
  proformaApprovalColor,
};
