import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router";

export default function BackTool({ actions }: any) {
  const navigate = useNavigate();
  return (
    <div className="border-b  bg-white flex justify-between h-11">
      <div className="flex flex-row items-center px-2">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center text-sm text-gray-600 focus:outline-none hover:text-red-500 py-2"
        >
          <BsChevronLeft />
          <span className="text-xs">Back</span>
        </button>
      </div>
      <div className="flex items-center">{actions}</div>
    </div>
  );
}
