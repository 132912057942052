import { useContext, useEffect, useState } from "react";

import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { AuthContext } from "../../../context/AuthContext";
import { AUTO_BASE_URL, TRACE_BASE_URL } from "../../../utils/urls";
export default function UpdateDetails({ isVisible, setVisible }: any) {
  const { user, updateUser, auth, updateAuth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    const uri = `users/${user?._id}`;
    const result = await fetch(`${TRACE_BASE_URL}/${uri}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
    const response = await result.json();

    if (response.success) {
      const { firstname, lastname, email, mobile } =
        await form.validateFields();

      const data = {
        name: `${firstname} ${lastname}`,
        email,
        mobile,
      };
      const uri = `api/users/${auth?._id}`;
      const local = await fetch(`${AUTO_BASE_URL}/${uri}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const localjson = await local.json();

      message.success("Updated successfully");
      updateUser(response?.payload);
      updateAuth(localjson.payload);
      setVisible(false);
    } else {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, user]);

  return (
    <Modal
      open={isVisible}
      title={<div className="flex">Update Details</div>}
      onCancel={() => setVisible(!isVisible)}
      width={680}
      footer={[
        <Button key="back" danger onClick={() => setVisible(!isVisible)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updDetails"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updDetails"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="firstname"
              initialValue={user?.firstname}
              rules={[
                {
                  required: true,
                  message: "Please enter firstname",
                },
              ]}
            >
              <Input placeholder="First Name" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="lastname"
              initialValue={user?.lastname}
              rules={[
                {
                  required: true,
                  message: "Please enter lastname",
                },
              ]}
            >
              <Input placeholder="Last Name" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              initialValue={user?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input
                type={"email"}
                placeholder="Email"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              initialValue={user?.mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
