import Moment from "react-moment";

export default function DocumentCustomer({ proforma }: any) {
  const year = new Date(proforma?.dates?.created).getFullYear();

  const inquiry = proforma?.vehicles?.length > 1 ? "vehicles" : "vehicle";
  return (
    <div className="mt-4">
      <div className="flex">
        <div className="font-bold w-20">PFI No:</div>
        <div className="">
          <div className="uppercase">
            {proforma?.source?.name === "Off-Lease"
              ? "SFL/SSBCL"
              : "KJL/AUTOMARK"}
            /{proforma?.pfi}/{year}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="font-bold w-20">Date:</div>
        <div className="">
          <Moment format="DD/MM/YYYY">{proforma?.dates?.created}</Moment>
        </div>
      </div>

      <div className="mt-3">
        <div className="font-bold">{proforma?.contact?.name}</div>
        <div className="">{proforma?.contact?.address || "-"}</div>
      </div>

      <div className="mt-4">
        <div className="">Dear Sir/Madam</div>
        <div className="">
          In response to your inquiry for the pre-owned {inquiry}, we have
          pleasure in quoting as below.
        </div>
      </div>
    </div>
  );
}
