import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchServiceSites = async () => {
  const uri = `api/support/services/sites`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateServiceSite = async (data: any) => {
  const uri = `api/support/services/sites`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateServiceSite = async ({ data, _id }: any) => {
  const uri = `api/support/services/sites/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteServiceSite = async ({ _id }: any) => {
  const uri = `api/support/services/sites/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchServiceSites,
  handleCreateServiceSite,
  handleUpdateServiceSite,
  handleDeleteServiceSite,
};
