import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function SideMenu({ menu }: any) {
  const { name, icon, path } = menu;

  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: false });
  return (
    <div
      className={`flex justify-center items-center  py-2 ${
        match
          ? "border-r-2 border-toyota bg-red-50 border-automark-dark"
          : "border-r-2 border-white"
      }`}
    >
      <Link to={path}>
        <div className="flex flex-col justify-center items-center">
          <div
            className={`flex flex-col justify-center items-center  rounded-lg ${
              match ? "" : ""
            }`}
          >
            <div
              className={`my-2 ${
                match ? "text-automark-dark" : "text-gray-500"
              }`}
            >
              {icon}
            </div>
          </div>
          <div
            className={`text-xs  ${
              match ? "text-automark-dark" : "text-gray-500"
            }`}
          >
            {name}
          </div>
        </div>
      </Link>
    </div>
  );
}
