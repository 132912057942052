import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useEffect } from "react";
import { handleUpdateModel } from "../../../../api/models";

const { Option } = Select;

export default function UpdateModel({ isVisible, setVisible, selected }: any) {
  const { _id } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateModel,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["models"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  const makes = [
    { id: 1, name: "Toyota" },
    { id: 2, name: "Lexus" },
    { id: 3, name: "Hino" },
  ];

  const makeOptions = makes?.map((make: any, index: number) => (
    <Option value={make?.name} key={index}>
      {make?.name}
    </Option>
  ));

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update Model"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateModel"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateModel"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Model Name"
              rules={[
                {
                  required: true,
                  message: "Please enter model name",
                },
              ]}
            >
              <Input type="" placeholder="Model Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="code"
              label="Model Code"
              rules={[
                {
                  required: true,
                  message: "Please enter model code",
                },
              ]}
            >
              <Input type="" placeholder="Model Code" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="make"
              label={"Make"}
              rules={[
                {
                  required: true,
                  message: "Please select make",
                },
              ]}
            >
              <Select placeholder="Make">{makeOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
