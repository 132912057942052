import Wrap from "../Wrap";
import IDisInquiries from "./IDisInquiries";

export default function DiscardedInquiries() {
  return (
    <Wrap active="Inquiries" child={<></>} actions={<></>}>
      <IDisInquiries inCRM={true} />
    </Wrap>
  );
}
