import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchInquiries = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/inquiries?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};



const handleFetchNewInquiries = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/inquiries/new?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDisInquiries = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/inquiries/discarded?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchConInquiries = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/inquiries/contacted?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleFetchArcInquiries = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/inquiries/archived?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchInquiry = async ({ _id }: any) => {
  if (_id === undefined) {
    return {};
  }
  const uri = `api/crm/inquiries/${_id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateInquiry = async (data: any) => {
  const uri = `api/crm/inquiries`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleAssignInquiry = async ({ data, inquiry_id }: any) => {
  const uri = `api/crm/inquiries/${inquiry_id}/assign`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleRespondInquiry = async ({ data, _id }: any) => {
  const uri = `api/crm/inquiries/${_id}/respond`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateInquiry = async ({ data, _id }: any) => {
  const uri = `api/crm/inquiries/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteInquiry = async ({ _id }: any) => {
  const uri = `api/crm/inquiries/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchInquiries,
  handleFetchInquiry,
  handleAssignInquiry,
  handleRespondInquiry,
  handleCreateInquiry,
  handleUpdateInquiry,
  handleDeleteInquiry,


  handleFetchNewInquiries,
  handleFetchDisInquiries,
  handleFetchConInquiries,
  handleFetchArcInquiries,
};
