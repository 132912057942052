import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, message } from "antd";
import { useEffect } from "react";
import { handleDeleteLead } from "../../../../api/leads";
import { useNavigate } from "react-router";

export default function DeleteLead({
  isVisible,
  setVisible,
  lead,
  lead_id,
}: any) {
  const { _id } = lead;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleDeleteLead,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
      navigate(-1);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    await mutate({ _id });
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [lead, form]);

  return (
    <Modal
      open={isVisible}
      title="Delete Lead"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteLead"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        id="deleteLead"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center justify-center my-8">
          <div>
            Are you Sure you want to
            <span className="text-automark-dark font-bold mx-1">Delete</span>
            Lead
          </div>
          <div className="font-bold text-lg">{lead?.title}?</div>
        </div>
      </Form>
    </Modal>
  );
}
