import { Button, Modal, Form, Row, Col, Input, message, Checkbox } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { handleCreateIndustry } from "../../../../api/industries";

export default function CreateIndustry({ isVisible, setVisible }: any) {
  const [isActive, setActive] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateIndustry,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["industries"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      isActive,
      ...values,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Create Industry"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createIndustry"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createIndustry"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Checkbox
              onChange={(e: any) => {
                setActive(e.target.checked);
              }}
              defaultChecked={isActive}
            >
              Is Active
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
