import Something from "../../../components/shared/Something";
import DealChart from "./components/DealChart";
import DealSources from "./components/DealSources";
import DealStatus from "./components/DealStatus";
import Wrap from "../Wrap";
import DateTool from "../../../components/toolbar/DateTool";
import { useState } from "react";
import moment from "moment";
import ModelWrapper from "./components/ModelWrapper";
import ConsultWrapper from "./components/ConsultWrapper";
import TopWrapper from "./components/TopWrapper";

export default function DealReports() {
  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });

  const handleDateChange = ({ startDate, endDate, isRange }: any) => {
    setDates({
      startDate,
      endDate,
      isRange,
    });
  };

  const isError = false;
  const isFetching = false;

  const refetch = () => {};

  return (
    <Wrap active="Deals Reports" dates={dates}>
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <div className="flex flex-col w-full">
          <DateTool stateHandler={handleDateChange} />

          <div className="p-2">
            {/* TOP WIDGET */}
            <TopWrapper dates={dates} />

            {/* MID WIDGET */}
            <div className="grid grid-cols-12 gap-2 mt-2">
              <div className="col-span-12 md:col-span-8">
                <ConsultWrapper dates={dates} />
                <DealSources dates={dates} />
                <DealStatus dates={dates} />
                <ModelWrapper dates={dates} />
              </div>
              <div className="col-span-12 md:col-span-4 bg-white border">
                <DealChart data={[]} dates={dates} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrap>
  );
}
