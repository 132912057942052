export default function Conversion({ deals }: any) {
  const getConversion = () => {
    let total = 0;
    let won = 0;
    deals?.forEach((i: any) => {
      if (i?._id === "won") {
        won = won + i?.total;
      }
      total = total + i?.total;
    });
    const percentage = Math.max((won / total) * 100, 0).toFixed(1);
    const position = Math.max(1 - won / total, 0);
    return { percentage, position };
  };
  const DIAMETER = 60;
  const STROKE_WIDTH = 6;
  const RADIUS = DIAMETER / 2 - STROKE_WIDTH / 2;

  const CIRCUMFERENCE = Math.PI * RADIUS * 2;

  const { percentage, position } = getConversion();

  return (
    <div className=" bg-white border  rounded-sm h-36">
      <div className="mx-8">
        <div className="flex justify-center items-center mt-2">
          <div className="flex justify-center items-center">
            <svg
              viewBox="0 0 80 80"
              width="160px"
              height="160px"
              className="circle-progress z-0"
            >
              <circle
                cx={DIAMETER / 2}
                cy={DIAMETER / 2}
                r={RADIUS}
                stroke="#16a34a"
                fill="transparent"
                strokeWidth={STROKE_WIDTH}
                style={{
                  strokeDasharray: CIRCUMFERENCE,
                  strokeDashoffset: CIRCUMFERENCE * position,
                }}
              />
            </svg>
            <div
              style={{
                backgroundColor: "#d1d5db",
                width: "120px",
                height: "120px",
              }}
              className="w-28 h-28 rounded-full flex justify-center items-center -ml-40 -mt-10"
            >
              <div className="w-24 h-24 flex-col rounded-full bg-white flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <div className="">Deals</div>
                  <div className="">Conversion</div>
                </div>
                <div className="font-bold text-2xl">
                  {isNaN(Number(percentage)) ? 0 : percentage}
                  <span className="text-xs">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
