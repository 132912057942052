import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleUpdateContact } from "../../../../api/contacts";
import { useEffect } from "react";

export default function UpdateSocials({ isVisible, setVisible, contact }: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateContact,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      social: {
        ...values,
      },
    };
    await mutate({ data, _id: contact?._id });
  };

  const clearForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ facebook: contact?.social?.facebook });
    form.setFieldsValue({ instagram: contact?.social?.instagram });
    form.setFieldsValue({ whatsapp: contact?.social?.whatsapp });
    form.setFieldsValue({ linkedin: contact?.social?.linkedin });
    form.setFieldsValue({ twitter: contact?.social?.twitter });
    form.setFieldsValue({ tiktok: contact?.social?.tiktok });
    form.setFieldsValue({ redit: contact?.social?.redit });
  }, [contact]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">UPDATE : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">Socials</div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateContactSocials"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="facebook"
                label="Facebook"
                initialValue={contact?.social?.facebook}
                rules={[
                  {
                    required: false,
                    message: "Please enter facebook",
                  },
                ]}
              >
                <Input
                  type=""
                  placeholder="https://www.facebook.com/username"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="instagram"
                label="Instagram"
                initialValue={contact?.social?.instagram}
                rules={[
                  {
                    required: false,
                    message: "Please enter instagram",
                  },
                ]}
              >
                <Input
                  type=""
                  placeholder="https://www.instagram.com/username"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="whatsapp"
                label="WhatsApp"
                initialValue={contact?.social?.city}
                rules={[
                  {
                    required: false,
                    message: "Please enter whatsapp number",
                  },
                ]}
              >
                <Input type="" placeholder="https://wa.me/number" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="linkedin"
                label="LinkedIn"
                initialValue={contact?.social?.city}
                rules={[
                  {
                    required: false,
                    message: "Please enter linkedin",
                  },
                ]}
              >
                <Input
                  type=""
                  placeholder="https://www.linkedin.com/in/username"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="twitter"
                label="Twitter / X"
                initialValue={contact?.social?.twitter}
                rules={[
                  {
                    required: false,
                    message: "Please enter twitter",
                  },
                ]}
              >
                <Input type="" placeholder="https://twitter.com/username" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="tiktok"
                label="Tiktok"
                initialValue={contact?.social?.tiktok}
                rules={[
                  {
                    required: false,
                    message: "Please enter Tiktok",
                  },
                ]}
              >
                <Input type="" placeholder="https://tiktok.com/@username" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="reddit"
                label="Reddit"
                initialValue={contact?.social?.twitter}
                rules={[
                  {
                    required: false,
                    message: "Please enter reddit",
                  },
                ]}
              >
                <Input
                  type=""
                  placeholder="https://www.reddit.com/r/username/"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit" loading={isLoading}>
            Update
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
