import DocumentHeader from "./DocumentHeader";
import DocumentCustomer from "./DocumentCustomer";
import DocumentPricing from "./DocumentPricing";
import DocumentDetails from "./DocumentDetails";
import DocumentBanks from "./DocumentBanks";
import DocumentSignature from "./DocumentSignature";
import DocumentFooter from "./DocumentFooter";
import "../../assets/styles/custom.css";

export default function AutomarkProforma({ proforma }: any) {
  return (
    <div className="px-16 pt-12" style={{ fontSize: 12 }}>
      <DocumentHeader  />
      <DocumentCustomer proforma={proforma} />
      <DocumentPricing proforma={proforma} />
      <DocumentDetails proforma={proforma} />
      <DocumentBanks />
      <DocumentSignature proforma={proforma} />
      <DocumentFooter />
    </div>
  );
}
