import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchServiceTypes = async () => {
  const uri = `api/support/services/types`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateServiceType = async (data: any) => {
  const uri = `api/support/services/types`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateServiceType = async ({ data, _id }: any) => {
  const uri = `api/support/services/types/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteServiceType = async ({ _id }: any) => {
  const uri = `api/support/services/types/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchServiceTypes,
  handleCreateServiceType,
  handleUpdateServiceType,
  handleDeleteServiceType,
};
