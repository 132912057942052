import { useState } from "react";
import DealActivity from "./DealActivity";
import DoneActivity from "./DoneActivity";
import UpdateActivity from "./UpdateActivity";
import DeleteActivity from "./DeleteActivity";

export default function DealActivities({ deal }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [doneModal, setDoneModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  const handleSelect = (activity: any) => {
    setSelected(activity);
  };
  return (
    <div className="mt-2">
      {deal?.activities?.map((activity: any, index: number) => (
        <DealActivity
          activity={activity || {}}
          key={index}
          isLoading={isLoading}
          handleSelect={handleSelect}
          isUpdateVisible={updateModal}
          setUpdateVisible={setUpdateModal}
          setDeleteVisible={setDeleteModal}
          setDoneVisible={setDoneModal}
        />
      ))}

      <DoneActivity
        isVisible={doneModal}
        setVisible={setDoneModal}
        deal_id={deal?._id}
        selected={selected?.selected || {}}
      />

      <UpdateActivity
        isVisible={updateModal}
        setVisible={setUpdateModal}
        deal_id={deal?._id}
        activity={selected?.selected || {}}
      />

      <DeleteActivity
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        deal_id={deal?._id}
        activity={selected?.selected || {}}
      />
    </div>
  );
}
