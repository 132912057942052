import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import CreateDate from "./CreateDate";
import Moment from "react-moment";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import UpdateDate from "./UpdateDate";
import DeleteDate from "./DeleteDate";

export default function DatesDetails({ contact, editMode }: any) {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  return (
    <div className="">
      <div className="flex justify-between pb-2 border-b-2">
        <div className="font-bold">Important Dates</div>
        {editMode && (

          <button onClick={() => setCreateModal(true)} className="border p-2">
          <AiOutlinePlus />
        </button>
          )}
      </div>

      {contact?.dates?.length === 0 ? (
        <div className="">
          <div className="text-gray-700 py-8">No Important Dates Found</div>
        </div>
      ) : (
        <div className="">
          {contact?.dates?.map((item: any, index: number) => (
            <div className="flex justify-between  my-4" key={index}>
              <div className="flex flex-col justify-center">
                <div className="flex">
                  <div className="w-20 font-bold text-lg">Date: </div>
                  {item?.date ? (
                    <Moment format="Do MMM, YYYY">{item?.date}</Moment>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="w-36">Description :</div>
                  <div className="">{item?.description || ""}</div>
                </div>
                <div className="flex flex-col">
                  <div className="w-36">Notes :</div>
                  <div className="">{item?.note || ""}</div>
                </div>
              </div>
              {editMode && (

                <div className="flex items-center justify-center">
                <button
                  onClick={() => {
                    setSelected(item);
                    setUpdateModal(true);
                  }}
                  className=""
                  >
                  <FiEdit />
                </button>
                <button
                  onClick={() => {
                    setSelected(item);
                    setDeleteModal(true);
                  }}
                  className="ml-1 text-red-600"
                  >
                  <FiTrash2 />
                </button>
              </div>
                  )}
            </div>
          ))}
        </div>
      )}

      <CreateDate
        isVisible={createModal}
        setVisible={setCreateModal}
        contact={contact}
      />

      <UpdateDate
        isVisible={updateModal}
        setVisible={setUpdateModal}
        contact={contact}
        selected={selected}
      />

      <DeleteDate
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        contact={contact}
        selected={selected}
      />
    </div>
  );
}
