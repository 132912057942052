import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import Signin from "./pages/auth/Signin";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Denied from "./pages/auth/Denied";
import Main from "./pages/manage/Main";
import AllUsers from "./pages/manage/users/AllUsers";
import Page404 from "./pages/errors/Page404";
import Home from "./pages/home";
import AllModels from "./pages/manage/models/AllModels";
import AllRoles from "./pages/manage/roles/AllRoles";
import AllSources from "./pages/manage/sources/AllSources";
import AllLocations from "./pages/manage/locations/AllLocations";
import AllFeatures from "./pages/manage/features/AllFeatures";
import AllServiceTypes from "./pages/manage/servicetypes/AllServiceTypes";
import AllServiceSites from "./pages/manage/servicesites/AllServiceSites";
import AllDealTitles from "./pages/manage/dealtitles/AllDealTitles";
import AllDealStages from "./pages/manage/dealstages/AllDealStages";
import AllRemarks from "./pages/manage/remarks/AllRemarks";
import AllReasons from "./pages/manage/reasons/AllReasons";
import AllTechnicians from "./pages/manage/technicians/AllTechnicians";
import AllCrmSources from "./pages/manage/crmsources/AllCrmSources";
import AllQuestions from "./pages/manage/questions/AllQuestions";
import Proformas from "./pages/proforma/Proformas";
import AllContacts from "./pages/manage/contacts/AllContacts";
import MyProfile from "./pages/account/MyProfile";
import React from "react";
import AllVehicles from "./pages/manage/vehicles/AllVehicles";
import Vehicles from "./pages/stock/Vehicles";
import OneVehicle from "./pages/manage/vehicles/OneVehicle";
import AllIndustries from "./pages/manage/industries/AllIndustries";
import AllAllowances from "./pages/manage/allowances/AllAllowances";
import AllProforma from "./pages/manage/proforma/AllProforma";
import Crm from "./pages/crm";
import Contacts from "./pages/crm/contacts/Contacts";
import OngoingDeals from "./pages/crm/deals/OngoingDeals";
import Reports from "./pages/reports";
import DealReports from "./pages/reports/deals/DealReports";
import LeadReports from "./pages/reports/leads/LeadReports";
import StockReports from "./pages/reports/stock/StockReports";
import SalesReports from "./pages/reports/sales/SalesReports";
import AfterReports from "./pages/reports/after/AfterReports";
import Services from "./pages/services";
import AllServices from "./pages/services/AllServices";
import PendingServices from "./pages/services/PendingServices";
import InprogressServices from "./pages/services/InprogressServices";
import CompletedServices from "./pages/services/CompletedServices";
import RejectedServices from "./pages/services/RejectedServices";
import OngoingLeads from "./pages/crm/leads/OngoingLeads";
import QualifiedLeads from "./pages/crm/leads/QualifiedLeads";
import ArchievedLeads from "./pages/crm/leads/ArchievedLeads";
import DetailedLead from "./pages/crm/leads/DetailedLead";
import DetailedService from "./pages/services/DetailedService";
import Suppliers from "./pages/crm/suppliers/Suppliers";
import WonDeals from "./pages/crm/deals/WonDeals";
import LostDeals from "./pages/crm/deals/LostDeals";
import ArchievedDeals from "./pages/crm/deals/ArchievedDeals";
import DetailedDeal from "./pages/crm/deals/DetailedDeal";
import OneContact from "./pages/manage/contacts/OneContact";
import AllAvailabilities from "./pages/manage/availability/AllAvailabilities";
import AllBudgets from "./pages/manage/budget/AllBudgets";
import NewInquiries from "./pages/crm/inquiries/NewInquiries";
import ContactedInquiries from "./pages/crm/inquiries/ContactedInquiries";
import DiscardedInquiries from "./pages/crm/inquiries/DiscardedInquiries";
import ArchivedInquiries from "./pages/crm/inquiries/ArchivedInquiries";
import INewInquiries from "./pages/crm/inquiries/INewInquiries";
import IConInquiries from "./pages/crm/inquiries/IConInquiries";
import IDisInquiries from "./pages/crm/inquiries/IDisInquiries";
import IArcInquiries from "./pages/crm/inquiries/IArcInquiries";
import VehicleServices from "./pages/manage/vehicles/VehicleServices";
import NewAppointments from "./pages/crm/inquiries/NewAppointments";
import INewAppointments from "./pages/crm/inquiries/INewAppointments";

export default function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Signin />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/denied" element={<Denied />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/proforma"
              element={
                <ProtectedRoute>
                  <Proformas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stock"
              element={
                <ProtectedRoute>
                  <Vehicles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm"
              element={
                <ProtectedRoute>
                  <Crm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/contacts"
              element={
                <ProtectedRoute>
                  <Contacts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/suppliers"
              element={
                <ProtectedRoute>
                  <Suppliers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/leads/new"
              element={
                <ProtectedRoute>
                  <OngoingLeads />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/leads/qualified"
              element={
                <ProtectedRoute>
                  <QualifiedLeads />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/leads/archieved"
              element={
                <ProtectedRoute>
                  <ArchievedLeads />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm/leads/:id/detailed"
              element={
                <ProtectedRoute>
                  <DetailedLead />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/deals/new"
              element={
                <ProtectedRoute>
                  <OngoingDeals />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/deals/won"
              element={
                <ProtectedRoute>
                  <WonDeals />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/deals/lost"
              element={
                <ProtectedRoute>
                  <LostDeals />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crm/deals/archived"
              element={
                <ProtectedRoute>
                  <ArchievedDeals />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm/deals/:id/detailed"
              element={
                <ProtectedRoute>
                  <DetailedDeal />
                </ProtectedRoute>
              }
            />

            <Route
              path="/inquiries"
              element={
                <ProtectedRoute>
                  <INewInquiries />
                </ProtectedRoute>
              }
            />

            <Route
              path="/inquiries/contacted"
              element={
                <ProtectedRoute>
                  <IConInquiries />
                </ProtectedRoute>
              }
            />

            <Route
              path="/inquiries/discarded"
              element={
                <ProtectedRoute>
                  <IDisInquiries />
                </ProtectedRoute>
              }
            />

            <Route
              path="/inquiries/archived"
              element={
                <ProtectedRoute>
                  <IArcInquiries />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm/inquiries/new"
              element={
                <ProtectedRoute>
                  <NewInquiries />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm/inquiries/contacted"
              element={
                <ProtectedRoute>
                  <ContactedInquiries />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm/inquiries/discarded"
              element={
                <ProtectedRoute>
                  <DiscardedInquiries />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm/inquiries/archived"
              element={
                <ProtectedRoute>
                  <ArchivedInquiries />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm/inquiries/:id/detailed"
              element={
                <ProtectedRoute>
                  <DetailedDeal />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm/inquiries/appointments"
              element={
                <ProtectedRoute>
                  <NewAppointments />
                </ProtectedRoute>
              }
            />

            <Route
              path="/inquiries/appointments"
              element={
                <ProtectedRoute>
                  <INewAppointments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services"
              element={
                <ProtectedRoute>
                  <Services />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services/all"
              element={
                <ProtectedRoute>
                  <AllServices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services/pending"
              element={
                <ProtectedRoute>
                  <PendingServices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services/rejected"
              element={
                <ProtectedRoute>
                  <RejectedServices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services/inprogress"
              element={
                <ProtectedRoute>
                  <InprogressServices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services/completed"
              element={
                <ProtectedRoute>
                  <CompletedServices />
                </ProtectedRoute>
              }
            />

            <Route
              path="/services/:id/detailed"
              element={
                <ProtectedRoute>
                  <DetailedService />
                </ProtectedRoute>
              }
            />

            <Route
              path="/reports"
              element={
                <ProtectedRoute>
                  <Reports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reports/deals"
              element={
                <ProtectedRoute>
                  <DealReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reports/leads"
              element={
                <ProtectedRoute>
                  <LeadReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reports/stock"
              element={
                <ProtectedRoute>
                  <StockReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reports/sales"
              element={
                <ProtectedRoute>
                  <SalesReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reports/aftersales"
              element={
                <ProtectedRoute>
                  <AfterReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account/profile"
              element={
                <ProtectedRoute>
                  <MyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage"
              element={
                <ProtectedRoute>
                  <Main />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/users"
              element={
                <ProtectedRoute>
                  <AllUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/roles"
              element={
                <ProtectedRoute>
                  <AllRoles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/models"
              element={
                <ProtectedRoute>
                  <AllModels />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/sources"
              element={
                <ProtectedRoute>
                  <AllSources />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/locations"
              element={
                <ProtectedRoute>
                  <AllLocations />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manage/availabilities"
              element={
                <ProtectedRoute>
                  <AllAvailabilities />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/features"
              element={
                <ProtectedRoute>
                  <AllFeatures />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/allowance"
              element={
                <ProtectedRoute>
                  <AllAllowances />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manage/proforma"
              element={
                <ProtectedRoute>
                  <AllProforma />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/questions"
              element={
                <ProtectedRoute>
                  <AllQuestions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/service/types"
              element={
                <ProtectedRoute>
                  <AllServiceTypes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/service/sites"
              element={
                <ProtectedRoute>
                  <AllServiceSites />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/deal/titles"
              element={
                <ProtectedRoute>
                  <AllDealTitles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/deal/stages"
              element={
                <ProtectedRoute>
                  <AllDealStages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/remarks"
              element={
                <ProtectedRoute>
                  <AllRemarks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/reasons"
              element={
                <ProtectedRoute>
                  <AllReasons />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/technicians"
              element={
                <ProtectedRoute>
                  <AllTechnicians />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/industries"
              element={
                <ProtectedRoute>
                  <AllIndustries />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/crm/sources"
              element={
                <ProtectedRoute>
                  <AllCrmSources />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manage/budget"
              element={
                <ProtectedRoute>
                  <AllBudgets />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manage/inquiries"
              element={
                <ProtectedRoute>
                  <AllCrmSources />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manage/crm/contacts"
              element={
                <ProtectedRoute>
                  <AllContacts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/crm/contacts/:id"
              element={
                <ProtectedRoute>
                  <OneContact />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/stock"
              element={
                <ProtectedRoute>
                  <AllVehicles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/stock/:chassis/details"
              element={
                <ProtectedRoute>
                  <OneVehicle />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/stock/services/:id"
              element={
                <ProtectedRoute>
                  <VehicleServices />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

function ProtectedRoute({ children }: { children: JSX.Element }) {
  let { token } = React.useContext(AuthContext);
  let location = useLocation();
  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}
