import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchCheckpoints = async () => {
  const uri = `api/support/inspections`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateCheckpoint = async ({ data }: any) => {
  const uri = `api/support/inspections`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleUpdateCheckpoint = async ({ data, checkpoint_id }: any) => {
  const uri = `api/support/inspections/${checkpoint_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteCheckpoint = async ({ checkpoint_id }: any) => {
  const uri = `api/support/inspections/${checkpoint_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleCreateQuestion = async ({ checkpoint_id, data }: any) => {
  const uri = `api/support/inspections/${checkpoint_id}/questions`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteQuestion = async ({
  checkpoint_id,
  question_id,
  data,
}: any) => {
  const uri = `api/support/inspections/${checkpoint_id}/questions/${question_id}`;
  const response = deleteDataAuto({ data, uri });
  return response;
};

export {
  handleFetchCheckpoints,
  handleCreateCheckpoint,
  handleUpdateCheckpoint,
  handleDeleteCheckpoint,
  handleCreateQuestion,
  handleDeleteQuestion,
};
