import { useState } from "react";
import {
  EditOutlined,
  SaveOutlined,
  SmallDashOutlined,
} from "@ant-design/icons";
import { Col, Form, Input, Row, message } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateServiceWip } from "../../../api/services";

export default function ServiceWipno({
  service_id,
  status,
  remark,
  wip,
  auth,
  serviceAccess,
}: any) {
  const [selected, setSelected] = useState(remark);
  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: handleUpdateServiceWip,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["services", service_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    await mutate({ service_id, data });
    setEdit(false);
  };

  return (
    <div className="bg-white text-xs">
      <div className="flex justify-between items-center px-2 py-2 border-b">
        <div className="">WIP Number</div>

        {status !== "completed" && (
          <div className="">
            {serviceAccess?.includes(auth?.accessLevel) && (
              <div className="">
                {edit ? (
                  <div className="flex">
                    <button
                      onClick={() => setEdit(false)}
                      className="flex justify-center items-center border rounded-md p-2 focus:outline-none hover:bg-red-50 text-red-800"
                    >
                      <AiOutlineClose />
                    </button>

                    <button
                      onClick={() => onSubmit()}
                      className="flex justify-center items-center border rounded-md p-2 ml-1 focus:outline-none hover:bg-green-50 text-green-800"
                    >
                      <SaveOutlined />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => setEdit(true)}
                    className="flex justify-center items-center border rounded-md p-2 focus:outline-none hover:bg-greed-50"
                  >
                    <EditOutlined />
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {edit ? (
        <div className="p-2">
          <Form
            id="UpdateServiceWip"
            form={form}
            name="normal_login"
            className="login-form"
            onFinish={onSubmit}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="wip"
                  initialValue={wip}
                  rules={[
                    {
                      required: true,
                      message: "Please enter wip number",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Wip Number" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <div className="p-2">
          {selected === null ? (
            <span className="text-gray-500">
              <SmallDashOutlined />
            </span>
          ) : (
            <div className="">{wip}</div>
          )}
        </div>
      )}
    </div>
  );
}
