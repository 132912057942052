import { useEffect } from "react";
import Moment from "react-moment";
import { Tooltip as Tip } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { RiFileExcel2Line } from "react-icons/ri";

export default function DealChart({ data, dates }: any) {
  // const { data, refetch, isLoading, isFetching, isError } = useQuery({
  //   queryKey: ["dealValues"],
  //   queryFn: handleFetchDealValuesReport,
  // });

  const a = data?.payload?.conversion;

  const rows = [
    {
      name: "Qualified",
      count: 0,
      value: 0,
      amt: 0,
    },
    {
      name: "Demo",
      count: 0,
      value: 0,
      amt: 2210,
    },
    {
      name: "Proposal Sent",
      count: 0,
      value: 0,
      amt: 2290,
    },
    {
      name: "Won",
      count: 0,
      value: 0,
      amt: 2000,
    },
    {
      name: "Lost",
      count: 0,
      value: 0,
      amt: 2181,
    },
  ];

  let deals: any = [];
  const prepareData = () => {
    a?.forEach((item: any) => {
      let d = {
        label: item?.label,
        count: Number(item?.count),
        value: item?.value,
      };
      deals.push(d);
    });
  };

  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;

    return (
      <text
        x={x + 17}
        y={y}
        dy={-4}
        fill={stroke}
        fontSize={10}
        textAnchor={"middle"}
      >
        {value}
      </text>
    );
  };

  useEffect(() => {
    prepareData();
  }, []);

  return (
    <div className="mb-4" style={{ width: "100%" }}>
      <div className="flex flex-row justify-between items-center  border-b border-gray-200 pl-2 mb-4">
        <div className="py-2">
          <h4 className="text-gray-800">
            <span className="text-gray-900 font-bold text-lg">
              Deals By Stages
            </span>
          </h4>
          <p className="text-gray-600 text-sm">
            Deal Values and Counts by deal stage
          </p>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center text-gray-500 text-xs">
            {dates?.isRange ? (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment> -{" "}
                <Moment format={"Do MMM, YYYY"}>{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment>
              </div>
            )}
          </div>
          <div className="mx-2">
            <Tip title="Export To Excel">
              <button
                color="#E50000"
                className="flex justify-center items-center border px-4 py-1 hover:bg-gray-100"
                // onClick={() => exportStockByFuel({ data: stock })}
              >
                <RiFileExcel2Line size={18} />
              </button>
            </Tip>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={rows}
          margin={{
            top: 50,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <Bar
            yAxisId="left"
            dataKey="count"
            fill="#8884d8"
            label={CustomizedLabel}
            name="Deals By Count"
          />
          <Bar
            yAxisId="right"
            dataKey="value"
            fill="#82ca9d"
            label={CustomizedLabel}
            name="Deals By Value"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
