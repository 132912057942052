import formatMoney from "../../../../utils/formatMoney";

export default function SourceWidget({ item, handleSelect }: any) {
  const getPercent = (item: any) => {
    let num = item?.total / item?.budget;
    let per = Math.round(num * 100);
    return isNaN(per) ? 0 : per;
  };

  return (
    <div className="col-span-12 sm:col-span-3 border border-gray-300 bg-white">
      <button className="w-full" onClick={() => handleSelect({ item })}>
        <div className="flex flex-col bg-purple-100">
          <div className="p-2 font-bold text-lg text-start">
            {item?._id} - Utilization [ {getPercent(item)} % ]
          </div>
          <div className="p-2 text-blue-800 text-start">
            Total Vehicle(s) Serviced: {item?.list?.length}
          </div>
          <div className="p-2 w-full">
            <div className="flex justify-between mb-1">
              <div className="">Budget Expenses :</div>
              <div className="">{formatMoney(item?.budget)}</div>
            </div>
            <div className="flex justify-between mb-1">
              <div className="">Actual Expenses :</div>
              <div className="">{formatMoney(item?.total)}</div>
            </div>

            {/* <div className="flex justify-between border-t-2 border-black pt-1">
              <div className="font-bold">Utilization :</div>
              [ {getPercent(item)} % ]
            </div> */}
          </div>
        </div>
      </button>
    </div>
  );
}
