import moment from "moment";
import { ExportToExcel } from "./exportExcel";

const exportAllStockData = ({ data, search }: any) => {
  ExportToExcel(
    data
      ?.filter((vehicle: any) => vehicle?.chassis?.includes(search))
      ?.map((item: any, index: number) => {
        return {
          "S/N": index + 1,
          Model: item?.model?.name === null ? "-" : item?.model?.name,
          Chassis: item?.chassis === null ? "-" : item?.chassis,
          Vsb: item?.vsb === null ? "-" : item?.vsb,
          Year: item?.year === null ? "-" : item?.year,
          Mileage: item?.mileage === null ? "-" : item?.mileage,
          Source: item?.source?.name === null ? "-" : item?.source?.name,
          Price:
            item?.prices?.exclusive === null ? "-" : item?.prices?.exclusive,

          Status: item?.status === null ? "-" : item?.status,
          Location: item?.location?.name === null ? "-" : item?.location?.name,
          "Fuel Type":
            item?.specifications?.fuel?.fuel_type === null
              ? "-"
              : item?.specifications?.fuel?.fuel_type,
          "Body Type":
            item?.specifications?.body?.body_type === null
              ? "-"
              : item?.specifications?.body?.body_type,
          Engine:
            item?.specifications?.engine?.engine_type === null
              ? "-"
              : item?.specifications?.engine?.engine_type,
          Transmission:
            item?.specifications?.transmission === null
              ? "-"
              : item?.specifications?.transmission,
          Steering:
            item?.specifications?.steering === null
              ? "-"
              : item?.specifications?.steering,
          "Drive Type":
            item?.specifications?.drive?.drive_type === null
              ? "-"
              : item?.specifications?.drive?.drive_type,
          EDT: item?.shipment?.estimated_departure
            ? moment(item?.shipment?.estimated_departure).format("DD/MM/YYYY")
            : "-",
          ETA: item?.shipment?.estimated_arrival
            ? moment(item?.shipment?.estimated_arrival).format("DD/MM/YYYY")
            : "-",
          ATA: item?.shipment?.actual_arrival
            ? moment(item?.shipment?.actual_arrival).format("DD/MM/YYYY")
            : "-",
        };
      }),
    "Vehicles.xlsx"
  );
};

const exportStockByModel = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Model: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "StockByModel.xlsx"
  );
};

const exportStockByStatus = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Status: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "StockByStatus.xlsx"
  );
};

const exportStockByYear = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Year: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "StockByYear.xlsx"
  );
};

const exportStockByFuel = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        "Fuel Type": item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "StockByFuel.xlsx"
  );
};

const exportStockBySource = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Source: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "StockBySource.xlsx"
  );
};

const exportStockByColor = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Color: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "StockByColor.xlsx"
  );
};

export {
  exportAllStockData,
  exportStockByModel,
  exportStockByStatus,
  exportStockByYear,
  exportStockByFuel,
  exportStockBySource,
  exportStockByColor,
};
