import StageLines from "./StageLines";

export default function StageWrapper({
  state,
  addHandler,
  focusHandler,
  changeHandler,
  deleteHandler,
  reorderHandler,
}: any) {
  return (
    <div className="">
      <StageLines
        items={state?.extems === undefined ? [] : state?.extems}
        addHandler={addHandler}
        changeHandler={changeHandler}
        focusHandler={focusHandler}
        deleteHandler={deleteHandler}
        reorderHandler={reorderHandler}
      />
    </div>
  );
}
