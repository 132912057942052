import { AiOutlineMail } from "react-icons/ai";
import { BsTelephoneOutbound } from "react-icons/bs";

export default function DealContacts({ deal }: any) {
  return (
    <div className="">
      <div className="flex justify-start items-center bg-white p-2 border h-12">
        <div className="font-bold text-sm"> CONTACTS</div>
      </div>
      <div className="">
        <div className="bg-white border p-4 mb-1 flex justify-between items-center">
          <div className="flex flex-col justify-center">
            <div className="text-gray-600 font-bold">{deal?.contact?.name}</div>
            <div className="text-xs mt-1">
              <div className="flex items-center text-gray-600">
                <AiOutlineMail />
                <span className="ml-1">: {deal?.contact?.email}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <BsTelephoneOutbound />
                <span className="ml-1">: {deal?.contact?.mobile}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
