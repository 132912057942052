import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchFeatures = async () => {
  const uri = `api/features`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateFeature = async (data: any) => {
  const uri = `api/features`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateFeature = async ({ data, _id }: any) => {
  const uri = `api/features/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};
const handleDeleteFeature = async ({ data, _id }: any) => {
  const uri = `api/features/${_id}`;
  const response = deleteDataAuto({ data, uri });
  return response;
};

export {
  handleFetchFeatures,
  handleCreateFeature,
  handleUpdateFeature,
  handleDeleteFeature,
};
