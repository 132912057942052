import moment from "moment";
import { ExportToExcel } from "./exportExcel";
import { capitalize } from "lodash";

const exportAllLeadsData = ({ data, search }: any) => {
  ExportToExcel(
    data
      ?.filter((lead: any) => lead?.contact?.name?.includes(search))
      ?.map((item: any, index: number) => {
        return {
          "S/N": index + 1,
          Contact: item?.contact?.name === null ? "-" : item?.contact?.name,
          Title: item?.title === null ? "-" : item?.title,
          Vehicles: item?.vehicles === null ? "-" : item?.vehicles?.length,
          Value: item?.value === null ? "-" : item?.value,
          "Supervised By":
            item?.supervisor?.name === null ? "-" : item?.supervisor?.name,
          "Created By":
            item?.creator?.name === null ? "-" : item?.creator?.name,
          "Created At": item?.createdAt
            ? moment(item?.createdAt).format("DD/MM/YYYY")
            : "-",

          Status:
            item?.status?.state === null
              ? "-"
              : capitalize(item?.status?.state),
        };
      }),
    "Leads.xlsx"
  );
};

const exportLeadsByModel = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Model: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "LeadsByModel.xlsx"
  );
};

const exportLeadsByStatus = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Status: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "LeadsByStatus.xlsx"
  );
};

const exportLeadsByConsultant = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Year: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "LeadsByConsultant.xlsx"
  );
};

const exportLeadsBySource = ({ data }: any) => {
  ExportToExcel(
    data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Source: item?._id === null ? "-" : item?._id,
        Vehicles: item?.total === null ? "-" : item?.total,
      };
    }),
    "LeadsBySource.xlsx"
  );
};

export {
  exportAllLeadsData,
  exportLeadsByModel,
  exportLeadsByStatus,
  exportLeadsByConsultant,
  exportLeadsBySource,
};
