import { Button, Modal, Form, Row, Col, message, Input } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateBudget } from "../../../../api/budget";

export default function CreateYearBudget({
  isVisible,
  setVisible,
  handleSelectYear,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateBudget,
    onSuccess: (data) => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["budgets"] });
      handleSelectYear(data?.year);
    },
    onError: (error: any) => {
      message.error(error);
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Create Budget"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createYearBudget"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Create
        </Button>,
      ]}
    >
      <Form
        id="createYearBudget"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="year"
              label="Budget Year"
              rules={[
                {
                  required: true,
                  message: "Please enter budget year",
                },
              ]}
            >
              <Input type="number" step={1} placeholder="Budget Year" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
