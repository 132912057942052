import { DeleteOutlined } from "@ant-design/icons";
import formatMoney from "../../../utils/formatMoney";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { handleRemoveServiceLine } from "../../../api/services";

export default function InvoiceItemEdit({ service_id, item }: any) {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: handleRemoveServiceLine,
    onSuccess: () => {
      message.success("Deleted Successfully");
      queryClient.invalidateQueries({ queryKey: ["services", service_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleDeleteServicePart = async ({ line_id }: any) => {
    await mutate({ service_id, line_id });
  };

  return (
    <div className="grid grid-cols-12 border-t border-black items-center">
      <div className="col-span-4 p-2 border-r border-black  text-left">
        {item?.category?.charAt(0)}-{item?.name}
      </div>
      <div className="col-span-1 p-2 border-r border-black  text-center">
        {item?.quantity || 0}
      </div>
      <div className="col-span-2 p-2 border-r border-black  text-center">
        {formatMoney(item?.quantity * item?.price || 0)}
      </div>

      <div className="col-span-1 p-2 border-r border-black  text-right">
        <span>{item?.unit}</span>
      </div>
      <div className="col-span-1 p-2 border-r border-black  text-right">
        <span>{formatMoney(item?.discount || 0)}</span>
      </div>

      <div className="col-span-2 p-2  border-r border-black text-right">
        {formatMoney(Number(item?.quantity * item?.price || 0))}
      </div>

      <div className="col-span-1 p-1  flex justify-center ">
        <button
          type="button"
          className="flex justify-center items-center border rounded-md p-1  text-center text-red-800 focus:outline-none hover:bg-red-50"
          onClick={() => handleDeleteServicePart({ line_id: item?._id })}
        >
          <DeleteOutlined style={{ fontSize: "12px", color: "#E1332E" }} />
        </button>
      </div>
    </div>
  );
}
