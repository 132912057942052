export default function StockItem({ item, name, count, index, handleSelect }: any) {
  return (
    <div className="border-b py-2 px-4 flex justify-between items-center">
      <div className="capitalize">
        {index + 1}. {name || "Others"}
      </div>
      <div className="flex">
        <span className="text-right mr-4">{count} Vehicles</span>
        <button
          onClick={() => {
            handleSelect({ item });
          }}
          className="flex justify-center items-center border bg-gray-200 px-4 py-1 text-xs ml-2 hover:bg-green-200 focus:outline-none"
        >
          View Vehicles
        </button>
      </div>
    </div>
  );
}
