import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Drawer, Form, Row, Select, Space, message } from "antd";
import { useContext, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Moment from "react-moment";
import { handleAssignInquiry } from "../../../../api/inquiries";
import { AuthContext } from "../../../../context/AuthContext";
import { handleFetchUsers } from "../../../../api/users";
const { Option } = Select;

export default function AssignConsultant({
  isVisible,
  setVisible,
  inquiry,
}: any) {
  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: handleFetchUsers,
  });

  const userOptions = users?.map((user: any, index: number) => (
    <Option value={user?._id} key={index}>
      {user?.name}
    </Option>
  ));

  const { mutate, isLoading } = useMutation({
    mutationFn: handleAssignInquiry,
    onSuccess: () => {
      message.success("Assigned Successfully");
      form.resetFields();
      queryClient.invalidateQueries({
        queryKey: ["newInquiries"],
      });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { consultant } = await form.validateFields();
    const data = { user: consultant };

    await mutate({ inquiry_id: inquiry?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ consultant: auth?._id });
  }, [form]);

  if (!inquiry) {
    return <></>;
  }

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">ASSIGN CONSULTANT</span>
          </span>
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <span className="text-2xl">{inquiry?.name}, </span>
        <span>
          Created <Moment format="DD/MM/YYYY">{inquiry?.createdAt}</Moment>
        </span>
      </div>
      <div className="mt-4">ASSIGN TO:-</div>
      <Form
        id="assignConsultant"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="consultant"
              
              rules={[
                {
                  required: true,
                  message: "Please assign consultant",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select consultant"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {userOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button danger type="primary" htmlType="submit">
            Assign
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
