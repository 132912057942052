import formatMoney from "../../../../utils/formatMoney";

export default function ModelWidget({ item, index, handleSelect }: any) {
  const getDealValue = ({ deals }: any) => {
    let total = 0;
    deals?.forEach((i: any) => {
      total = total + i?.value;
    });
    return total;
  };
  return (
    <div className="border-b py-2 px-4 flex justify-between items-center">
      <div className="">
        {index + 1}. {item?._id || "-"}
      </div>
      <div className="flex">
        <div className="w-24 text-right mr-4">{item?.total} Deals</div>
        <div className="font-bold w-32">
          $ {formatMoney(getDealValue({ deals: item?.list }))} /=
        </div>
        <button
          onClick={() => {
            handleSelect({ item });
          }}
          className="flex justify-center items-center border bg-gray-200 px-4 py-1 text-xs ml-2 hover:bg-green-200 focus:outline-none"
        >
          View Deals
        </button>
      </div>
    </div>
  );
}
