import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchAllServices = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/services?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchPendingServices = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/services/pending?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};
const handleFetchInprogressServices = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/services/progress?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};
const handleFetchRejectedServices = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/services/rejected?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};
const handleFetchCompletedServices = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/services/completed?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleFetchService = async ({ service_id }: any) => {
  const uri = `api/services/${service_id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchVehicleServices = async (chassis : any) => {
  const uri = `api/services/vehicles/${chassis}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleUpdateServiceWip = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/wip`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleUpdateServiceRemarks = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/remark`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleUpdateServiceStatus = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/status`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleApproveService = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/approve`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleRejectService = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/reject`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleStartService = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/start`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handlePauseService = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/pause`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleContinueService = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/pending`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleCompleteService = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/complete`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleAddServiceLine = async ({ service_id, data }: any) => {
  const uri = `api/services/${service_id}/lines`;
  const response = patchDataAuto({ uri, data });
  return response;
};

const handleRemoveServiceLine = async ({ service_id, line_id }: any) => {
  const uri = `api/services/${service_id}/lines/${line_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleCreateService = async ({ data }: any) => {
  const uri = `api/services`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleUpdateService = async ({ data, _id }: any) => {
  const uri = `api/services/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteService = async ({ _id }: any) => {
  const uri = `api/services/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchService,
  handleCreateService,
  handleUpdateService,
  handleDeleteService,
  handleApproveService,
  handleRejectService,
  handleStartService,
  handlePauseService,
  handleContinueService,
  handleCompleteService,
  handleFetchAllServices,
  handleUpdateServiceWip,
  handleUpdateServiceRemarks,
  handleUpdateServiceStatus,
  handleFetchPendingServices,
  handleFetchRejectedServices,
  handleFetchInprogressServices,
  handleFetchCompletedServices,
  handleAddServiceLine,
  handleRemoveServiceLine,
  handleFetchVehicleServices
};
