import Manage from "..";
import DetailedService from "../../services/DetailedService";

export default function VehicleServices() {
  return (
    <Manage
      title={"Vehicle"}
      subtitle={"Services"}
      description={"List of all vehicle services"}
      actions={<></>}
    >
      <DetailedService />
    </Manage>
  );
}
