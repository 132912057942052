const AUTO_BASE_URL = "https://api.kjl.co.tz:4080";
const ARPS_BASE_URL = "https://api.kjl.co.tz:3000";
const TRACE_BASE_URL = "https://api.kjl.co.tz:4000";
const EMAIL_BASE_URL = "https://api.kjl.co.tz:8080";
const PBX_BASE_URL = "http://192.168.100.30:8080";

const urls = {
  AUTO_BASE_URL: AUTO_BASE_URL,
  ARPS_BASE_URL: ARPS_BASE_URL,
  TRACE_BASE_URL: TRACE_BASE_URL,
  EMAIL_BASE_URL: EMAIL_BASE_URL,
  PBX_BASE_URL: PBX_BASE_URL,
};

export {
  AUTO_BASE_URL,
  ARPS_BASE_URL,
  TRACE_BASE_URL,
  EMAIL_BASE_URL,
  PBX_BASE_URL,
  urls,
};
