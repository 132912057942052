import DocumentHeader from "./DocumentHeader";
import DocumentPricing from "./DocumentPricing";
import DocumentFooter from "./DocumentFooter";
import DocumentBanks from "./DocumentBanks";
import "../../assets/styles/custom.css";

export default function StaluteProforma({ proforma }: any) {
  return (
    <div className="px-16" style={{ fontSize: 12 }}>
      <div className="pt-12">
        <DocumentHeader proforma={proforma} />
        <DocumentPricing proforma={proforma} />
        <DocumentBanks  />
        <DocumentFooter />
      </div>
    </div>
  );
}
