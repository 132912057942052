import Something from "../../../components/shared/Something";
import LeadChart from "./components/LeadChart";
import LeadGeneration from "./components/LeadGeneration";
import LeadSources from "./components/LeadSources";
import LeadModels from "./components/LeadModels";
import LeadStatus from "./components/LeadStatus";
import Wrap from "../Wrap";
import DateTool from "../../../components/toolbar/DateTool";
import { useState } from "react";
import moment from "moment";
import TopWrapper from "./components/TopWrapper";

export default function LeadReports() {
  const [dates, setDates] = useState<any>({
    startDate: moment(new Date()).format("YYYYMMDD"),
    endDate: moment(new Date()).format("YYYYMMDD"),
    isRange: false,
  });

  const handleDateChange = ({ startDate, endDate, isRange }: any) => {
    setDates({
      startDate,
      endDate,
      isRange,
    });
  };
  const isError = false;
  const isFetching = false;

  const refetch = () => {};

  return (
    <Wrap active="Leads Reports" dates={dates}>
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <div className="flex flex-col w-full">
          <DateTool stateHandler={handleDateChange} />
          <div className="p-2">
            {/* TOP WIDGET */}
            <TopWrapper dates={dates} />

            {/* MID WIDGET */}
            <div className="grid grid-cols-12 gap-2 mt-2">
              <div className="col-span-12 md:col-span-8">
                <LeadGeneration dates={dates} />
                <LeadSources dates={dates} />
                <LeadStatus dates={dates} />
                <LeadModels dates={dates} />
              </div>
              <div className="col-span-12 md:col-span-4 bg-white border pb-4">
                <LeadChart dates={dates} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrap>
  );
}
