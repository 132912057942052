export default function DealRemarks({ deal }: any) {
  return (
    <div className="text-xs">
      <div className="flex items-center bg-white p-2 border h-12">
        <div className="font-bold text-sm">REMARKS</div>
      </div>
      <div className="">
        <div className="bg-white border p-4 mb-1 flex justify-between items-center">
          <div className="">
            <div className="text-gray-800 flex justify-center items-center">
              <span className=""> {deal?.status?.remarks || "-"}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
