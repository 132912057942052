import { useState } from "react";
import DealsPanel from "./DealPanel";
import { useQuery } from "@tanstack/react-query";
import { handleFetchDealStatusReport } from "../../../../api/reports";
import ConvWidget from "./ConvWidget";
import AgeingWidget from "./AgeingWidget";

export default function TopWrapper({ dates }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const { data: deals } = useQuery({
    queryKey: ["dealAgeingReport"],
    queryFn: handleFetchDealStatusReport,
  });

  const handleSelect = ({ item }: any) => {
    setSelected(item);
    setVisible(true);
  };

  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-6">
        <ConvWidget deals={deals} />
      </div>
      {deals?.map((item: any, index: number) => (
        <div className="col-span-12 md:col-span-2" key={index}>
          <AgeingWidget item={item} key={index} handleSelect={handleSelect} />
        </div>
      ))}

      <DealsPanel
        title="DEALS BY AGEING"
        subtitle={selected?._id}
        deals={selected?.list || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
