import { Tooltip, message } from "antd";
import { useState } from "react";
import {
  AiOutlineCar,
  AiOutlineDelete,
  AiOutlineFileDone,
  AiOutlineMail,
} from "react-icons/ai";

import { BiUser } from "react-icons/bi";
import { BsTelephoneOutbound } from "react-icons/bs";
import { TfiSaveAlt } from "react-icons/tfi";
import CreateActivity from "./CreateActivity";
import ConvertDeal from "./ConvertDeal";
import CreateSMS from "./CreateSMS";
import CreateEmail from "./CreateEmail";
import ArchiveDeal from "./ArchiveDeal";
import DeleteDeal from "./DeleteDeal";
import UploadDocument from "./UploadDocument";
import WarmWidget from "./WarmWidget";

export default function ActivityHeader({ deal, deal_id, access }: any) {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [smsModal, setSMSModal] = useState<boolean>(false);
  const [emailModal, setEmailModal] = useState<boolean>(false);
  const [convertModal, setConvertModal] = useState<boolean>(false);
  const [archiveModal, setArchiveModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [category, setCategory] = useState("");
  const [action, setAction] = useState("");
  const [hasTimeCreate, setHasTimeCreate] = useState(false);
  const [hasFeedCreate, setHasFeedCreate] = useState(false);

  const handleShowModal = ({ category, action, time, feed }: any) => {
    setCategory(category);
    setAction(action);
    setCreateModal(true);
    setHasTimeCreate(time);
    setHasFeedCreate(feed);
  };

  const handleHideModal = () => {
    setCreateModal(false);
  };

  const handleShowEmailDrawer = ({ category, action, time, feed }: any) => {
    setCategory(category);
    setAction(action);
    setHasTimeCreate(time);
    setHasFeedCreate(feed);
    setEmailModal(true);
  };

  const handleShowSMSDrawer = ({ category, action, time, feed }: any) => {
    setCategory(category);
    setAction(action);
    setHasTimeCreate(time);
    setHasFeedCreate(feed);
    setSMSModal(true);
  };

  const handleShowDocModal = () => {
    setUploadModal(true);
  };

  return (
    <div className="flex justify-between items-center pb-1 border-b-4  border-gray-300 mb-1 h-10 bg-white text-sm">
      <div className="bg-white flex">
        <button
          onClick={() =>
            access
              ? handleShowModal({
                  category: "Meeting",
                  action: "meeting",
                  time: true,
                  feed: true,
                })
              : message.error("Sorry, Access Denied")
          }
          className="border px-3 py-1 mr-1 flex justify-center items-center focus:outline-none hover:bg-green-50"
        >
          <BiUser />
          <span className="ml-1">Meeting</span>
        </button>
        <button
          onClick={() =>
            access
              ? handleShowEmailDrawer({
                  category: "Email",
                  action: "email",
                  time: false,
                  feed: true,
                })
              : message.error("Sorry, Access Denied")
          }
          className="border px-3 py-1 mr-1 flex justify-center items-center focus:outline-none hover:bg-green-50"
        >
          <AiOutlineMail />
          <span className="ml-1">Email</span>
        </button>
        <button
          onClick={() =>
            access
              ? handleShowSMSDrawer({
                  category: "SMS",
                  action: "sms",
                  time: false,
                  feed: true,
                })
              : message.error("Sorry, Access Denied")
          }
          className="border px-3 py-1 mr-1 flex justify-center items-center focus:outline-none hover:bg-green-50"
        >
          <AiOutlineMail />
          <span className="ml-1">SMS</span>
        </button>
        <button
          onClick={() =>
            access
              ? handleShowModal({
                  category: "Vehicle Demonstration",
                  action: "demonstration",
                  time: true,
                  feed: true,
                })
              : message.error("Sorry, Access Denied")
          }
          className="border px-3 py-1 mr-1 flex justify-center items-center focus:outline-none hover:bg-green-50"
        >
          <AiOutlineCar />
          <span className="ml-1">Vehicle Demonstration</span>
        </button>
        <button
          onClick={() =>
            access
              ? handleShowModal({
                  category: "Call",
                  action: "call",
                  time: true,
                  feed: true,
                })
              : message.error("Sorry, Access Denied")
          }
          className="border px-3 py-1 mr-1 flex justify-center items-center focus:outline-none hover:bg-green-50"
        >
          <BsTelephoneOutbound />
          <span className="ml-1">Call</span>
        </button>
        <button
          onClick={() =>
            access
              ? handleShowModal({
                  category: "Notes",
                  action: "notes",
                  time: false,
                  feed: false,
                })
              : message.error("Sorry, Access Denied")
          }
          className="border px-3 py-1 mr-1 flex justify-center items-center focus:outline-none hover:bg-green-50"
        >
          <AiOutlineFileDone />
          <span className="ml-1">Notes</span>
        </button>
        <button
          onClick={() =>
            access
              ? handleShowDocModal()
              : message.error("Sorry, Access Denied")
          }
          className="border px-3 py-1 mr-1 flex justify-center items-center focus:outline-none hover:bg-green-50"
        >
          <AiOutlineFileDone />
          <span className="ml-1">Documents</span>
        </button>
        <CreateActivity
          isVisible={createModal}
          category={category}
          action={action}
          hasTime={hasTimeCreate}
          hasFeed={hasFeedCreate}
          deal_id={deal_id}
          hideModal={handleHideModal}
        />

        <CreateSMS
          isVisible={smsModal}
          setVisible={setSMSModal}
          deal_id={deal_id}
          action={"sms"}
          deal={deal}
        />

        <CreateEmail
          isVisible={emailModal}
          setVisible={setEmailModal}
          deal_id={deal_id}
          action={"email"}
          deal={deal}
        />

        <ArchiveDeal
          isVisible={archiveModal}
          setVisible={setArchiveModal}
          deal_id={deal_id}
          deal={deal}
        />

        <DeleteDeal
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          deal={deal}
          deal_id={deal_id}
        />

        <UploadDocument
          isVisible={uploadModal}
          setVisible={setUploadModal}
          deal_id={deal_id}
        />

        <ConvertDeal
          isVisible={convertModal}
          deal={deal}
          deal_id={deal_id}
          setVisible={setConvertModal}
        />
      </div>
      <div className="flex justify-center items-center">
        <Tooltip placement="bottom" title={"Archive this Deal"}>
          <button
            onClick={() =>
              access
                ? setArchiveModal(true)
                : message.error("Sorry, Access Denied")
            }
            className="flex items-center border rounded-sm px-2 py-1 focus:outline-none hover:bg-yellow-100 bg-yellow-50 mr-2"
          >
            <TfiSaveAlt color="#ca8a04" />
          </button>
        </Tooltip>

        <Tooltip placement="bottom" title={"Delete this Deal"}>
          <button
            onClick={() =>
              access
                ? setDeleteModal(true)
                : message.error("Sorry, Access Denied")
            }
            className="flex items-center border rounded-sm px-2 py-1 focus:outline-none hover:bg-red-100 bg-red-50  mr-2"
          >
            <AiOutlineDelete color={"#E11A2C"} />
          </button>
        </Tooltip>

        <div className="px-2 flex justify-center items-center border-l border-gray-300 pl-2">
          <WarmWidget deal={deal} deal_id={deal_id} access={access} />
        </div>
      </div>
    </div>
  );
}
