import ChartHeader from "./ChartHeader";
import UserIcon from "../../../assets/images/user.png";
import { useQuery } from "@tanstack/react-query";
import { handleFetchDealContactsReport } from "../../../api/reports";

export default function TopCustomers({ dates }: any) {
  const { data: contacts } = useQuery({
    queryKey: ["dealContactsReport"],
    queryFn: handleFetchDealContactsReport,
  });

  function getTop5ByTotal({ contacts }: any) {
    if (contacts === undefined) {
      return [];
    }
    const sortedData = contacts?.sort((a: any, b: any) => b.total - a.total);
    return sortedData.slice(0, 5);
  }

  const top5 = getTop5ByTotal({ contacts: contacts });
  return (
    <div>
      <ChartHeader
        title="Top 5 Customers"
        subtitle="The most reputable customers"
      />
      {top5?.map((contact: any, index: number) => (
        <div
          className="flex justify-start items-center px-4 h-12 border-b"
          key={index}
        >
          <div className="w-8 h-8 bg-gray-300 rounded-full flex justify-center items-center">
            <img src={UserIcon} className="h-7 w-7 rounded-full" alt="" />
          </div>
          <div className="flex-col ml-2">
            <div className="text-sm font-bold text-black">
              Name : {contact?._id || "-"}
            </div>
            <div className="flex text-xs text-gray-500">
              <span className="font-bold">Deals : </span>{" "}
              {contact?.total || "-"}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
