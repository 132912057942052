const getAgeing = ({ date }: any) => {
  const targetDate: Date = new Date(date);
  const currentDate: Date = new Date();
  const timeDifference: number = targetDate.getTime() - currentDate.getTime();
  const daysDifference: number = Math.floor(
    timeDifference / (1000 * 60 * 60 * 24)
  );

  let x = daysDifference;

  if (x < 0) {
    x = x * -1;
  }
  return x;
};

export default getAgeing;
