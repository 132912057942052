import React, { useState } from "react";
import { FiCheck, FiEdit } from "react-icons/fi";
import CreateTask from "./CreateTask";
import Moment from "react-moment";
import UpdateTask from "./UpdateTask";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { handleCompleteLeadTask } from "../../../../api/leads";

export default function LeadTasks({ lead, lead_id, access }: any) {
  const [updateTask, setUpdateTask] = useState(false);
  const [createTask, setCreateTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: complete } = useMutation({
    mutationFn: handleCompleteLeadTask,
    onSuccess: () => {
      message.success("Completed Successfully");
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleComplete = async ({ task_id }: any) => {
    await complete({ lead_id, task_id });
    setUpdateTask(false);
  };

  return (
    <div className="">
      <div className="p-2 border h-12 bg-white">
        <div className="flex justify-between items-center font-bold text-sm">
          <div className="text-sm">TASKS</div>
          {access && (
            <div className="">
              {!createTask ? (
                <button
                  className="border rounded-md px-3 py-1 focus:outline-none hover:bg-green-50"
                  onClick={() => setCreateTask(true)}
                >
                  +
                </button>
              ) : (
                <button
                  className="border rounded-md px-3 py-1 focus:outline-none hover:bg-green-50"
                  onClick={() => setCreateTask(false)}
                >
                  x
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="bg-white border p-2 mb-1 flex flex-col justify-between">
        <div className="flex">
          {createTask ? (
            <CreateTask lead_id={lead_id} setVisible={setCreateTask} />
          ) : (
            <div className="w-full">
              {updateTask ? (
                <UpdateTask
                  setUpdateTask={setUpdateTask}
                  lead_id={lead_id}
                  selectedTask={selectedTask}
                />
              ) : (
                <div className="w-full">
                  {lead?.tasks?.map((task: any, index: number) => (
                    <div
                      className="flex justify-between items-center bg-gray-100 rounded-md mb-1 p-2 w-full"
                      key={index}
                    >
                      <div className="">
                        <div className="">{task?.description}</div>
                        <div className="text-gray-600">
                          <Moment format="Do MMM, YYYY">
                            {task?.dates?.expected}
                          </Moment>
                        </div>
                      </div>
                      {access && (
                        <div className="">
                          <button
                            onClick={() => {
                              setUpdateTask(true);
                              setSelectedTask(task);
                            }}
                            className="border rounded-sm px-2 py-1 focus:outline-none"
                          >
                            <FiEdit />
                          </button>

                          <button
                            onClick={() =>
                              handleComplete({ task_id: task?._id })
                            }
                            className={
                              task?.is_completed
                                ? `border rounded-sm px-2 py-1 focus:outline-none hover:bg-green-50 bg-green-200`
                                : `border rounded-sm px-2 py-1 focus:outline-none hover:bg-green-50`
                            }
                          >
                            <FiCheck />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
