import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { useEffect, useState } from "react";
import IMain from "./IMain";
import IHeader from "./components/IHeader";
import moment from "moment";
import Moment from "react-moment";
import { Table } from "antd";
import { handleFetchAppointments } from "../../../api/appointments";

export default function INewAppointments({ inCRM }: any) {
  const [advSearch, setAdvSearch] = useState<boolean>(false);
  const [action, setAction] = useState<boolean>(false);

  const initials = {
    name: "",
    consultant: "",
    source: "",
    status: "",
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  };
  const [filters, setFilters] = useState<any>({ ...initials });

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 10,
    total_pages: 1,
    total_count: 0,
  });

  const clearAllFilters = () => {
    setFilters({ ...initials });
  };

  const params = `&name=${filters?.name}&consultant=${
    filters?.consultant
  }&source=${filters?.source}&status=${filters?.status}&startDate=${moment(
    filters?.startDate
  ).format("YYYYMMDD")}&endDate=${moment(filters?.endDate).format("YYYYMMDD")}`;
  const {
    data: appointments,
    refetch,
    isLoading,
    isFetched,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["newAppointments", { pagination, params }],
    queryFn: handleFetchAppointments,
    enabled: !!filters?.startDate
  });

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };

  const onChange = (params: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: appointments?.total_count,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.per_page -
            pagination?.per_page +
            index +
            1}
        </span>
      ),
    },
    {
      title: "Name",
      width: 180,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.name || "-"}</span>;
      },
    },

    {
      title: "Mobile",
      width: 120,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.mobile || "-"}</span>;
      },
    },
    {
      title: "Email",
      width: 120,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.email || "-"}</span>;
      },
    },




    {
      title: "Appointment Date",
      width: 100,
      render: (record: any) => {
        return <Moment format={"Do MMM,YYYY"}>{record?.date}</Moment>;
      },
    },
    {
      title: "Appointment Details",
      width: 180,
      render: (record: any) => {
        return <div>{record?.requirements}</div>;
      },
    },
    {
      title: "Created",
      width: 100,
      render: (record: any) => {
        return <Moment fromNow>{record?.createdAt}</Moment>;
      },
    },
  ];

  useEffect(() => {
    if (appointments?.total_count) {
      setPagination({
        ...pagination,
        total_count: appointments?.total_count,
      });
    }
  }, [isFetched]);

  return (
    <div className="">
      <IHeader
        filters={filters}
        setFilters={setFilters}
        setAdvSearch={setAdvSearch}
        advSearch={advSearch}
        inquiries={appointments}
        action={action}
        handleAdvancedSearch={handleAdvancedSearch}
        clearAllFilters={clearAllFilters}
      />
      <IMain active="Appointments" actions={<></>} inCRM={inCRM}>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <div className="grid gap-x-0 grid-cols-12 border bg-white">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  size="small"
                  dataSource={appointments === undefined ? [] : appointments?.data || []}
                  columns={columns}
                  scroll={{ y: 860, x: 0 }}
                  rowKey="id"
                  pagination={{
                    current: pagination?.page,
                    pageSize: pagination?.per_page,
                    total: pagination?.total_count,
                  }}
                  onChange={onChange}
                  loading={isLoading}
                  rowClassName={"hover:cursor-pointer hover:bg-red-50"}
                />
              </div>
            </div>
          </div>
        )}
      </IMain>
    </div>
  );
}
