import InvoiceItemsRead from "./InvoiceItemsRead";
import DocumentLines from "./DocumentLines";
import InvoiceTotals from "./InvoiceTotals";
import DocumentLogo from "./DocumentLogo";
import DocumentRef from "./DocumentRef";
import DocumentVehicle from "./DocumentVehicle";
import "./custom.css";

export default function JoborderWrapper({ jobRef, service, editable }: any) {
  if (service === undefined) {
    return <></>;
  }

  return (
    <div className="book" ref={jobRef} id="invoice-doc">
      <div className="page">
        <div className="subpage">
          <div className="p-1">
            <DocumentLogo />
            <DocumentRef service={service} />
            <DocumentVehicle
              vehicle={service?.vehicle}
              service_type={service?.service_type}
            />
            {!editable ? (
              <InvoiceItemsRead service={service || {}} />
            ) : (
              <DocumentLines service={service || {}} />
            )}
            <InvoiceTotals service={service} />
            <div className="absolute bottom-0 right-0 left-0 border-t border-black">
              <div className="flex flex-col justify-center items-center text-xs text-black py-1">
                <div className="" style={{ fontSize: 12 }}>
                  AUTOMARK, P.O Box 9060, Dar es Salaam, Tanzania
                </div>
                <div className="" style={{ fontSize: 10 }}>
                  TIN: 100-146-304, VRN: 10-006645-E
                </div>
                <div className="" style={{ fontSize: 10 }}>
                  Email: mparmar@toyota.co.tz, Phone Number: (+255) 784 452500
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
