import { Button, Modal, Form } from "antd";

export default function CompleteService({ service_id, isVisible, setVisible }: any) {
  const isLoading = false;

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setVisible(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Complete Service"
      onCancel={hideModal}
      footer={[
        <Button key="back" danger onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="CompleteService"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Complete
        </Button>,
      ]}
    >
      <Form id="CompleteService" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center font-bold">
            Are you sure you want to mark this service as completed
          </p>
        </div>
      </Form>
    </Modal>
  );
}
