import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchModels = async () => {
  const uri = `api/models`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateModel = async (data: any) => {
  const uri = `api/models`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleFetchModel = async ({ _id }: any) => {
  const uri = `api/models/${_id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleUpdateModel = async ({ data, _id }: any) => {
  const uri = `api/models/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteModel = async ({ _id }: any) => {
  const uri = `api/models/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchModels,
  handleCreateModel,
  handleFetchModel,
  handleUpdateModel,
  handleDeleteModel,
};
