import { useEffect, useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Moment from "react-moment";
import { BsChevronDown } from "react-icons/bs";
import { Popover } from "antd";
import { useQuery } from "@tanstack/react-query";
import { handleFetchYearBudget } from "../../../api/budget";
import salesStats from "../../../utils/salesStats";
import formatShort from "../../../utils/formatShort";
import { handleFetchSalesByYearReport } from "../../../api/reports";

export default function Mainchart({ dates, year }: any) {
  const [report, setReport] = useState<any>({
    id: 1,
    name: "Value",
    budget: "budgetValue",
    actual: "actualValue",
  });
  const [opened, setOpened] = useState<boolean>(false);

  const DataFormater = (number: any) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };


  const { data: budget } = useQuery({
    queryKey: ["budgets", year],
    queryFn: () => handleFetchYearBudget({ year }),
  });


  const { data: actual } = useQuery({
    queryKey: ["actuals", year],
    queryFn: () => handleFetchSalesByYearReport({ year }),
  });

  const reports = [
    {
      id: 1,
      name: "Value",
      budget: "budgetValue",
      actual: "actualValue",
    },
    {
      id: 2,
      name: "Count",
      budget: "budgetCount",
      actual: "actualCount",
    },
  ];


  // let data: any = salesStats({ budget, actual });
  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;

    return (
      <text
        x={x + 17}
        y={y}
        dy={-4}
        fill={stroke}
        fontSize={10}
        textAnchor={"middle"}
      >
        {formatShort(value)}
      </text>
    );
  };

  const data : any = salesStats({ budget, actual })

  return (
    <div className="col-span-12 sm:col-span-12 md:col-span-12">
      <div className="flex flex-col justify-between">
        <div className="flex flex-row justify-between items-center  border-b border-gray-200 px-6 mb-4">
          <div className="py-2">
            <h4 className="text-gray-800">
              <span className="text-gray-900 font-bold text-lg">
                Sales Statistics :
              </span>
              <span className="ml-1">{year}</span>
            </h4>
            <p className="text-gray-600 text-sm">
              Sales Budget vs Actual Sales
            </p>
          </div>

          <div className="flex items-center">
            <div className="flex items-center text-green-600  border-green-600">
              {dates?.isRange ? (
                <div className="font-bold">
                  <Moment format={"Do MMM"}>{dates?.startDate}</Moment> -{" "}
                  <Moment format={"Do MMM"}>{dates?.endDate}</Moment>
                </div>
              ) : (
                <div className="font-bold">
                  <Moment format={"Do MMM"}>{dates?.startDate}</Moment>
                </div>
              )}
              <span className="mx-2 font-bold">


              : {year}
              </span>
            </div>
            <div className="ml-2">
              <Popover
                className="cursor:pointer focus:outline-none hover:text-toyota"
                trigger="click"

                placement="bottom"
                content={
                  <div className="flex flex-col w-40">
                    {reports?.map((report: any) => (
                      <button
                        className="cursor-pointer hover:text-toyota text-left focus:outline-none py-1"
                        onClick={() => {
                          setReport(report);
                          setOpened((o) => !o);
                        }}
                        key={report.id}
                      >
                        {report?.name}
                      </button>
                    ))}
                  </div>
                }
              >
                <div
                  onClick={() => setOpened((o) => !o)}
                  className="cursor-pointer"
                >
                  <div className="flex flex-row justify-center items-center hover:text-blue-800 text-gray-600 border p-1">
                    <div className="">{report?.name}</div>
                    <div className="ml-2">
                      <BsChevronDown color={"#4145A8"} />
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            data={data}
            margin={{
              top: 0,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Month" />
            <XAxis
              dataKey="Month"
              axisLine={false}
              tickLine={false}
              interval={0}
              height={1}
              scale="band"
              xAxisId="quarter"
              
            />
            <YAxis tickFormatter={DataFormater} />
            <Tooltip />
            <Legend verticalAlign="top" />
            <Bar
              dataKey={`${report?.budget}`}
              fill="#8F8F8F"
              label={CustomizedLabel}
              name="Sales Budget"
            />
            <Bar
              dataKey={`${report?.actual}`}
              fill="#16a34a"
              label={CustomizedLabel}
              name="Actual Sales"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
