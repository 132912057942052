import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useContext, useState } from "react";
import { GoCheck } from "react-icons/go";
import {
  handleApproveService,
  handleRejectService,
} from "../../../api/services";
import { AuthContext } from "../../../context/AuthContext";

export default function ApproveService({
  service_id,
  isVisible,
  setVisible,
}: any) {
  const { auth } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [selected, setSelected] = useState<any>({
    id: 1,
    name: "Approve",
    action: "approve",
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: selected?.id === 1 ? handleApproveService : handleRejectService,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["services", service_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const options = [
    { id: 1, name: "Approve", action: "approve" },
    { id: 2, name: "Reject", action: "reject" },
  ];

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    await mutate({ service_id, data: { user: auth?._id, ...values } });
    setVisible(false);
  };
  return (
    <Modal
      open={isVisible}
      title={`${selected?.name} Jobcard`}
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="ApproveService"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          {selected?.name}
        </Button>,
      ]}
    >
      <Form
        id="ApproveService"
        form={form}
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex justify-between">
          {options?.map((item: any, index: number) => (
            <div
              className={`border w-full mx-1 flex items-center p-2 hover:cursor-pointer ${
                selected?.id === item?.id ? "bg-blue-100 border-blue-200" : ""
              }`}
              onClick={() => setSelected(item)}
              key={index}
            >
              <div className="border border-gray-500 w-6 h-6 flex justify-center items-center">
                {selected?.id === item?.id ? <GoCheck size={18} /> : null}
              </div>
              <div className="ml-2">{item?.name}</div>
            </div>
          ))}
        </div>
        {selected?.id === 2 && (
          <div className="p-1">
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="remark"
                  label="Remarks"
                  rules={[
                    {
                      required: true,
                      message: "Please enter remarks",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Remarks" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </Modal>
  );
}
