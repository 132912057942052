import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Row,
  Select,
} from "antd";
import { GrClose } from "react-icons/gr";
import { handleFetchUsers } from "../../../../api/users";
import { useState } from "react";
import dayjs from "dayjs";
import { handleFetchCrmSources } from "../../../../api/crmSource";

const { Option } = Select;

export default function AdvancedSearch({
  setVisible,
  isLoading,
  mainFilters,
  clearAllFilters,
  handleAdvancedSearch,
}: any) {
  const [filters, setFilters] = useState<any>({});
  const [form] = Form.useForm();

  const clearFilters = () => {
    form.resetFields();
    setFilters({});
    clearAllFilters();
  };


  const { data: sources} = useQuery({
    queryKey: ["crmSources"],
    queryFn: handleFetchCrmSources,
  });

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: handleFetchUsers,
  });

  const onStartDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setFilters({ ...filters, startDate: dateString });
  };

  const onEndDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setFilters({ ...filters, endDate: dateString });
  };
  const sourceOptions = sources?.map((item: any, index: number) => {
    return (
      <Option value={item.name} key={index}>
        {item.name}
      </Option>
    );
  });


  const consultantOptions = users?.map((item: any, index: number) => {
    if (item?.isSeller) {
      return (
        <Option value={item?._id} key={index}>
          {item.name}
        </Option>
      );
    }
  });

  return (
    <div className="h-auto  px-4 border-b bg-white z-0">
      <div className="flex justify-between items-center py-4 border-b">
        <div className="text-lg">Advanced Search</div>
        <div className="">
          <button
            onClick={() => setVisible(false)}
            className="focus:outline-none hover:bg-red-100  p-2"
          >
            <GrClose />
          </button>
        </div>
      </div>
      <div className="h-auto mt-4 flex-1">
        <Form
          id="leadAdvSearch"
          name="leadAdvSearch"
          form={form}
          onFinish={() => handleAdvancedSearch(filters)}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="consultant"
                label="Consultant"
                rules={[
                  {
                    required: false,
                    message: "Please select consultant",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Consultant"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, consultant: e });
                  }}
                  defaultValue={""}
                >
                  <Option  value="">
                    All
                  </Option>
                  {consultantOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="source"
                label="Source"
                rules={[
                  {
                    required: false,
                    message: "Please select source",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Source"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, source: e });
                  }}
                  defaultValue={""}
                >
                  <Option value="">
                    All
                  </Option>
                  {sourceOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: false,
                    message: "Please select status",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Status"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, status: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  <Option value={true}>
                    Contacted
                  </Option>
                  <Option value={true}>
                    Not Contacted
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="startDate"
                label="Start Date"
                rules={[
                  {
                    required: false,
                    message: "Please select start date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Start date"
                  className="w-full"
                  format={"DD/MM/YYYY"}
                  defaultValue={dayjs(mainFilters.startDate)}
                  onChange={onStartDateChange}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="endDate"
                label="End Date"
                rules={[
                  {
                    required: false,
                    message: "Please select end date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="End Date"
                  className="w-full"
                  format={"DD/MM/YYYY"}
                  defaultValue={dayjs(mainFilters.endDate)}
                  onChange={onEndDateChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button danger type="primary" htmlType="submit" loading={isLoading}>
              Search
            </Button>

            <Button
              type="default"
              danger
              className="ml-4"
              onClick={() => {
                form.resetFields();
                clearFilters();
              }}
            >
              Clear
            </Button>
            <Button
              type="default"
              danger
              className="ml-4"
              onClick={() => setVisible(false)}
            >
              Close
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
