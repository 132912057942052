import { useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateQuestion } from "../../../../api/checkpoints";

export default function CreateQuestion({ checkpoint_id, status, wip }: any) {
  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: handleCreateQuestion,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["checkpoints"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    await mutate({ checkpoint_id, data });
    setEdit(false);
  };

  return (
    <div className="bg-white text-xs">
      <div className="p-2">
        <Form
          id="UpdateServiceWip"
          form={form}
          name="normal_login"
          className="login-form"
          onFinish={onSubmit}
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="question"
                initialValue={wip}
                rules={[
                  {
                    required: true,
                    message: "Please enter question",
                  },
                ]}
              >
                <Input type="text" placeholder="New Question" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <button
                onClick={() => onSubmit()}
                className="flex justify-center items-center border rounded-md p-2 ml-1 focus:outline-none hover:bg-green-50 text-green-800"
              >
                <SaveOutlined />
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
