import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import Moment from "react-moment";
import UpdateParticulars from "./UpdateParticulars";
import UpdateAddress from "./UpdateAddress";

export default function ParticularDetails({ contact, editMode }: any) {
  const [particularModal, setParticularModal] = useState<boolean>(false);
  const [addressModal, setAddressModal] = useState<boolean>(false);
  return (
    <div className="">
      <div className="py-4">
        <div className="flex justify-between pb-2 border-b-2">
          <div className="font-bold">Personal Details</div>
          {editMode && (
            <button onClick={() => setParticularModal(true)} className="">
              <FiEdit />
            </button>
          )}
        </div>
        <div className="">
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">National Id: </div>
            <div className="">{contact?.particular?.nationalID || "-"}</div>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Passport No: </div>
            <div className="">{contact?.particular?.passport || "-"}</div>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Company :</div>
            <div className="">{contact?.particular?.company || "-"}</div>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Designation: </div>
            <div className="">{contact?.particular?.designation || "-"}</div>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Gender </div>
            <div className="">{contact?.particular?.gender || "-"}</div>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Date of Birth: </div>
            <div className="">
              {contact?.particular?.birth ? (
                <Moment format="Do MMM, YYYY">
                  {contact?.particular?.birth}
                </Moment>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Status </div>
            <div className="">
              {contact?.isExisting ? "Existing Customer" : "New Customer"}
            </div>
          </div>
        </div>

        <div className="">
          <div className="flex justify-between mt-4 pb-2 border-b-2">
            <div className="font-bold">Address Info</div>
            {editMode && (
              <button onClick={() => setAddressModal(true)} className="">
                <FiEdit />
              </button>
            )}
          </div>
          <div className="">
            <div className="flex item-center text-gray-600 py-1">
              <div className="w-40">Physical: </div>
              <div className="">{contact?.addressInfo?.physical || "-"}</div>
            </div>
            <div className="flex item-center text-gray-600 py-1">
              <div className="w-40">Postal: </div>
              <div className="">{contact?.addressInfo?.postal || "-"}</div>
            </div>
            <div className="flex item-center text-gray-600 py-1">
              <div className="w-40">City :</div>
              <div className="">{contact?.addressInfo?.city || "-"}</div>
            </div>
            <div className="flex item-center text-gray-600 py-1">
              <div className="w-40">Coordinates : </div>
              <div className="">
                {contact?.addressInfo?.latitude || "-"} ,{" "}
                {contact?.addressInfo?.longitude || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateParticulars
        isVisible={particularModal}
        setVisible={setParticularModal}
        contact={contact}
      />
      <UpdateAddress
        isVisible={addressModal}
        setVisible={setAddressModal}
        contact={contact}
      />
    </div>
  );
}
