import formatShort from "../../../../utils/formatShort";

export default function AgeingWidget({ item, handleSelect }: any) {
  const getTotal = () => {
    let total = 0;
    item?.list?.forEach((item: any) => {
      total = total + Number(item?.value);
    });
    return total;
  };

  return (
    <button
      onClick={() => {
        handleSelect({ item });
      }}
      className="border border-gray-300 px-4 py-2 bg-blue-100 h-20 w-full"
    >
      <div className="text-lg font-bold flex justify-end capitalize">
        {item?._id}
      </div>
      <div className="flex justify-between">
        <div className="text-3xl">
          {item?.total}
          <span className="text-xs ml-1">Deals</span>
        </div>
        <div className="text-2xl"> $ {formatShort(getTotal())} /=</div>
      </div>
    </button>
  );
}
