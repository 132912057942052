import Moment from "react-moment";

export default function DocumentRef({ service }: any) {
  return (
    <>
      <div className="bg-white border-b border-t border-r border-l border-black mb-2 flex flex-row justify-between items-center text-xs">
        <div className=" flex flex-col w-full sm:w-7/12 text-xs">
          <div className="px-2 flex">
            <span className="font-bold w-28">Customer Name: </span>
            <span className="px-2">Automark Tanzania</span>
          </div>
          <div className="px-2 flex">
            <span className="font-bold w-28">Dealer Name : </span>
            <span className="px-2">{service?.location?.name}</span>
          </div>
          <div className="px-2 flex">
            <span className="font-bold w-28">Operator : </span>
            <span className="px-2">{service?.creator?.name || "-"}</span>
          </div>
        </div>

        <div className={`border-l border-black  w-5/12`}>
          <div className="flex justify-between items-center px-2 border-b border-black ">
            <div className="border-r border-black w-24">J.O No </div>
            <div className="w-full text-left px-2">{service?.serial}</div>
          </div>
          <div className="flex justify-between items-center px-2 border-b border-black">
            <div className="border-r border-black w-24">DATE </div>
            <div className="w-full text-left px-2">
              <Moment format="Do MMM, YYYY">{service?.createdAt}</Moment>
            </div>
          </div>
          <div className="flex justify-between items-center px-2">
            <div className="border-r border-black w-24">W.I.P No </div>
            <div className="w-full text-left px-2">{service?.wip}</div>
          </div>
        </div>
      </div>
    </>
  );
}
