import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateVehicleOtherSpecs } from "../../api/vehicles";
import { useEffect } from "react";

export default function OtherSpecsUpdate({
  isVisible,
  setVisible,
  chassis,
  vehicle,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateVehicleOtherSpecs,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const {speakers, turning_radius, ground_clearance, ...rest } =
      await form.getFieldsValue();
    const data = {
      turning_radius: Number(turning_radius),
      ground_clearance: Number(ground_clearance),
      speakers: Number(speakers),
      ...rest,
    };
    await mutate({ data, chassis });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({
      turning_radius: vehicle?.specifications?.turning_radius,
    });
    form.setFieldsValue({
      ground_clearance: vehicle?.specifications?.ground_clearance,
    });
    form.setFieldsValue({ drive_train: vehicle?.specifications?.drive_train });
    form.setFieldsValue({
      interior_trim: vehicle?.specifications?.interior_trim,
    });
    form.setFieldsValue({ speakers: vehicle?.specifications?.speakers });
    form.setFieldsValue({ tyre_size: vehicle?.specifications?.tyre_size });
  }, [isVisible]);

  return (
    <Modal
      open={isVisible}
      title="Update Other Specs"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateVehicleOtherSpecs"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleOtherSpecs"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="turning_radius"
              label="Turning Radius"
              initialValue={vehicle?.specifications?.turning_radius}
              rules={[
                {
                  required: false,
                  message: "Please enter turning radius",
                },
              ]}
            >
              <Input type="number" placeholder="Turning Radius" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="ground_clearance"
              label="Ground Clearance"
              initialValue={vehicle?.specifications?.ground_clearance}
              rules={[
                {
                  required: false,
                  message: "Please enter ground clearance",
                },
              ]}
            >
              <Input type="number" placeholder="Ground Clearance" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="drive_train"
              label="Drive Train"
              initialValue={vehicle?.specifications?.drive_train}
              rules={[
                {
                  required: false,
                  message: "Please enter drive train",
                },
              ]}
            >
              <Input type="text" placeholder="Drive Train" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="interior_trim"
              label="Interior Trim"
              initialValue={vehicle?.specifications?.drive_train}
              rules={[
                {
                  required: false,
                  message: "Please enter interior trim",
                },
              ]}
            >
              <Input type="text" placeholder="Interior Trim" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="tyre_size"
              label="Tyre Size"
              initialValue={vehicle?.specifications?.speakers}
              rules={[
                {
                  required: false,
                  message: "Please enter tyre size",
                },
              ]}
            >
              <Input type="text" placeholder="Tyre Size" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="speakers"
              label="No of Speakers"
              initialValue={vehicle?.specifications?.speakers}
              rules={[
                {
                  required: false,
                  message: "Please enter no of speakers",
                },
              ]}
            >
              <Input type="text" placeholder="No of Speakers" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
