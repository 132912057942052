import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleUpdateVehicleDetails } from "../../api/vehicles";
import { handleFetchModels } from "../../api/models";
import { useEffect } from "react";

const { Option } = Select;

export default function DetailsUpdate({
  isVisible,
  setVisible,
  chassis,
  vehicle,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateVehicleDetails,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
      clearForm();
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: models } = useQuery({
    queryKey: ["models"],
    queryFn: handleFetchModels,
  });

  const onSubmit = async () => {
    const { mileage, vsb, auctionGrade, ...rest } = await form.getFieldsValue();
    const data = {
      mileage: Number(mileage),
      vsb: Number(vsb),
      auctionGrade: auctionGrade,
      ...rest,
    };
    await mutate({ data, chassis });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  const year = new Date().getFullYear() + 1;

  const years: any = [];

  for (let i = 1998; i < year; i++) {
    years.unshift({ year: i });
  }

  const modelOptions = models?.map((model: any, index: number) => (
    <Option value={model?._id} key={index}>
      {model?.make + " - " + model?.name}
    </Option>
  ));

  const yearOptions = years?.map((year: any, index: number) => (
    <Option value={year?.year} key={index}>
      {year?.year}
    </Option>
  ));

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Modal
      open={isVisible}
      title="Details Update"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateVehicleDetails"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleDetails"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="chassis"
              label="Chassis Number"
              initialValue={vehicle?.chassis}
              rules={[
                {
                  required: false,
                  message: "Please enter chassis number",
                },
              ]}
            >
              <Input type="" placeholder="Chassis Number" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="registration"
              label="Registration Number"
              initialValue={vehicle?.registration}
              rules={[
                {
                  required: false,
                  message: "Please enter Registration number",
                },
              ]}
            >
              <Input type="" placeholder="Registration Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="vsb"
              label="VSB"
              initialValue={vehicle?.vsb}
              rules={[
                {
                  required: false,
                  message: "Please enter VSB",
                },
              ]}
            >
              <Input type="" placeholder="VSB" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mileage"
              label="Mileage"
              initialValue={vehicle?.mileage}
              rules={[
                {
                  required: false,
                  message: "Please enter mileage",
                },
              ]}
            >
              <Input type="" placeholder="Mileage" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="model"
              label="Model"
              initialValue={vehicle?.model?.entity}
              rules={[
                {
                  required: false,
                  message: "Please select model",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select model"
                optionFilterProp="children"
                defaultValue={vehicle?.model?.entity}
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {modelOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="year"
              label="Year"
              initialValue={vehicle?.year}
              rules={[
                {
                  required: false,
                  message: "Please select year",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select year"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {yearOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="color_exterior"
              label="Exterior Color"
              initialValue={vehicle?.colors?.exterior}
              rules={[
                {
                  required: false,
                  message: "Please enter exterior color",
                },
              ]}
            >
              <Input type="text" placeholder="Exterior Color" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="color_interior"
              label="Interior Color"
              initialValue={vehicle?.colors?.interior}
              rules={[
                {
                  required: false,
                  message: "Please enter interior color",
                },
              ]}
            >
              <Input type="text" placeholder="Interior Color" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="auctionGrade"
              label="Auction Grade"
              initialValue={vehicle?.auctionGrade}
              rules={[
                {
                  required: false,
                  message: "Please enter Auction Grade",
                },
              ]}
            >
              <Input type="text" placeholder="Auction Grade" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
