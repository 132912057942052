import React from "react";

export default function DocumentFooter() {
  return (
    <div className="flex w-full justify-between border-t-2 border-black mt-1">
      {/* DAR ES SALAAM */}
      <div className="w-1/4">
        <div className="font-bold">Dar es Salaam</div>
        <div className="">No. 5 Nyerere Road</div>
        <div className="">P.O Box 9060, Dar es Salaam</div>
        <div className="">T: (255) 22 2866815-9</div>
        <div className="">F: (255) 22-2866814</div>
      </div>
      {/* ARUSHA */}
      <div className="w-1/4">
        <div className="font-bold">Arusha</div>
        <div className="">Goliondoi Rd</div>
        <div className="">P.O Box 194, Arusha</div>
        <div className="">T: (255) 27 2544092</div>
        <div className="">F: (255) 27 2548426</div>
      </div>
      {/* TANGA */}
      <div className="w-1/4">
        <div className="font-bold">Tanga</div>
        <div className="">Bombo Hospital Rd</div>
        <div className="">P.O Box 109, Tanga</div>
        <div className="">T: (255) 27 2644668</div>
        <div className="">F: (255) 27 2644669</div>
      </div>
      {/* EMAIL */}
      <div className="w-1/4">
        <div className="font-bold">Email</div>
        <div className="">sales@automark.co.tz</div>
        <div className=""></div>
        <div className=""></div>
        <div className=""></div>
      </div>
    </div>
  );
}
