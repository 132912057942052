import { RiTableLine } from "react-icons/ri";

export default function SourceWidget({ item, handleSelect }: any) {
  return (
    <div
      onClick={() => {
        handleSelect({ item });
      }}
      className="col-span-2 bg-white py-1 border rounded-l-sm flex items-center justify-start px-4 cursor-pointer"
    >
      <div className="w-12 h-12 rounded-full bg-gray-200 flex justify-center items-center">
        <RiTableLine size={25} color={"#E50000"} />
      </div>
      <div className="flex-col ml-4">
        <div className="font-bold text-lg text-gray-600">
          {item?._id}
        </div>
        <div className="text-black text-lg font-bold">{item?.total || "-"} <span className="text-sm">Vehicles</span></div>
      </div>
    </div>
  );
}
