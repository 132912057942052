import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import UpdateSocials from "./UpdateSocials";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineReddit,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";

export default function SocialDetails({ contact, editMode }: any) {
  const [socialModal, setSocialModal] = useState<boolean>(false);
  return (
    <div className="">
      <div className="py-4">
        <div className="flex justify-between pb-2 border-b-2">
          <div className="font-bold">Social Details</div>
          {editMode && (

            <button onClick={() => setSocialModal(true)} className="">
            <FiEdit />
          </button>
            )}
        </div>
        <div className="">
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40 flex items-center">
              <AiOutlineFacebook />
              <span className="ml-1">Facebook : </span>
            </div>
            <a
              href={`${contact?.social?.facebook}`}
              target="_blank"
              className="text-blue-900"
            >
              {contact?.social?.facebook || "-"}
            </a>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40 flex items-center">
              <AiOutlineInstagram />
              <span className="ml-1">Instagram : </span>
            </div>
            <a
              href={`${contact?.social?.instagram}`}
              target="_blank"
              className="text-blue-900"
            >
              {contact?.social?.instagram || "-"}
            </a>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40 flex items-center">
              <AiOutlineWhatsApp />
              <span className="ml-1">WhatsApp : </span>
            </div>
            <a
              href={`${contact?.social?.whatsapp}`}
              target="_blank"
              className="text-blue-900"
            >
              {contact?.social?.whatsapp || "-"}
            </a>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40 flex items-center">
              <AiOutlineLinkedin />
              <span className="ml-1">LinkedIn : </span>
            </div>
            <a
              href={`${contact?.social?.linkedin}`}
              target="_blank"
              className="text-blue-900"
            >
              {contact?.social?.linkedin || "-"}
            </a>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40 flex items-center">
              <AiOutlineTwitter />
              <span className="ml-1">Twitter / X : </span>
            </div>
            <a
              href={`${contact?.social?.twitter}`}
              target="_blank"
              className="text-blue-900"
            >
              {contact?.social?.twitter || "-"}
            </a>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40 flex items-center">
              <FaTiktok size={12} />
              <span className="ml-1">Tiktok : </span>
            </div>
            <a
              href={`${contact?.social?.tiktok}`}
              target="_blank"
              className="text-blue-900"
            >
              {contact?.social?.tiktok || "-"}
            </a>
          </div>{" "}
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40 flex items-center">
              <AiOutlineReddit />
              <span className="ml-1">Reddit : </span>
            </div>
            <a
              href={`${contact?.social?.reddit}`}
              target="_blank"
              className="text-blue-900"
            >
              {contact?.social?.reddit || "-"}
            </a>
          </div>
        </div>
      </div>

      <UpdateSocials
        isVisible={socialModal}
        setVisible={setSocialModal}
        contact={contact}
      />
    </div>
  );
}
