import { Button, Modal, Form, Row, Col, message, DatePicker } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateVehicleShipment } from "../../api/vehicles";

export default function UpdateShipment({
  isVisible,
  setVisible,
  chassis,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateVehicleShipment,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { etd, eta, ata } = await form.getFieldsValue();
    const data = {
      etd: etd,
      eta: eta,
      ata: ata,
    };
    await mutate({ data, chassis });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Update Shipment Details"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateVehicleShipment"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleShipment"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="etd"
              label="Estimated Departure Date ( ETD)"
              rules={[
                {
                  required: false,
                  message: "Please enter departure date",
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="Departure Date"
                className="w-full"
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="eta"
              label="Estimated Arrival Date ( ETA )"
              rules={[
                {
                  required: false,
                  message: "Please enter departure date",
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="Departure Date"
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="border-t-4 mb-4"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="ata"
              label="Actual Arrival Date ( ATA )"
              rules={[
                {
                  required: false,
                  message: "Please enter arrival date",
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="Actual Arrival Date"
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
