import { Popover, Button, DatePicker } from "antd";
import { useContext, useState } from "react";
import moment, { Moment } from "moment";
import { AuthContext } from "../../../../context/AuthContext";
import { fetchDataAuto } from "../../../../services/auto";
import { ExportToExcel } from "../../../../utils/exportExcel";
import { RiFileExcel2Line } from "react-icons/ri";

const { RangePicker } = DatePicker;

const ExportContent = (props: any) => {
  const {  token, expire } = useContext(AuthContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { hide } = props;
  const dateFormat = "DD/MM/YYYY";
  const timeFormat = "HH:mm";
  const today: Moment = moment().add(0, "day");

  const [filterDate, setFilterDate] = useState<any>([today, today]);

  const handleFetchBookings = async () => {
    setLoading(true);
    const uri = `api/proforma/export/data?startDate=${moment(filterDate[0]).format(
      "YYYYMMDD"
    )}&endDate=${moment(filterDate[1]).format("YYYYMMDD")}&all=0`;
    
    const data = await fetchDataAuto({ uri, token, expire });

    ExportToExcel(
      data?.map((booking: any) => ({
        "Booking Number": booking?.bookingno,
        Customer: booking?.customer_name,
        Mobile: booking?.customer_mobile,
        Email: booking?.customer_email,
        Address: booking?.customer_address,
        Date: moment(booking?.date).format(dateFormat),
        Time: `${moment(booking?.slottime?.starttime).format(
          timeFormat
        )} - ${moment(booking?.slottime?.endtime).format(timeFormat)} `,
        Service: booking?.description,
        Model: booking?.model,
        "Registration Number": booking?.vehicle,
        Status: booking?.status,
        Advisor: `${booking?.assignedTo?.firstname} ${booking?.assignedTo?.lastname}`,
        Mileage: booking?.mileage,
        Wip: booking?.wip,
        "Created Date": moment(booking?.createdAt).format(
          `${dateFormat} ${timeFormat}`
        ),
        "Customer Remarks": booking?.customer_comments,
      })),
      `Proforma from ${moment(filterDate[0]).format(dateFormat)} to ${moment(
        filterDate[1]
      ).format(dateFormat)}.xlsx`
    );
    hide();
    setLoading(false);
  };

  return (
    <div>
      <RangePicker
        format={dateFormat}
        onChange={(date: any) => {
          setFilterDate(date);
        }}
      />

      <div className="flex flex-row justify-between mt-2">
        <Button
          type="primary"
          onClick={() => {
            handleFetchBookings();
          }}
          danger
          className="bg-automark-pale hover:bg-automark-dark"
        >
          {isLoading ? "Exporting ... " : "Export"}
        </Button>

        <Button onClick={hide}>Cancel</Button>
      </div>
    </div>
  );
};

const ExportProforma = (props: any) => {
  const [isVisible, toggleVisibility] = useState(false);

  const hide = () => {
    toggleVisibility(false);
  };

  const show = () => {
    toggleVisibility(true);
  };

  return (
    <Popover
      content={<ExportContent {...props} hide={hide} />}
      title="Select Date To Export"
      trigger="hover"
      open={isVisible}
    >
      <button
        className="border rounded-md px-2 py-1 flex items-center mx-2"
        onClick={show}
      >
        <RiFileExcel2Line size={20} />
        <span className="mx-1">Export </span>
      </button>
    </Popover>
  );
};

export default ExportProforma;
