import { Badge } from "antd";
import { capitalize } from "lodash";
import { AiOutlineEye } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { status } from "../../../utils/contants";
import formatMoney from "../../../utils/formatMoney";
import thumbnail from "../../../assets/images/photo.png";

export default function VehicleWidget({
  item,
  auth,
  setProformaModal,
  setDetailsModal,
  setSelected,
}: any) {
  const createAcccess = [2, 4];
  return (
    <div className="col-span-12 md:col-span-4 sm:col-span-6 lg:col-span-2 border bg-white">
      <Badge.Ribbon text={capitalize(item.status)} color={status[item?.status]}>
        <button
          onClick={() => {
            setSelected(item);
            setDetailsModal(true);
          }}
        >
          <img
            src={item?.thumbnail || thumbnail}
            className="w-full aspect-[4/3] object-contain"
            style={{ width: "100%" }}
            alt=""
          />
        </button>
        <div className="border-t">
          <div className="p-2">
            <div className="font-bold mb-1">{item?.chassis}</div>
            <div className="uppercase text-gray-600 mb-2">
              {item?.model?.name}
            </div>
            <div className="flex justify-between">
              <div className="">{formatMoney(item?.prices?.inclusive)} /=</div>
              <div className="">{item?.source?.name}</div>
            </div>
          </div>
          <div className="border-t p-2">
            <div className="flex justify-center">
              <div className="w-1/2 mx-1">
                {item?.status === "available" ? (
                  <div className="">
                    {createAcccess?.includes(auth?.accessLevel) ? (
                      <button
                        onClick={() => {
                          setSelected(item);
                          setProformaModal(true);
                        }}
                        className="border px-2 py-1 w-full mx-1 flex items-center justify-center bg-blue-50"
                      >
                        <FiPlus />
                        <span className="ml-1">Proforma</span>
                      </button>
                    ) : (
                      <button
                        disabled
                        className="border px-2 py-1 w-full mx-1 flex items-center justify-center bg-gray-50 cursor-not-allowed"
                      >
                        <FiPlus color={"#d1d5db"} />
                        <span className="ml-1 text-gray-300">Proforma</span>
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    disabled
                    className="border px-2 py-1 w-full mx-1 flex items-center justify-center bg-gray-50 cursor-not-allowed"
                  >
                    <FiPlus color={"#d1d5db"} />
                    <span className="ml-1 text-gray-300">Proforma</span>
                  </button>
                )}
              </div>
              <div className="w-1/2 mx-1">
                <button
                  onClick={() => {
                    setSelected(item);
                    setDetailsModal(true);
                  }}
                  className="border px-2 py-1  w-full mx-1 flex items-center justify-center bg-green-50"
                >
                  <AiOutlineEye />
                  <span className="ml-1">Details</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Badge.Ribbon>
    </div>
  );
}
