import Wrap from "../Wrap";
import INewAppointments from "./INewAppointments";

export default function NewAppointments() {
  return (
    <Wrap active="Inquiries" child={<></>} actions={<></>}>
      <INewAppointments inCRM={true} />
    </Wrap>
  );
}
