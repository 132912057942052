import { useState } from "react";
import LeadActivity from "./LeadActivity";
import DoneActivity from "./DoneActivity";
import UpdateActivity from "./UpdateActivity";
import DeleteActivity from "./DeleteActivity";
import UploadDocument from "./UploadDocument";

export default function LeadActivities({ lead }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [doneModal, setDoneModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  const handleSelect = (activity: any) => {
    setSelected(activity);
  };
  return (
    <div className="mt-2">
      {lead?.activities?.map((activity: any, index: number) => (
        <LeadActivity
          activity={activity || {}}
          key={index}
          isLoading={isLoading}
          handleSelect={handleSelect}
          isUpdateVisible={updateModal}
          setUpdateVisible={setUpdateModal}
          setDeleteVisible={setDeleteModal}
          setDoneVisible={setDoneModal}
        />
      ))}

      <DoneActivity
        isVisible={doneModal}
        setVisible={setDoneModal}
        lead_id={lead?._id}
        selected={selected?.selected || {}}
      />

      <UpdateActivity
        isVisible={updateModal}
        setVisible={setUpdateModal}
        lead_id={lead?._id}
        activity={selected?.selected || {}}
      />

      <DeleteActivity
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        lead_id={lead?._id}
        activity={selected?.selected || {}}
      />
    </div>
  );
}
