import ChartHeader from "./ChartHeader";
import CarModel from "../../../assets/images/model.png";
import { useQuery } from "@tanstack/react-query";
import { handleFetchProformaModelReport } from "../../../api/reports";

export default function TopModels({ dates }: any) {
  const { data: models } = useQuery({
    queryKey: ["proformaModelReport"],
    queryFn: handleFetchProformaModelReport,
  });

  function getTop5ByTotal({ models }: any) {
    if (models === undefined) {
      return [];
    }
    const sortedData = models?.sort((a: any, b: any) => b.total - a.total);
    return sortedData.slice(0, 5);
  }

  const top5 = getTop5ByTotal({ models: models });
  return (
    <div className="">
      <ChartHeader title="Top 5 Models" subtitle="The most inquired models" />
      {top5?.map((model: any) => (
        <div
          className="flex flex-row px-4 justify-between items-center h-12 border-b"
          key={model.id}
        >
          <div className="flex justify-center items-center">
            <div className="border-r pr-4">
              <img src={CarModel} className="h-10 w-10" alt="" />
            </div>
            <div className="flex flex-col px-2">
              <div className="text-sm font-bold text-black">
                Model : {model._id || "-"}
              </div>
              <div className="flex text-xs text-gray-500">
                <span className="font-bold">Proformas : </span>{" "}
                {model.total || "-"}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
