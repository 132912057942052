import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import getAgeing from "../../../../utils/getAgeing";

export default function AgeingWidget({ vehicle }: any) {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className="border-b border-t px-4 py-2 flex justify-between items-center bg-gray-100">
      <div className="">Vehicle Ageing : </div>
      <div className="">
        {show ? (
          <div className="flex items-center justify-center">
            <div className="w-16 bg-gray-200 h-6  flex items-center justify-center mr-1 text-red-600">
              {vehicle?.shipment?.actual_arrival
                ? getAgeing({ date: vehicle?.shipment?.actual_arrival })
                : "-"}
              <span className="ml-1">Days</span>
            </div>
            <button
              onClick={() => setShow(!show)}
              className="border p-1 rounded-md ml-1"
            >
              <FiEyeOff />
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <div className="w-16 bg-gray-200 h-6 flex items-center justify-center mr-1">
              <div className=""></div>
            </div>
            <button
              onClick={() => setShow(!show)}
              className="border p-1 rounded-md"
            >
              <FiEye />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
