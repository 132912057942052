import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Row,
  Select,
} from "antd";
import { GrClose } from "react-icons/gr";
import { handleFetchModels } from "../../../../api/models";
import { handleFetchSources } from "../../../../api/sources";
import { handleFetchUsers } from "../../../../api/users";
import { useState } from "react";
import { handleFetchContacts } from "../../../../api/contacts";
import formatMoney from "../../../../utils/formatMoney";

const { Option } = Select;


const statuses = [
  "new",
  "won",
  "lost",
  "rotten",
  "pending",
  "waiting"
]

export default function AdvancedSearch({
  setVisible,
  isLoading,
  clearAllFilters,
  handleAdvancedSearch,
}: any) {
  const [filters, setFilters] = useState<any>({});
  const [form] = Form.useForm();

  const clearFilters = () => {
    form.resetFields();
    setFilters({});
    clearAllFilters();
  };

  const { data: models } = useQuery({
    queryKey: ["models"],
    queryFn: handleFetchModels,
  });

  const { data: sources } = useQuery({
    queryKey: ["sources"],
    queryFn: handleFetchSources,
  });

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: handleFetchUsers,
  });

  const { data: contacts } = useQuery({
    queryKey: ["contacts"],
    queryFn: handleFetchContacts,
  });

  const modelOptions = models?.map((item: any, index: number) => {
    return (
      <Option value={item?._id} key={index}>
        {item.name}
      </Option>
    );
  });

  const onStartDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setFilters({ ...filters, startDate: dateString });
  };

  const onEndDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setFilters({ ...filters, endDate: dateString });
  };
  const sourceOptions = sources?.map((item: any, index: number) => {
    return (
      <Option value={item.name} key={index}>
        {item.name}
      </Option>
    );
  });

  const statusOptions = statuses?.map((index: any) => {
    return (
      <Option value={statuses[index]} key={index} style={{ textTransform: 'capitalize'}}>
        {statuses[index]}
      </Option>
    );
  });

  const customerOptions = contacts?.map((item: any, index: number) => {
    return (
      <Option value={item?._id} key={index}>
        {item.name}
      </Option>
    );
  });

  const consultantOptions = users?.map((item: any, index: number) => {
    if (item?.isSeller) {
      return (
        <Option value={item?._id} key={index}>
          {item.name}
        </Option>
      );
    }
  });

  return (
    <div className="h-auto  px-4 border-b bg-white z-0">
      <div className="flex justify-between items-center py-4 border-b">
        <div className="text-lg">Advanced Search</div>
        <div className="">
          <button
            onClick={() => setVisible(false)}
            className="focus:outline-none hover:bg-red-100  p-2"
          >
            <GrClose />
          </button>
        </div>
      </div>
      <div className="h-auto mt-4 flex-1">
        <Form
          id="leadAdvSearch"
          name="leadAdvSearch"
          form={form}
          onFinish={() => handleAdvancedSearch(filters)}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="customer"
                label="Customer"
                rules={[
                  {
                    required: false,
                    message: "Please select customer",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Customer"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, customer: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {customerOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="consultant"
                label="Consultant"
                rules={[
                  {
                    required: false,
                    message: "Please select consultant",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Consultant"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, consultant: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {consultantOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <Form.Item
                name="model"
                label="Model"
                rules={[
                  {
                    required: false,
                    message: "Please select model",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Model"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, model: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {modelOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <Form.Item
                name="source"
                label="Source"
                rules={[
                  {
                    required: false,
                    message: "Please select source",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Source"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, source: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {sourceOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: false,
                    message: "Please select status",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Status"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, status: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {statusOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="startDate"
                label="Start Date"
                rules={[
                  {
                    required: false,
                    message: "Please select start date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Start date"
                  className="w-full"
                  format={"DD/MM/YYYY"}
                  onChange={onStartDateChange}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="endDate"
                label="End Date"
                rules={[
                  {
                    required: false,
                    message: "Please select end date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="End Date"
                  className="w-full"
                  format={"DD/MM/YYYY"}
                  onChange={onEndDateChange}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="minValue"
                label="Min Value"
                initialValue=""
                rules={[
                  {
                    required: false,
                    message: "Please enter minimum value",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Min Value"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, minValue: e });
                  }}
                >
                  <Option selected value="">
                    Min Value:
                  </Option>
                  <Option value="5000">{`${formatMoney(5000)} USD`}</Option>
                  <Option value="10000">{`${formatMoney(10000)} USD`}</Option>
                  <Option value="15000">{`${formatMoney(15000)} USD`}</Option>
                  <Option value="20000">{`${formatMoney(20000)} USD`}</Option>
                  <Option value="25000">{`${formatMoney(25000)} USD`}</Option>
                  <Option value="30000">{`${formatMoney(30000)} USD`}</Option>
                  <Option value="35000">{`${formatMoney(35000)} USD`}</Option>
                  <Option value="40000">{`${formatMoney(40000)} USD`}</Option>
                  <Option value="45000">{`${formatMoney(45000)} USD`}</Option>
                  <Option value="50000">{`${formatMoney(50000)} USD`}</Option>
                  <Option value="55000">{`${formatMoney(55000)} USD`}</Option>
                  <Option value="60000">{`${formatMoney(60000)} USD`}</Option>
                  <Option value="65000">{`${formatMoney(65000)} USD`}</Option>
                  <Option value="70000">{`${formatMoney(70000)} USD`}</Option>
                  <Option value="75000">{`${formatMoney(75000)} USD`}</Option>
                  <Option value="80000">{`${formatMoney(80000)} USD`}</Option>
                  <Option value="85000">{`${formatMoney(85000)} USD`}</Option>
                  <Option value="90000">{`${formatMoney(90000)} USD`}</Option>
                  <Option value="95000">{`${formatMoney(95000)} USD`}</Option>
                  <Option value="100000">{`${formatMoney(100000)} USD`}</Option>
                  <Option value="105000">{`${formatMoney(105000)} USD`}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="maxPrice"
                label="Max Value"
                initialValue=""
                rules={[
                  {
                    required: false,
                    message: "Please enter maximum value",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Max Value"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, maxValue: e });
                  }}
                >
                  <Option selected value="">
                    Max Value:
                  </Option>
                  <Option value="5000">{`${formatMoney(5000)} USD`}</Option>
                  <Option value="10000">{`${formatMoney(10000)} USD`}</Option>
                  <Option value="15000">{`${formatMoney(15000)} USD`}</Option>
                  <Option value="20000">{`${formatMoney(20000)} USD`}</Option>
                  <Option value="25000">{`${formatMoney(25000)} USD`}</Option>
                  <Option value="30000">{`${formatMoney(30000)} USD`}</Option>
                  <Option value="35000">{`${formatMoney(35000)} USD`}</Option>
                  <Option value="40000">{`${formatMoney(40000)} USD`}</Option>
                  <Option value="45000">{`${formatMoney(45000)} USD`}</Option>
                  <Option value="50000">{`${formatMoney(50000)} USD`}</Option>
                  <Option value="55000">{`${formatMoney(55000)} USD`}</Option>
                  <Option value="60000">{`${formatMoney(60000)} USD`}</Option>
                  <Option value="65000">{`${formatMoney(65000)} USD`}</Option>
                  <Option value="70000">{`${formatMoney(70000)} USD`}</Option>
                  <Option value="75000">{`${formatMoney(75000)} USD`}</Option>
                  <Option value="80000">{`${formatMoney(80000)} USD`}</Option>
                  <Option value="85000">{`${formatMoney(85000)} USD`}</Option>
                  <Option value="90000">{`${formatMoney(90000)} USD`}</Option>
                  <Option value="95000">{`${formatMoney(95000)} USD`}</Option>
                  <Option value="100000">{`${formatMoney(100000)} USD`}</Option>
                  <Option value="105000">{`${formatMoney(105000)} USD`}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button danger type="primary" htmlType="submit" loading={isLoading}>
              Search
            </Button>

            <Button
              type="default"
              danger
              className="ml-4"
              onClick={() => {
                form.resetFields();
                clearFilters();
              }}
            >
              Clear
            </Button>
            <Button
              type="default"
              danger
              className="ml-4"
              onClick={() => setVisible(false)}
            >
              Close
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
