import { Form, Modal, message } from "antd";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { handleUpdateDeal } from "../../../../api/deals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { capitalize } from "lodash";
export default function StatusModal({
  deal,
  deal_id,
  isVisible,
  setVisible,
}: any) {
  const [selected, setSelected] = useState(deal?.status);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateDeal,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["deals", deal_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async (status: any) => {
    const data = {
      status: status.toLowerCase(),
    };
    await mutate({ data, _id: deal_id });
    setVisible(false);
  };

  const status = [
    { id: 1, name: "new" },
    // { id: 2, name: "unresponsive" },
    // { id: 3, name: "pending" },
    // { id: 4, name: "approved" },
    // { id: 5, name: "awaiting payment" },
    { id: 6, name: "won" },
    { id: 7, name: "lost" },
  ];

  return (
    <Modal
      open={isVisible}
      title={"Update Deal Status"}
      onCancel={() => setVisible(false)}
      footer={[]}
    >
      {status?.map((item: any) => (
        <button
          onClick={() => {
            setSelected(item?.name);
            onSubmit(item?.name);
          }}
          className={`p-2 bg-gray-100 mb-2 flex items-center w-full hover:bg-gray-50 focus:outline-none ${
            item?.name === deal?.status?.state ? "bg-green-100" : ""
          }`}
        >
          {item?.name === deal?.status?.state ? (
            <div className="border border-green-600 w-6 h-6 flex justify-center items-center bg-green-100">
              <CheckOutlined />
            </div>
          ) : (
            <div className="border w-6 h-6 flex justify-center items-center"></div>
          )}
          <div className="ml-2">{capitalize(item?.name)}</div>
        </button>
      ))}
    </Modal>
  );
}
