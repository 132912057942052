export default function AgedWidget({ item, handleSelect }: any) {
  return (
    <div className="col-span-2 bg-white h-auto rounded-sm p-4 cursor-pointer border">
      <div className="">AGED STOCK</div>
      <div className="flex justify-between">
        <div className="font-bold">{item?._id || "-"}</div>
        <div className="font-bold">{item?.total}</div>
      </div>
      <div className="mt-2">
        {item?.list?.map((i: any, index: number) => (
          <div
            key={index}
            className="flex justify-between border-t py-1 text-gray-600 hover:bg-gray-50"
            onClick={() => handleSelect({ item: i })}
          >
            <div className="">{i?._id?.source}</div>
            <div className="">{i?.total}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
