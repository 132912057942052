export default function DocumentFooter() {
  return (
    <div className="flex flex-col border-t-2 border-black mt-20">
      <div className="italic">
        For any correspondence on this Invoice order kindly contact SFL Accounts
        Department
      </div>
      <div className="">Email: dlugongo@sfl.co.tz</div>
      <div className="">Phone: (+255) 222 86615-9 (EXT - 143)</div>
    </div>
  );
}
