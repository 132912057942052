import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import UpdateAddress from "./UpdateAddress";
import UpdateBusiness from "./UpdateBusiness";

export default function BusinessDetails({ contact, editMode }: any) {
  const [businessModal, setBusinessModal] = useState<boolean>(false);
  const [addressModal, setAddressModal] = useState<boolean>(false);
  return (
    <div className="">
      <div className="py-4">
        <div className="flex justify-between pb-2 border-b-2">
          <div className="font-bold">Company Details</div>
          {editMode && (
            <button onClick={() => setBusinessModal(true)} className="">
              <FiEdit />
            </button>
          )}
        </div>
        <div className="">
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Industry: </div>
            <div className="">{contact?.business?.industry?.name || "-"}</div>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Fax </div>
            <div className="">{contact?.business?.fax || "-"}</div>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Website: </div>
            <a
              href={`${contact?.business?.website}`}
              target="_blank"
              className="text-blue-900"
            >
              {contact?.business?.website || "-"}
            </a>
          </div>
          <div className="flex item-center text-gray-600 py-1">
            <div className="w-40">Status </div>
            <div className="">
              {contact?.isExisting ? "Existing Customer" : "New Customer"}
            </div>
          </div>
          <div className="ml-4">
            <div className="">Business Owner</div>
            <div className="ml-4">
              <div className="flex items-center text-gray-600 py-1">
                <div className="w-40">Name :</div>
                <div className="">{contact?.business?.owner?.name || "-"}</div>
              </div>
              <div className="flex items-center text-gray-600 py-1">
                <div className="w-40">Mobile :</div>
                <div className="">
                  {contact?.business?.owner?.mobile || "-"}
                </div>
              </div>
              <div className="flex items-center text-gray-600 py-1">
                <div className="w-40">Email :</div>
                <div className="">{contact?.business?.owner?.email || "-"}</div>
              </div>
              <div className="flex items-center text-gray-600 py-1">
                <div className="w-40">Address :</div>
                <div className="">
                  {contact?.business?.owner?.address || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="ml-4">
            <div className="">Contact Person</div>
            <div className="ml-4">
              <div className="flex items-center text-gray-600 py-1">
                <div className="w-40">Name :</div>
                <div className="">
                  {contact?.business?.contact?.name || "-"}
                </div>
              </div>
              <div className="flex items-center text-gray-600 py-1">
                <div className="w-40">Mobile :</div>
                <div className="">
                  {contact?.business?.contact?.mobile || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="flex justify-between mt-4 pb-2 border-b-2">
            <div className="font-bold">Address Info</div>
            {editMode && (
              <button onClick={() => setAddressModal(true)} className="">
                <FiEdit />
              </button>
            )}
          </div>
          <div className="">
            <div className="flex item-center text-gray-600 py-1">
              <div className="w-40">Physical: </div>
              <div className="">{contact?.addressInfo?.physical || "-"}</div>
            </div>
            <div className="flex item-center text-gray-600 py-1">
              <div className="w-40">Postal: </div>
              <div className="">{contact?.addressInfo?.postal || "-"}</div>
            </div>
            <div className="flex item-center text-gray-600 py-1">
              <div className="w-40">City :</div>
              <div className="">{contact?.addressInfo?.city || "-"}</div>
            </div>
            <div className="flex item-center text-gray-600 py-1">
              <div className="w-40">Coordinates : </div>
              <div className="">
                {contact?.addressInfo?.latitude || "-"} ,{" "}
                {contact?.addressInfo?.longitude || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <UpdateBusiness
        isVisible={businessModal}
        setVisible={setBusinessModal}
        contact={contact}
      />
      <UpdateAddress
        isVisible={addressModal}
        setVisible={setAddressModal}
        contact={contact}
      />
    </div>
  );
}
