import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useState } from "react";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { IoTrashBinOutline } from "react-icons/io5";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import Manage from "..";
import CreateLocation from "./components/CreateLocation";
import UpdateLocation from "./components/UpdateLocation";
import { postDataMail } from "../../../services/mail";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { handleFetchLocations } from "../../../api/locations";
import DeleteLocation from "./components/DeleteLocation";

export default function AllLocations() {
  const [selected, setSelected] = useState<any>({});
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const {
    data: locations,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["locations"],
    queryFn: handleFetchLocations,
  });

  const [emailForm] = Form.useForm();

  const handleRefetch = () => {
    refetch();
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.name ? record?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Action",
      width: 100,
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setUpdateModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <FiEdit />
          </button>

          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDeleteModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <IoTrashBinOutline />
          </button>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = locations?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  return (
    <Manage
      title={"Locations"}
      subtitle={"All"}
      description={"List of all locations"}
      actions={
        <div className="flex">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  locations?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Name: item?.name === null ? "-" : item?.name,
                    };
                  }),
                  "Locations.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
        </div>
      }
    >
      <>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="grid gap-x-0 grid-cols-12">
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <Table
                size="small"
                dataSource={locations === undefined ? [] : locations}
                columns={columns}
                scroll={{ y: 660, x: 0 }}
                rowKey="id"
                loading={isLoading}
              />

              <CreateLocation
                isVisible={createModal}
                setVisible={setCreateModal}
                refetch={handleRefetch}
              />

              <UpdateLocation
                isVisible={updateModal}
                setVisible={setUpdateModal}
                selected={selected}
              />

              <DeleteLocation
                isVisible={deleteModal}
                setVisible={setDeleteModal}
                selected={selected}
              />
            </div>
          </div>
        )}
      </>
    </Manage>
  );
}
