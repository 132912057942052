import { Button, Drawer, Form, Space, Input } from "antd";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiFileExcel2Line, RiFilterOffLine } from "react-icons/ri";
import formatMoney from "../../../../utils/formatMoney";
import { ExportToExcel } from "../../../../utils/exportExcel";
import moment from "moment";
import { exportAllStockData } from "../../../../utils/stockDataExport";
const { Search } = Input;
export default function StockPanel({
  setVisible,
  isVisible,
  vehicles,
  title,
  subtitle,
}: any) {
  const [search, setSearch] = useState("");

  const [form] = Form.useForm();

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };

  const exportData = () => {
    ExportToExcel(
      vehicles
        ?.filter((vehicle: any) => vehicle?.chassis?.includes(search))
        ?.map((item: any, index: number) => {
          return {
            "S/N": index + 1,
            Model: item?.model?.name === null ? "-" : item?.model?.name,
            Chassis: item?.chassis === null ? "-" : item?.chassis,
            Vsb: item?.vsb === null ? "-" : item?.vsb,
            Year: item?.year === null ? "-" : item?.year,
            Mileage: item?.mileage === null ? "-" : item?.mileage,
            Source: item?.source?.name === null ? "-" : item?.source?.name,
            Price:
              item?.prices?.exclusive === null ? "-" : item?.prices?.exclusive,

            Status: item?.status === null ? "-" : item?.status,
            Location:
              item?.location?.name === null ? "-" : item?.location?.name,
            "Fuel Type":
              item?.specifications?.fuel?.fuel_type === null
                ? "-"
                : item?.specifications?.fuel?.fuel_type,
            "Body Type":
              item?.specifications?.body?.body_type === null
                ? "-"
                : item?.specifications?.body?.body_type,
            Engine:
              item?.specifications?.engine?.engine_type === null
                ? "-"
                : item?.specifications?.engine?.engine_type,
            Transmission:
              item?.specifications?.transmission === null
                ? "-"
                : item?.specifications?.transmission,
            Steering:
              item?.specifications?.steering === null
                ? "-"
                : item?.specifications?.steering,
            "Drive Type":
              item?.specifications?.drive?.drive_type === null
                ? "-"
                : item?.specifications?.drive?.drive_type,
            EDT: item?.shipment?.estimated_departure
              ? moment(item?.shipment?.estimated_departure).format("DD/MM/YYYY")
              : "-",
            ETA: item?.shipment?.estimated_arrival
              ? moment(item?.shipment?.estimated_arrival).format("DD/MM/YYYY")
              : "-",
            ATA: item?.shipment?.actual_arrival
              ? moment(item?.shipment?.actual_arrival).format("DD/MM/YYYY")
              : "-",
          };
        }),
      "Vehicles.xlsx"
    );
  };

  const getTotal = () => {
    let total = 0;
    vehicles
      ?.filter((cla: any) => cla?.chassis?.includes(search))
      .forEach((vehicle: any) => {
        total = total + vehicle?.prices?.inclusive;
      });
    return isNaN(total) ? 0 : total;
  };

  return (
    <Drawer
      title={
        <span className="uppercase">
          {title} : {subtitle}
        </span>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <div className="flex justify-between items-center border-b border-gray-300 mb-2 pb-2 -mt-4">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="registration">
                <Search
                  placeholder="Search by chassis"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <div className="flex ">
            <button
              color="#E50000"
              onClick={() => exportAllStockData({ data: vehicles, search })}
              className="flex justify-center items-center border p-1 hover:bg-green-100 focus:outline-none"
            >
              <RiFileExcel2Line size={20} />
              <span className="ml-2">Export To Excel</span>
            </button>
          </div>
        </div>
        <div className="flex py-1 border-b border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-40 truncate font-bold">Vehicle Model</div>
              <div className="w-44 truncate text-left font-bold">
                Vehicle Chassis
              </div>
              <div className="w-20 truncate font-bold">Year</div>
              <div className="w-36 truncate font-bold">Mileage</div>
            </div>
            <div className="font-bold">
              <div className="mr-2 text-left flex-1">Value ( USD )</div>
            </div>
          </div>
        </div>
        {vehicles
          ?.filter((vehicle: any) => vehicle?.chassis?.includes(search))
          .map((item: any, index: number) => (
            <div className="flex py-1 border-b border-gray-300" key={index}>
              <div className="mr-2 w-10 text-center">{index + 1}.</div>
              <div className="flex justify-between items-center flex-1">
                <div className="flex items-center">
                  <div className="w-40">
                    {item?.model === null ? "-" : item?.model?.name}
                  </div>
                  <div className="w-44 truncate">
                    {item?.chassis ? item?.chassis : "-"}
                  </div>
                  <div className="w-20 truncate flex">{item?.year}</div>
                  <div className="w-36 truncate flex">{item?.mileage} KM</div>
                </div>
                <div className="">
                  <div className="mr-2 text-left flex-1">
                    $ {formatMoney(item?.prices?.inclusive)} /=
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="flex justify-between items-start border-t-2 border-b-2 py-2">
          <div className="ml-2">Grand Total</div>
          <div className="font-bold px-1">$ {formatMoney(getTotal())} /=</div>
        </div>
      </div>
    </Drawer>
  );
}
