import { Button, Modal, Form, message, Row, Col, Select } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleUpdateVehicleAvailability } from "../../api/vehicles";
import { handleFetchAvailabilities } from "../../api/availability";
import { useEffect } from "react";

const { Option } = Select;

export default function UpdateAvailability({
  isVisible,
  setVisible,
  chassis,
  availability,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateVehicleAvailability,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: availabilities } = useQuery({
    queryKey: ["availabilities"],
    queryFn: handleFetchAvailabilities,
  });

  const onSubmit = async () => {
    const { availability } = await form.getFieldsValue();
    const data = {
      availability,
    };
    await mutate({ data, chassis });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  const availabilityOptions = availabilities?.map(
    (availability: any, index: number) => (
      <Option value={availability?._id} key={index}>
        {availability?.title}, {availability?.description}
      </Option>
    )
  );

  useEffect(() => {
    form.setFieldsValue({ availability: availability?.entity });
  }, [availability]);

  return (
    <Modal
      open={isVisible}
      title="Vehicle Availability"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateVehicleAvailability"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleAvailability"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="availability"
              label="Availability"
              rules={[
                {
                  required: true,
                  message: "Please select availability",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select availability"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {availabilityOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
