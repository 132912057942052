const colors: any = {
  PENDING: "bg-status-yellow font-bold p-2 rounded-md text-center text-white",
  APPROVED: "bg-status-blue font-bold p-2 rounded-md text-center text-white",
  "INVOICED AND CLOSED":
    "bg-status-green font-bold p-2 rounded-md text-center text-white",
  "NOT APPROVED":
    "bg-status-red text-white font-bold p-2 rounded-md text-center",
};

const roles: any = {
  SA: "Service Advisor",
  LM: "Line Manager",
  HBO: "Head Branch Operations",
  AP: "Authorized Personel",
  FOM: "Front Office Manager",
  AD: "Super Admin",
};

const week: any = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednessday",
  4: "Thursday",
  5: "Friday",
};

const week_dummy: any = [
  {
    name: "Monday",
    count: 0,
    data: [],
  },
  {
    name: "Tuesday",
    count: 0,
    data: [],
  },
  {
    name: "Wednessday",
    count: 0,
    data: [],
  },
  {
    name: "Thursday",
    count: 0,
    data: [],
  },
  {
    name: "Friday",
    count: 0,
    data: [],
  },
];

const status: any = {
  available: "#2CB663",
  reserved: "#FFDB45",
  returned: "#5766ED",
  sold: "#F04D6A",
};

const serviceStatus: any = {
  new: "#4b5563",
  pending: "#FAC04D",
  approved: "#2CB663",
  paused: "#E11A2C",
  rejected: "#E11A2C",
  progress: "#FAC04D",
  completed: "#2CB663",
};

const year_dummy = [
  { name: "Jan", month: "January", count: 0, data: [] },
  { name: "Feb", month: "February", count: 0, data: [] },
  { name: "Mar", month: "March", count: 0, data: [] },
  { name: "Apr", month: "April", count: 0, data: [] },
  { name: "May", month: "May", count: 0, data: [] },
  {
    name: "Jun",
    month: "June",
    count: 0,
    data: [],
  },
  {
    name: "Jul",
    month: "July",
    count: 0,
    data: [],
  },
  { name: "Aug", month: "August", count: 0, data: [] },
  { name: "Sep", month: "September", count: 0, data: [] },
  { name: "Oct", month: "October", count: 0, data: [] },
  { name: "Nov", month: "November", count: 0, data: [] },
  { name: "Dec", month: "December", count: 0, data: [] },
];

export { colors, roles, week, week_dummy, year_dummy, status, serviceStatus };
