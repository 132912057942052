import { fetchDataAuto } from "../services/auto";

/*
|--------------------------------------------------------------------------
| Deal Reports
|--------------------------------------------------------------------------
 */

const handleFetchDealAgeingReport = async () => {
  const uri = `api/reports/deals/ageing`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDealConvertionReport = async () => {
  const uri = `api/reports/deals/convertion`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDealValuesReport = async () => {
  const uri = `api/reports/deals/values`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDealGenerationReport = async () => {
  const uri = `api/reports/deals/generation`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDealSourcesReport = async () => {
  const uri = `api/reports/deals/sources`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDealStatusReport = async () => {
  const uri = `api/reports/deal/status`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDealModelsReport = async () => {
  const uri = `api/reports/deal/model`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDealContactsReport = async () => {
  const uri = `api/reports/deal/contacts`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchDealConsultReport = async () => {
  const uri = `api/reports/deal/creator`;
  const response = fetchDataAuto({ uri });
  return response;
};

/*
|--------------------------------------------------------------------------
| Lead Reports
|--------------------------------------------------------------------------
 */

const handleFetchLeadAgeingReport = async () => {
  const uri = `api/reports/leads/ageing`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchLeadConvertionReport = async () => {
  const uri = `api/reports/leads/convertion`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchLeadValuesReport = async () => {
  const uri = `api/reports/leads/values`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchLeadGenerationReport = async () => {
  const uri = `api/reports/leads/generation`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchLeadSourcesReport = async () => {
  const uri = `api/reports/leads/sources`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchLeadStatusReport = async () => {
  const uri = `api/reports/lead/status`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchLeadModelsReport = async () => {
  const uri = `api/reports/lead/model`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchLeadConsultReport = async () => {
  const uri = `api/reports/lead/creator`;
  const response = fetchDataAuto({ uri });
  return response;
};

/*
|--------------------------------------------------------------------------
| Stock Reports
|--------------------------------------------------------------------------
 */

const handleFetchStockModelsReport = async () => {
  const uri = `api/reports/stock/model`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchStockSourceReport = async () => {
  const uri = `api/reports/stock/source`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchStockStatusReport = async () => {
  const uri = `api/reports/stock/status`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchStockYearReport = async () => {
  const uri = `api/reports/stock/year`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchStockColorReport = async () => {
  const uri = `api/reports/stock/color`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchStockFuelReport = async () => {
  const uri = `api/reports/stock/fuel`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchStockAgeingReport = async () => {
  const uri = `api/reports/stock/age`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchStockAgeSourceReport = async () => {
  const uri = `api/reports/stock/agesource`;
  const response = fetchDataAuto({ uri });
  return response;
};



/*
|--------------------------------------------------------------------------
| Sales Reports
|--------------------------------------------------------------------------
 */
const handleFetchSalesByYearReport = async ({year}: any) => {
  const uri = `api/reports/sales/years/${year}`;
  const response = fetchDataAuto({ uri });
  return response;
};



/*
|--------------------------------------------------------------------------
| Proforma Reports
|--------------------------------------------------------------------------
 */

const handleFetchProformaModelReport = async () => {
  const uri = `api/reports/proforma/model`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchProformaContactReport = async () => {
  const uri = `api/reports/proforma/contact`;
  const response = fetchDataAuto({ uri });
  return response;
};



/*
|--------------------------------------------------------------------------
| Aftersales Reports
|--------------------------------------------------------------------------
 */
const handleFetchAfterSourceReport = async () => {
  const uri = `api/reports/service/source`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchAfterStatusReport = async () => {
  const uri = `api/reports/service/status`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchAfterModelReport = async () => {
  const uri = `api/reports/service/model`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleFetchAfterLocationReport = async () => {
  const uri = `api/reports/service/location`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchAfterTechnicianReport = async () => {
  const uri = `api/reports/service/technician`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleFetchAfterApprovalReport = async () => {
  const uri = `api/reports/service/approval`;
  const response = fetchDataAuto({ uri });
  return response;
};

export {
  /*
|--------------------------------------------------------------------------
| Deal Reports
|--------------------------------------------------------------------------
 */

  handleFetchDealGenerationReport,
  handleFetchDealAgeingReport,
  handleFetchDealSourcesReport,
  handleFetchDealStatusReport,
  handleFetchDealValuesReport,
  handleFetchDealConvertionReport,
  handleFetchDealModelsReport,
  handleFetchDealContactsReport,
  handleFetchDealConsultReport,
  /*
|--------------------------------------------------------------------------
| Lead Reports
|--------------------------------------------------------------------------
 */
  handleFetchLeadGenerationReport,
  handleFetchLeadAgeingReport,
  handleFetchLeadSourcesReport,
  handleFetchLeadStatusReport,
  handleFetchLeadValuesReport,
  handleFetchLeadConvertionReport,
  handleFetchLeadModelsReport,
  handleFetchLeadConsultReport,

  /*
|--------------------------------------------------------------------------
| Stock Reports
|--------------------------------------------------------------------------
 */
  handleFetchStockModelsReport,
  handleFetchStockSourceReport,
  handleFetchStockStatusReport,
  handleFetchStockYearReport,
  handleFetchStockColorReport,
  handleFetchStockFuelReport,
  handleFetchStockAgeingReport,
  handleFetchStockAgeSourceReport,

  /*
|--------------------------------------------------------------------------
| Proforma Reports
|--------------------------------------------------------------------------
 */
  handleFetchProformaModelReport,
  handleFetchProformaContactReport,

  /*
|--------------------------------------------------------------------------
| Sales Reports
|--------------------------------------------------------------------------
 */
  handleFetchSalesByYearReport,

  /*
|--------------------------------------------------------------------------
| Aftersales Reports
|--------------------------------------------------------------------------
 */
  handleFetchAfterSourceReport,
  handleFetchAfterStatusReport,
  handleFetchAfterModelReport,
  handleFetchAfterLocationReport,
  handleFetchAfterTechnicianReport,
  handleFetchAfterApprovalReport,
};
