import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, Select, message } from "antd";
import { handleAddServiceLine } from "../../../api/services";
import { useEffect } from "react";
const { Option } = Select;

export default function JobcardModal({
  isVisible,
  setVisible,
  service_id,
}: any) {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleAddServiceLine,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["services", service_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = { ...values };
    await mutate({ service_id, data });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };
  const units = [
    { id: 1, name: "HR" },
    { id: 2, name: "EA" },
  ];

  const categories = [
    {
      id: 1,
      name: "Labour",
    },
    {
      id: 2,
      name: "Grease",
    },
    {
      id: 3,
      name: "Consumable",
    },
    {
      id: 4,
      name: "Parts",
    },
  ];

  const unitOptions = units.map((unit: any, index: number) => (
    <Option value={unit?.name} key={index}>
      {unit?.name}
    </Option>
  ));

  const categoryOptions = categories.map((unit: any, index: number) => (
    <Option value={unit?.name} key={index}>
      {unit?.name}
    </Option>
  ));

  useEffect(()=> {
    form.setFieldsValue({
      discount: 0
    })

  },[])

  return (
    <Modal
      open={isVisible}
      title="New Item"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="AddServiceLine"
          htmlType="submit"
          danger
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="AddServiceLine"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Item Name"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <Input placeholder="Part / Labour" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[
                {
                  required: true,
                  message: "Please enter qunatity",
                },
              ]}
            >
              <Input placeholder="Qty" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="price"
              label="Price"
              rules={[
                {
                  required: true,
                  message: "Please enter price",
                },
              ]}
            >
              <Input placeholder="Price" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="unit"
              label="Unit of Measure"
              rules={[
                {
                  required: true,
                  message: "Please select unit",
                },
              ]}
            >
              <Select placeholder="Unit">{unitOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="discount"
              label="Discount (%)"
              rules={[
                {
                  required: true,
                  message: "Please enter discout",
                },
              ]}
            >
              <Input
                type="number"
                max={20}
                placeholder="Discount"
                defaultValue={0}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="category"
              label="Item Category"
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}
            >
              <Select placeholder="Category">{categoryOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
