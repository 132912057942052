import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";
import { fetchDataTrace } from "../services/trace";

const handleFetchUsers = async () => {
  const uri = `api/users`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateUser = async (data: any) => {
  const uri = `api/users`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleFetchAuthUsers = async () => {
  const uri = `users`;
  const response = fetchDataTrace({ uri });
  return response;
};

const handleUpdateUser = async ({ _id, data }: any) => {
  const uri = `api/users/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleDeleteUser = async (data: any) => {
  const uri = `api/users`;
  const response = deleteDataAuto({ data, uri });
  return response;
};


const handleUpdateSeller = async ({ _id, data }: any) => {
  const uri = `api/users/${_id}/seller`;
  const response = patchDataAuto({ data, uri });
  return response;
};

export {
  handleFetchUsers,
  handleCreateUser,
  handleFetchAuthUsers,
  handleUpdateUser,
  handleDeleteUser,
  handleUpdateSeller
};
