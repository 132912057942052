import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Drawer, Form, Input, Row, Space, message } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleCreateContactHobby } from "../../../../api/contacts";
import { useEffect } from "react";

const { TextArea } = Input;

export default function CreateHobby({ isVisible, setVisible, contact }: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateContactHobby,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ data, _id: contact?._id });
  };

  const clearForm = () => {
    form.resetFields();
  };

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">CREATE : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">Hobby</div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="createContactHobby"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="hobby"
                label="Hobby"
                rules={[
                  {
                    required: true,
                    message: "Please enter hobby",
                  },
                ]}
              >
                <Input placeholder="Hobby" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
