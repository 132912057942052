import { Tooltip } from "antd";
import React, { useState } from "react";
import {
  HiOutlineClipboardDocumentCheck,
  HiOutlineClipboardDocumentList,
} from "react-icons/hi2";
import Check160 from "./Check160";
import SpecSheet from "./SpecSheet";

export default function SpecCheck({ vehicle, chassis }: any) {
  const [specModal, setSpecModal] = useState<boolean>(false);
  const [checkModal, setCheckModal] = useState<boolean>(false);
  return (
    <div className="flex justify-between items-center">
      <div className="flex p-2">
        <Tooltip title="Spec Sheet">
          <button
            onClick={() => setSpecModal(true)}
            className="border w-12 h-8 flex justify-center items-center bg-blue-500 mx-2 rounded-md"
          >
            <HiOutlineClipboardDocumentList size={21} color="#FFF" />
          </button>
        </Tooltip>
        <Tooltip title="160 Check">
          <button
            onClick={() => setCheckModal(true)}
            className="w-12 h-8 flex justify-center items-center bg-green-600 rounded-md"
          >
            <HiOutlineClipboardDocumentCheck size={21} color="#FFF" />
          </button>
        </Tooltip>
      </div>

      <Check160
        isVisible={checkModal}
        setVisible={setCheckModal}
        vehicle={vehicle}
        chassis={chassis}
      />

      <SpecSheet
        isVisible={specModal}
        setVisible={setSpecModal}
        vehicle={vehicle}
        chassis={chassis}
      />
    </div>
  );
}
