import { formatNumber } from "../../utils/formatNumber";

export default function DocumentDetails({ proforma }: any) {
  return (
    <div className="mt-4">
      <div className="font-bold">Please note the following</div>

      {/* AVAILABILITY */}
      <div className="mt-4">
        <div className="flex">
          <div className="w-28 font-bold ">Availability:</div>
          <div className="flex-1">
            {proforma?.availability?.title || "-"},{" "}
            {proforma?.availability?.description}
          </div>
        </div>

        <div className="flex mt-2">
          <div className="w-28 font-bold ">Color:</div>
          <div className="flex-1">{proforma?.vehicles[0]?.color}</div>
        </div>
        
        <div className="flex mt-2">
          <div className="w-28 font-bold ">Mileage:</div>
          <div className="flex-1">{formatNumber(proforma?.vehicles[0]?.mileage)} KM</div>
        </div>

        <div className="flex mt-2">
          <div className="w-28 font-bold ">Validity:</div>
          <div className="flex-1">
            This Proforma invoice is valid for a period of 30 days from the date
            of issue.
          </div>
        </div>

        <div className="flex mt-2">
          <div className="w-28 font-bold ">Delivery:</div>
          <div className="flex-1">
            Price quoted is subject to {proforma?.transfer?.name} delivery.
          </div>
        </div>

        <div className="flex mt-2">
          <div className="w-28 font-bold ">Payment:</div>
          <div className="flex-1">
            Kindly remit payments in the respective currency of choice, AUTOMARK
            will not accept any responsibility or liability regarding any
            currency exchange losses.
          </div>
        </div>

        <div className="flex mt-2">
          <div className="w-28 font-bold ">Warranty:</div>
          <div className="flex-1">
            12 months / 20,000 km (whichever comes first) Engine and
            Transmission Warranty.
          </div>
        </div>

        <div className="flex mt-2">
          <div className="w-28 font-bold ">Documents:</div>
          <div className="flex-1">
            TIN Certificate for vehicle registration purposes and proof of
            insurance upon vehicle delivery.
          </div>
        </div>
      </div>
    </div>
  );
}
