import CarModel from "../../../assets/images/hilux.png";
import { capitalize } from "lodash";
import { Badge } from "antd";
import { IoLocationOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { serviceStatus } from "../../../utils/contants";

export default function ServiceItem({ service }: any) {
  return (
    <div className="col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-3 border rounded-md hover:bg-gray-50 bg-white">
      <Badge.Ribbon
        text={capitalize(service?.status)}
        color={serviceStatus[service?.status]}
      >
        <Link to={`/services/${service?._id}/detailed`}>
          <div className="flex flex-col p-3 justify-center items-center">
            <div className="flex items-center justify-center w-full mt-2 ">
              <img src={CarModel} className="w-40 h-40" alt="" />
            </div>
            <div className="border-b border-gray-100 pb-2">
              <div className="text-lg font-bold text-gray-700">
                {service?.service_type}
              </div>
              <div className="flex justify-center items-center text-gray-700">
                <IoLocationOutline />
                <span className="ml-1">{service?.location?.name}</span>
              </div>
            </div>
            <div className="flex justify-center items-center pt-2">
              <div className="flex">
                <div className="flex flex-col justify-center">
                  <div className="text-md text-gray-800">
                    <span className="font-bold ">Customer : </span>
                    Automark Tanzania
                  </div>
                  <div className="flex text-gray-600">
                    <div className="">
                      <span className="font-bold">Technician : </span>
                      {service?.technician?.name}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mx-2">Jobcard No: {service?.serial || "-"}</div>
                    <div className="mx-2">WIP: {service?.wip || "-"}</div>
                  </div>

                  <div className="text-gray-600 my-1 text-center">
                    {service?.vehicle?.model?.name} -{" "}
                    {service?.vehicle?.chassis}
                  </div>
                </div>
              </div>
            </div>

            <div className=" justify-center pb-3 mt-4 mb-2">
              <div className="my-1 justify-center">
                {service?.approval?.user !== null ? (
                  <>
                    {service?.approval?.isApproved ? (
                      <span className="text-green-700 border p-1 bg-green-50">
                        Approved by {service?.approval?.user?.name || "-"}
                      </span>
                    ) : (
                      <span className="text-red-700 border p-1 bg-red-50">
                        Rejected by {service?.approval?.user?.name || "-"}
                      </span>
                    )}
                  </>
                ) : (
                  <span className="text-gray-500 border p-1 bg-red-50">
                    Awaiting Manager approval
                  </span>
                )}
              </div>
            </div>
          </div>
        </Link>
      </Badge.Ribbon>
    </div>
  );
}
