import { AUTO_BASE_URL } from "../utils/urls";
import Cookies from "js-cookie";
const fetchDataAuto = async ({ uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${AUTO_BASE_URL}/${uri}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

const postDataAuto = async ({ data, uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${AUTO_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 201) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

const postFileAuto = async ({ data, uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${AUTO_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  });
  if (response.status !== 201) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

const patchDataAuto = async ({ data, uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${AUTO_BASE_URL}/${uri}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (response.status !== 200) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

const putDataAuto = async ({ data, uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${AUTO_BASE_URL}/${uri}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 200) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

const deleteDataAuto = async ({ uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${AUTO_BASE_URL}/${uri}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

export {
  fetchDataAuto,
  postDataAuto,
  patchDataAuto,
  putDataAuto,
  deleteDataAuto,
  postFileAuto,
};
