function checkRotting(last_action: any) {
  const today = new Date();
  const last = new Date(last_action);
  const difference = today.getTime() - last.getTime();
  const rottenDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
  const isRotten = rottenDays > 30 ? true : false;
  // const rottenDays = Math.ceil(difference);
  // const isRotten = rottenDays > 1000 * 60 ? true : false;
  const result = { isRotten, rottenDays };
  return result;
}

export default checkRotting;
