import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchReasons = async () => {
  const uri = `api/support/reasons`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateReason = async (data: any) => {
  const uri = `api/support/reasons`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateReason = async ({ data, _id }: any) => {
  const uri = `api/support/reasons/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteReason = async ({ _id }: any) => {
  const uri = `api/support/reasons/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchReasons,
  handleCreateReason,
  handleUpdateReason,
  handleDeleteReason,
};
