import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import UpdateShipment from "./UpdateShipment";
import Moment from "react-moment";

export default function Shipment({
  arrival,
  shipment,
  chassis,
  editable,
}: any) {
  const [updateModal, setUpdateModal] = useState<boolean>(false);

  return (
    <div>
      <div className="border-b border-t px-4 py-2 flex justify-between bg-gray-100">
        <div className=""> Shipment </div>
        {editable && (
          <button
            onClick={() => setUpdateModal(true)}
            className="border p-1 rounded-md"
          >
            <FiEdit />
          </button>
        )}
      </div>
      <div className="border-t-4"></div>
      <div className="p-4" style={{ fontSize: 14 }}>
        <div className="flex justify-between">
          <div className="">
            <span className="">ETD :</span> Expected Time of Departure
          </div>
          <div className="">
            {shipment?.estimated_departure ? (
              <div className="text-gray-600">
                <Moment format="Do MMM, YYYY">
                  {shipment?.estimated_departure?.toString()}
                </Moment>
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
      <div className="px-4 mb-4" style={{ fontSize: 14 }}>
        <div className="flex justify-between">
          <div className="">
            <span className="">ETA :</span> Expected Time of Arrival
          </div>
          <div className="">
            {shipment?.estimated_arrival ? (
              <div className="text-gray-600">
                <Moment format="Do MMM, YYYY">
                  {shipment?.estimated_arrival?.toString()}
                </Moment>
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
      <div className="px-4 mb-4" style={{ fontSize: 14 }}>
        <div className="flex justify-between">
          <div className="">
            <span className="">ATA :</span> Actual Time of Arrival
          </div>
          <div className="">
            {shipment?.actual_arrival ? (
              <div className="text-gray-600">
                <Moment format="Do MMM, YYYY">
                  {shipment?.actual_arrival?.toString()}
                </Moment>
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
      <UpdateShipment
        isVisible={updateModal}
        setVisible={setUpdateModal}
        arrival={arrival}
        shipment={shipment}
        chassis={chassis}
      />
    </div>
  );
}
