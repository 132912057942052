import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchAllLeads = async () => {
  const uri = `api/crm/leads`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchNewLeads = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/leads/new?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchArchivedLeads = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/leads/archived?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchQualifiedLeads = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/crm/leads/qualified?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateLead = async (data: any) => {
  const uri = `api/crm/leads`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleConvertLead = async ({ lead_id, data }: any) => {
  const uri = `api/crm/leads/${lead_id}/convert`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleCreateLeadTask = async ({ data, _id }: any) => {
  const uri = `api/crm/leads/${_id}/tasks`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateLeadTask = async ({ data, lead_id, task_id }: any) => {
  const uri = `api/crm/leads/${lead_id}/tasks/${task_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteLeadTask = async ({ lead_id, task_id }: any) => {
  const uri = `api/crm/leads/${lead_id}/tasks/${task_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleCompleteLeadTask = async ({ lead_id, task_id }: any) => {
  const uri = `api/crm/leads/${lead_id}/tasks/${task_id}/complete`;
  const response = patchDataAuto({ uri });
  return response;
};

const handleCreateLeadActivity = async ({ data, _id, action }: any) => {
  const uri = `api/crm/leads/${_id}/${action}`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleUpdateLeadVehicles = async ({ data, lead_id }: any) => {
  const uri = `api/crm/leads/${lead_id}/vehicles`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleCreateLeadProforma = async ({ data, lead_id }: any) => {
  const uri = `api/crm/leads/${lead_id}/proforma`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleRemoveLeadVehicle = async ({ vehicle, lead_id }: any) => {
  const uri = `api/crm/leads/${lead_id}/vehicles/${vehicle}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleUpdateLeadActivity = async ({
  data,
  lead_id,
  activity_id,
}: any) => {
  const uri = `api/crm/leads/${lead_id}/activities/${activity_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDoneLeadActivity = async ({ data, lead_id, activity_id }: any) => {
  const uri = `api/crm/leads/${lead_id}/activities/${activity_id}/done`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteLeadActivity = async ({
  data,
  lead_id,
  activity_id,
}: any) => {
  const uri = `api/crm/leads/${lead_id}/activities/${activity_id}`;
  const response = deleteDataAuto({ data, uri });
  return response;
};

const handleCreateLeadMeeting = async ({ data, _id }: any) => {
  const uri = `api/crm/leads/${_id}/meeting`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleCreateLeadSMS = async ({ data, _id }: any) => {
  const uri = `api/crm/leads/${_id}/sms`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUploadLeadDoc = async ({ data, lead_id }: any) => {
  const uri = `api/crm/leads/${lead_id}/file`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleFetchLead = async ({ _id }: any) => {
  const uri = `api/crm/leads/${_id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleUpdateLead = async ({ data, _id }: any) => {
  const uri = `api/crm/leads/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteLead = async ({ _id }: any) => {
  const uri = `api/crm/leads/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleArchiveLead = async ({ lead_id, data }: any) => {
  const uri = `api/crm/leads/${lead_id}/archive`;
  const response = patchDataAuto({ uri, data });
  return response;
};

export {
  handleFetchAllLeads,
  handleFetchNewLeads,
  handleFetchArchivedLeads,
  handleFetchQualifiedLeads,
  handleFetchLead,
  handleCreateLead,
  handleConvertLead,
  handleUpdateLead,
  handleDeleteLead,
  handleArchiveLead,
  handleCreateLeadTask,
  handleUpdateLeadTask,
  handleDeleteLeadTask,
  handleCompleteLeadTask,
  handleCreateLeadActivity,
  handleUpdateLeadActivity,
  handleDeleteLeadActivity,
  handleCreateLeadMeeting,
  handleUpdateLeadVehicles,
  handleRemoveLeadVehicle,
  handleCreateLeadProforma,
  handleCreateLeadSMS,
  handleUploadLeadDoc,
  handleDoneLeadActivity,
};
