import { AiOutlineUnorderedList } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Wrap({ children, actions, active }: any) {
  const menus = [{ id: 1, name: "My Profile", link: "/account/profile" }];
  return (
    <div className="">
      <div className="border-b  bg-white flex justify-between px-2">
        <div className="flex items-center">
          {menus.map((menu: any, index: number) => (
            <Link
              to={menu?.link}
              className={`flex items-center mr-4 text-sm py-3 ${
                active === menu?.name ? "border-b border-automark-dark" : ""
              }`}
              key={index}
            >
              <AiOutlineUnorderedList />
              <span className="ml-1">{menu?.name}</span>
            </Link>
          ))}
        </div>
        <div className="flex items-center">{actions}</div>
      </div>
      <div className="p-2">{children}</div>
    </div>
  );
}
