import {
  Button,
  Form,
  Row,
  Col,
  Input,
  message,
  Select,
  DatePicker,
  Space,
  Drawer,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleCreateContact } from "../../../../api/contacts";
import { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { handleFetchIndustries } from "../../../../api/industries";

const { Option } = Select;

export default function CreateContact({ isVisible, setVisible, inquiry }: any) {
  const [selected, setSelected] = useState(2);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleCreateContact,
    onSuccess: () => {
      message.success("Created Successfully");
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: industries } = useQuery({
    queryKey: ["industries"],
    queryFn: handleFetchIndustries,
  });

  const types = [
    { id: 1, name: "Organization" },
    { id: 2, name: "Individual" },
  ];

  const industryOptions = industries?.map((industry: any, index: number) => (
    <Option value={industry?._id} key={index}>
      {industry?.name}
    </Option>
  ));

  const onSubmit = async () => {
    let business: any = null;
    let particular: any = null;
    let industry: any = null;
    let contact: any = null;
    const values = await form.getFieldsValue();

    if (selected === 1) {
      if (values.vrn) {
        business = {
          vrn: values.vrn,
        };
      }
      if (values.tin) {
        business = {
          ...business,
          tin: values.tin,
        };
      }

      contact = {
        name: values?.cName,
        mobile: values?.cMobile,
      };

      if (values.industryId) {
        const filters = industries.filter(
          (item: any) => item?._id === values.industryId
        );

        industry = {
          entity: filters[0]?._id,
          name: filters[0]?.name,
        };
        business = {
          ...business,
          industry,
        };
      }
    } else {
      particular = {
        gender: values.gender,
        birth: values.birth,
      };
    }

    const data = {
      isCompany: selected === 1 ? true : false,
      business,
      particular,
      contact,
      ...values,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
    form.setFieldsValue({ name: inquiry?.name });
    form.setFieldsValue({ email: inquiry?.email });
    form.setFieldsValue({ mobile: inquiry?.mobile });
  };

  useEffect(() => {
    form.setFieldsValue({ name: inquiry?.name });
    form.setFieldsValue({ email: inquiry?.email });
    form.setFieldsValue({ mobile: inquiry?.mobile });
  }, [inquiry]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">ADD : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">Contact</div>
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="createContact"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          {types?.map((type: any, index: number) => (
            <Col xs={{ span: 24 }} lg={{ span: 12 }} key={index}>
              <button
                type="button"
                className="flex flex-row  items-center mb-4 border w-full"
                onClick={() => setSelected(type.id)}
              >
                {selected === type?.id ? (
                  <div className="h-8 w-8 border-r flex justify-center items-center bg-green-100">
                    <AiOutlineCheck />
                  </div>
                ) : (
                  <div className="h-8 w-8 border-r flex justify-center items-center">
                    <AiOutlineCheck color="#FFFFFF" />
                  </div>
                )}
                <div className="ml-4"></div>
                {type?.name}
              </button>
            </Col>
          ))}
        </Row>

        {selected === 1 ? (
          <div className="">
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="name"
                  label="Company Name"
                  initialValue={inquiry?.name}
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input type="" placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="industryId"
                  label="Industry"
                  rules={[
                    {
                      required: false,
                      message: "Please enter industry",
                    },
                  ]}
                >
                  <Select placeholder="Industry">{industryOptions}</Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input type="" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="mobile"
                  label="Mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input type="" placeholder="Mobile" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="tin"
                  label="TIN"
                  rules={[
                    {
                      required: false,
                      message: "Please enter tin",
                    },
                  ]}
                >
                  <Input type="" placeholder="TIN" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="vrn"
                  label="VRN"
                  rules={[
                    {
                      required: false,
                      message: "Please enter vrn",
                    },
                  ]}
                >
                  <Input type="" placeholder="VRN" />
                </Form.Item>
              </Col>
            </Row>

            <div className="bg-gray-100 py-2 font-bold">Contact Person</div>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="cName"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input type="" placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="cMobile"
                  label="Mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input type="" placeholder="Mobile" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="">
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="name"
                  label={selected === 1 ? "Company Name" : "Full Name"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input type="" placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="tin"
                  label="TIN"
                  rules={[
                    {
                      required: false,
                      message: "Please enter tin",
                    },
                  ]}
                >
                  <Input type="" placeholder="TIN" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input type="" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="mobile"
                  label="Mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input type="" placeholder="Mobile" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select gender",
                    },
                  ]}
                >
                  <Select placeholder="Gender">
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="birth"
                  label="Date of Birth"
                  rules={[
                    {
                      required: false,
                      message: "Please enter D.O.B",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Date of Birth"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}

        <Form.Item>
          <Button danger type="primary" htmlType="submit">
            Create
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
