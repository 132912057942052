import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchSources = async () => {
  const uri = `api/sources`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateSource = async (data: any) => {
  const uri = `api/sources`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleUpdateSource = async ({ data, _id }: any) => {
  const uri = `api/sources/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteSource = async ({ _id }: any) => {
  const uri = `api/sources/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchSources,
  handleCreateSource,
  handleUpdateSource,
  handleDeleteSource,
};
