import { CiSearch } from "react-icons/ci";

export default function NoRecords() {
  return (
    <div className="m-2 border bg-white h-44 flex flex-col justify-center items-center">
      <div className="">
        <CiSearch size={24} />
      </div>
      <div className="text-gray-600 mt-2">No Records Found</div>
    </div>
  );
}
