import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import UpdateAvailability from "./UpdateAvailability";

export default function Availability({ chassis, availability, editable }: any) {
  const [updateModal, setUpdateModal] = useState<boolean>(false);

  return (
    <div>
      <div className="border-b border-t px-4 py-2 flex justify-between bg-gray-100">
        <div className=""> Availability</div>
        {editable && (
          <button
            onClick={() => setUpdateModal(true)}
            className="border p-1 rounded-md"
          >
            <FiEdit />
          </button>
        )}
      </div>
      <div className="border-t-4"></div>
      <div className="p-4" style={{ fontSize: 14 }}>
        {availability?.title || "-"}
        {", "}
        {availability?.description || "-"}
      </div>

      <UpdateAvailability
        isVisible={updateModal}
        setVisible={setUpdateModal}
        chassis={chassis}
        availability={availability}
      />
    </div>
  );
}
