export default function AgeingWidget({ item, handleSelect }: any) {
  return (
    <button
      onClick={() => handleSelect({ item })}
      className="border border-gray-300 px-4 py-2 bg-red-100 h-20 w-full"
    >
      <div className="text-lg flex justify-start">{item?._id}</div>
      <div className="flex justify-between">
        <div className="text-3xl">
          <span className="font-bold">{item?.total || "-"}</span>
          <span className="text-lg ml-1">Vehicles</span>
        </div>
      </div>
    </button>
  );
}
