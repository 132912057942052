import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { IoTrashBinOutline } from "react-icons/io5";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import Manage from "..";
import CreateContact from "./components/CreateContact";
import UpdateProforma from "./components/UpdateProforma";
import { postDataMail } from "../../../services/mail";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import DeleteProforma from "./components/DeleteProforma";
import { handleFetchProformas } from "../../../api/proforma";
import Moment from "react-moment";
import { BsEye } from "react-icons/bs";
import ProformaDetails from "../../proforma/components/ProformaDetails";
import { TbSignature } from "react-icons/tb";
import { capitalize } from "lodash";
import ApproveProforma from "./components/ApproveProforma";
import { manageApprovalColors } from "../../../constants/colors";

export default function AllProforma() {
  const [selected, setSelected] = useState<any>({});
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 24,
    total_pages: 1,
    total_count: 60,
  });

  const { data, refetch, isFetched, isLoading, isFetching, isError } = useQuery(
    {
      queryKey: ["proformas", { pagination }],
      queryFn: handleFetchProformas,
    }
  );

  const onChange = (params: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: data?.total_count,
    });
  };

  const getYear = (date: any) => {
    let year = new Date(date).getFullYear();
    return year;
  };

  const [emailForm] = Form.useForm();

  const handleRefetch = () => {
    refetch();
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Customer Name",
      width: 120,
      render: (record: any) => {
        return (
          <div className="flex flex-col">
            <div className="text-gray-700 font-bold">
              {record?.contact?.name || "-"}
            </div>
            <div className="text-gray-600 text-xs">
              {record?.contact?.email || "-"}
            </div>
            <div className="text-gray-600 text-xs">
              {record?.contact?.mobile || "-"}
            </div>
          </div>
        );
      },
    },

    {
      title: "Reference",
      width: 120,
      render: (record: any) => {
        return (
          <div className="uppercase font-bold truncate">
            {record?.source?.name === "OFF-LEASE"
              ? "SFL/SSBCL"
              : "KJL/AUTOMARK"}
            /{record?.pfi}/{getYear(record?.createdAt)}
          </div>
        );
      },
    },
    {
      title: "Created By",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.creator?.name ? record?.creator?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Created",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.status?.date ? (
              <Moment fromNow>{record?.status?.date}</Moment>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      title: "Aproved By",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.approver?.name ? record?.approver?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Destination",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.transfer?.name ? record?.transfer?.name : "-"}
          </span>
        );
      },
    },
    // {
    //   title: "Discount",
    //   width: 120,
    //   render: (record: any) => {
    //     return (
    //       <div className="text-gray-700">
    //         {record?.discount?.isPercentage ? (
    //           <div className="flex">
    //             <div className="">{record?.discount?.amount}</div>
    //             <div className="">
    //               {record?.discount?.isPercentage ? "%" : ""}
    //             </div>
    //           </div>
    //         ) : (
    //           <div className="flex">
    //             <div className="">{formatMoney(record?.discount?.amount)}</div>
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Total Amount",
    //   width: 120,
    //   render: (record: any) => {
    //     return (
    //       <span className="text-gray-700">
    //         <div className="">{getTotal(record?.vehicles)}</div>
    //       </span>
    //     );
    //   },
    // },

    {
      title: "Status",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            <div className="">{capitalize(record?.status?.state)}</div>
          </span>
        );
      },
    },
    {
      title: "Action",
      width: 100,
      render: (record: any) => (
        <div className="flex justify-end">
          <Tooltip title="Proforma Details">
            <button
              onClick={() => {
                setSelected({
                  ...record,
                });
                setDetailModal(true);
              }}
              className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-blue-50 hover:bg-gray-200 cursor-pointer"
            >
              <BsEye />
            </button>
          </Tooltip>
          <Tooltip title="Approve Proforma">
            <button
              onClick={() => {
                setSelected({
                  ...record,
                });
                setApproveModal(true);
              }}
              className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-green-100 hover:bg-gray-200 cursor-pointer"
            >
              <TbSignature />
            </button>
          </Tooltip>
          <Tooltip title="Update Proforma">
            <button
              onClick={() => {
                setSelected({
                  ...record,
                });
                setUpdateModal(true);
              }}
              className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-yellow-100 hover:bg-gray-200 cursor-pointer"
            >
              <FiEdit />
            </button>
          </Tooltip>

          <Tooltip title="Delete Proforma">
            <button
              onClick={() => {
                setSelected({
                  ...record,
                });
                setDeleteModal(true);
              }}
              className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
            >
              <IoTrashBinOutline />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const mapped = data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Email: item?.email === null ? "-" : item?.email,
        Address: item?.address === null ? "-" : item?.address,
        TIN: item?.tin === null ? "-" : item?.tin,
        Category: item?.isCompany ? "Organization" : "Individual",
      };
    });

    const raw = getExportData(mapped);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  useEffect(() => {
    if (data?.total_count) {
      setPagination({
        ...pagination,
        total_count: data?.total_count,
      });
    }
  }, [isFetched]);

  return (
    <Manage
      title={"Proforma"}
      subtitle={"All"}
      description={"List of all proforma"}
      actions={
        <div className="flex">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  data?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Name: item?.name === null ? "-" : item?.name,
                      Mobile: item?.mobile === null ? "-" : item?.mobile,
                      Email: item?.email === null ? "-" : item?.email,
                      Address: item?.address === null ? "-" : item?.address,
                      TIN: item?.tin === null ? "-" : item?.tin,
                      Category: item?.isCompany ? "Organization" : "Individual",
                    };
                  }),
                  "Proforma.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button>+ New</Button>
        </div>
      }
    >
      <>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="grid gap-x-0 grid-cols-12">
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <Table
                size="small"
                dataSource={data === undefined ? [] : data?.results}
                columns={columns}
                scroll={{ y: 660, x: 0 }}
                rowKey="id"
                loading={isLoading}
                onChange={onChange}
                pagination={{
                  current: pagination?.page,
                  pageSize: pagination?.per_page,
                  total: pagination?.total_count,
                }}
                rowClassName={(record: any, index: number) =>
                  `${manageApprovalColors[record?.status?.state]}`
                }
              />

              <CreateContact
                isVisible={createModal}
                setVisible={setCreateModal}
                refetch={handleRefetch}
              />

              <UpdateProforma
                isVisible={updateModal}
                setVisible={setUpdateModal}
                selected={selected}
              />

              <ApproveProforma
                isVisible={approveModal}
                setVisible={setApproveModal}
                selected={selected}
              />

              <DeleteProforma
                isVisible={deleteModal}
                setVisible={setDeleteModal}
                selected={selected}
              />

              <ProformaDetails
                isVisible={detailModal}
                setVisible={setDetailModal}
                selected={selected}
              />
            </div>
          </div>
        )}
      </>
    </Manage>
  );
}
