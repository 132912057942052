import logo from "../../assets/images/logo-red.png";
export default function DocumentHeader() {
  return (
    <div className="flex justify-between">
      <div className="-mt-2">
        <div className="text-lg text-automark-dark font-bold">
          TOYOTA TANZANIA LTD
        </div>
        <div className="text-md mb-1">No. 19 Sokoine</div>
        <div className="text-md mb-1">P.O Box 9060, Dar es Salaam</div>
        <div className="text-md mb-1">Tel: +255 22 2118990/1</div>
        <div className="text-md mb-1">Fax: +255 22 2118994</div>
        <div className="text-md mb-1">VRN 10-006645-E</div>
        <div className="text-md">TIN 100-146-304</div>
      </div>
      <div className="">
        <img src={logo} width={250} />
      </div>
    </div>
  );
}
