import { useQuery } from "@tanstack/react-query";
import { handleFetchVehicles } from "../../api/vehicles";
import Something from "../../components/shared/Something";
import VehicleWidget from "./components/VehicleWidget";
import CreateProforma from "./components/CreateProforma";
import { useContext, useEffect, useState } from "react";
import VehicleDetails from "./components/VehicleDetails";
import {
  Button,
  Form,
  Input,
  Pagination,
  Popover,
  Tooltip,
  message,
} from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
  RiSearch2Line,
} from "react-icons/ri";
import {
  ExportToExcel,
  blobToDataUrl,
  getExportData,
} from "../../utils/exportExcel";
import { postDataMail } from "../../services/mail";
import AdvancedSearch from "./components/AdvancedSearch";
import { AuthContext } from "../../context/AuthContext";

const { Search } = Input;

export default function Vehicles() {
  const { auth } = useContext(AuthContext);
  const [proformaModal, setProformaModal] = useState<boolean>(false);
  const [detailsModal, setDetailsModal] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const [advSearch, setAdvSearch] = useState(false);

  const initials = {
    chassis: "",
    vsb: "",
    model: "",
    source: "",
    status: "",
    transmission: "",
    exteriorColor: "",
    location: "",
    fuel: "",
    availability: "",
    minYear: "",
    maxYear: "",
    minPrice: "",
    maxPrice: "",
    minMileage: "",
    maxMileage: "",
  };
  const [filters, setFilters] = useState<any>({ ...initials });
  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 12,
    total_pages: 1,
    total_count: 0,
  });
  const [form] = Form.useForm();

  const [emailForm] = Form.useForm();

  const params = `&chassis=${filters?.chassis}&vsb=${filters?.vsb}&model=${filters?.model}&source=${filters?.source}&status=${filters?.status}&transmission=${filters?.transmission}&exteriorColor=${filters?.exteriorColor}&location=${filters?.location}&fuel=${filters?.fuel}&availability=${filters?.availability}&minYear=${filters?.minYear}&maxYear=${filters?.maxYear}&minPrice=${filters?.minPrice}&maxPrice=${filters?.maxPrice}&minMileage=${filters?.minMileage}&maxMileage=${filters?.maxMileage}`;

  const {
    data: stock,
    refetch,
    isFetching,
    isFetched,
    isLoading,
    isError,
  } = useQuery(["vehicles", { pagination, params }], handleFetchVehicles);

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };

  const clearAllFilters = () => {
    form.resetFields();
    setAdvSearch(false);
    setFilters({ ...initials });
  };

  const clearFilters = () => {
    form.resetFields();
    setFilters({ ...initials });
  };

  const onChange = (page: any, per_page: any) => {
    setPagination({
      ...pagination,
      page,
      per_page,
      total_count: stock?.total_count,
    });
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = stock?.vehicles?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Chassis: item?.chassis === null ? "-" : item?.chassis,
        Model: item?.model?.name === null ? "-" : item?.model?.name,
        Mileage: item?.mileage === null ? "-" : item?.mileage,
        Year: item?.year === null ? "-" : item?.year,
        Source: item?.source?.name === null ? "-" : item?.source?.name,
        Price: item?.prices?.exclusive === null ? "-" : item?.prices?.exclusive,

        Status: item?.status === null ? "-" : item?.status,
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  useEffect(() => {
    if (stock?.total_count) {
      setPagination({
        ...pagination,
        total_count: stock?.total_count,
      });
    }
  }, [isFetched]);

  return (
    <div className="">
      <div className="min-h-screen">
        <div className="h-12 border-b flex justify-between items-center px-2 bg-white">
          <Form layout="inline" form={form}>
            <Form.Item name="chassis">
              <Search
                placeholder="Search by chassis"
                allowClear
                className="rounded-none"
                onSearch={(e: any) => {
                  setFilters({ ...filters, chassis: e });
                }}
              />
            </Form.Item>
            <Form.Item name="vsb">
              <Search
                placeholder="Search by VSB"
                allowClear
                className="rounded-none"
                onSearch={(e: any) => {
                  setFilters({ ...filters, vsb: e });
                }}
              />
            </Form.Item>
            <Button
              className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
              onClick={() => {
                setAdvSearch(!advSearch);
              }}
            >
              <div className="flex justify-center items-center">
                <div className="mr-2">Advanced Search</div>
                <div className="border-l px-2">
                  <RiSearch2Line color="gray" size={14} />
                </div>
              </div>
            </Button>
            <Button
              className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
              onClick={() => {
                clearAllFilters();
              }}
            >
              <RiFilterOffLine color="gray" />
            </Button>
          </Form>
          <div className="">
            <div className="flex">
              <Popover
                content={
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      form={emailForm}
                      onFinish={sendMail}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is Required" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                          { required: true, message: "Subject is Required" },
                        ]}
                      >
                        <Input placeholder="Email Subject" />
                      </Form.Item>
                      <div className="flex flex-row justify-end border-t pt-2">
                        <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                          <Button
                            onClick={() => {
                              toggleMessageModal(false);
                            }}
                            danger
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
                          <Button type="primary" danger htmlType="submit">
                            {isMailLoading ? "Sending ..." : "Submit"}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </>
                }
                title="Message Content"
                trigger="focus"
                placement="left"
                open={messageModal}
              >
                <Tooltip title="Send Email">
                  <button
                    className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                    onClick={async () => {
                      toggleMessageModal(true);
                    }}
                  >
                    <RiMailSendLine size={18} />
                  </button>
                </Tooltip>
              </Popover>
              <Tooltip title="Export To Excel">
                <button
                  color="#E50000"
                  className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={() =>
                    ExportToExcel(
                      stock?.vehicles?.map((item: any, index: number) => {
                        return {
                          "S/N": index + 1,
                          Chassis: item?.chassis === null ? "-" : item?.chassis,
                          Model:
                            item?.model?.name === null
                              ? "-"
                              : item?.model?.name,
                          Mileage: item?.mileage === null ? "-" : item?.mileage,
                          Year: item?.year === null ? "-" : item?.year,
                          Source:
                            item?.source?.name === null
                              ? "-"
                              : item?.source?.name,
                          Price:
                            item?.prices?.exclusive === null
                              ? "-"
                              : item?.prices?.exclusive,

                          Status: item?.status === null ? "-" : item?.status,
                        };
                      }),
                      "Vehicles.xlsx"
                    )
                  }
                >
                  <RiFileExcel2Line size={18} />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        {advSearch && (
          <AdvancedSearch
            setVisible={setAdvSearch}
            isLoading={isLoading}
            handleAdvancedSearch={handleAdvancedSearch}
            clearAllFilters={clearFilters}
          />
        )}
        <div className="">
          {isError ? (
            <Something refetch={refetch} isFetching={isFetching} />
          ) : (
            <div className="">
              <div className="p-2">
                <div className="grid grid-cols-12 gap-2">
                  {stock?.vehicles?.map((item: any, index: number) => (
                    <VehicleWidget
                      item={item}
                      key={index}
                      auth={auth}
                      setProformaModal={setProformaModal}
                      setDetailsModal={setDetailsModal}
                      setSelected={setSelected}
                    />
                  ))}
                </div>
              </div>

              <div className="p-4 flex justify-start items-center w-full">
                <Pagination
                  showQuickJumper
                  defaultCurrent={pagination?.page}
                  total={pagination?.total_count}
                  onChange={onChange}
                  pageSize={pagination?.per_page}
                  showPrevNextJumpers={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CreateProforma
        isVisible={proformaModal}
        setVisible={setProformaModal}
        selected={selected}
      />

      <VehicleDetails
        isVisible={detailsModal}
        setVisible={setDetailsModal}
        selected={selected}
      />
    </div>
  );
}
