import { Button, Drawer, Form, Space, message } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { PrinterOutlined } from "@ant-design/icons";
import { useRef } from "react";
import logo from "../../assets/images/logo-red.svg";
import inspection from "../../assets/images/inspection.jpg";
import ReactToPrint from "react-to-print";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateVehicleCheck } from "../../api/vehicles";
import "../../assets/styles/custom.css";

export default function Check160({ isVisible, setVisible, vehicle }: any) {
  const docRef = useRef(null);

  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const check = {
    is_finished: true,
    1: {
      name: "VIN & Dealer Code Check",
      questions: [
        {
          id: 1,
          name: "Question 1, ground 1 with this as one of one for the whole",
          option1: true,
          option2: false,
          option3: false,
        },
        {
          id: 1,
          name: "Question 1, ground 1",
          option1: true,
          option2: false,
          option3: false,
        },
        {
          id: 1,
          name: "Question 1, ground 1",
          option1: true,
          option2: false,
          option3: false,
        },
      ],
    },
    2: {
      name: "Road Test",
      questions: [
        {
          id: 1,
          name: "Question 1, ground 1",
          option1: true,
          option2: false,
          option3: false,
        },
        {
          id: 2,
          name: "Question 2, ground 1",
          option1: false,
          option2: false,
          option3: false,
        },
        {
          id: 3,
          name: "Question 3, ground 1",
          option1: false,
          option2: false,
          option3: false,
        },
      ],
    },
    4: {
      name: "Frame, Structure & Underbody",
      questions: [
        {
          id: 1,
          name: "Question 1, ground 1",
          option1: true,
          option2: false,
          option3: false,
        },
        {
          id: 2,
          name: "Question 2, ground 1",
          option1: false,
          option2: false,
          option3: false,
        },
        {
          id: 3,
          name: "Question 3, ground 1",
          option1: false,
          option2: false,
          option3: false,
        },
      ],
    },
  };

  const { mutate } = useMutation({
    mutationFn: handleUpdateVehicleCheck,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["proformas"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const check1 = async (e: CheckboxChangeEvent) => {
    let option1 = e.target.checked;
    let option2 = false;
    let option3 = false;

    const data = { option1, option2, option3 };
    if (check?.is_finished) {
      message.error("This report was marked completed");
      return;
    }

    await mutate(data);
  };

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">160 CHECK POINT INSPECTION :</span>
          </span>
          <div className="flex text-gray-500 px-2">
            [ VEHICLE - {vehicle?.chassis} ]
          </div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <ReactToPrint
            trigger={() => {
              return (
                <Button className="flex justify-start items-center focus:outline-none hover:text-red-500 w-full">
                  <PrinterOutlined /> Print
                </Button>
              );
            }}
            content={() => docRef.current}
            documentTitle={`Spec-Sheet`}
          />
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="book -mt-10" ref={docRef} id="specsheet">
        <div className="page">
          <div className="" style={{ fontSize: 11 }}>
            <div className="p-1">
              <div className="flex justify-center">
                <img src={logo} width={320} alt="" />
              </div>

              <div className="flex justify-center font-bold text-xl mt-4">
                160-Point Quality Assurance Inspection Check Sheet
              </div>

              <div className="mt-6 border-b-2 border-black pb-2">
                <div className="grid grid-cols-12">
                  <div className="col-span-4">
                    Vehicle Registration no: {vehicle?.registration}
                  </div>

                  <div className="col-span-4">
                    Odometer Reading: {`${vehicle?.mileage} Km`}
                  </div>
                  <div className="col-span-4">
                    Chassis No: {vehicle?.chassis}
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-4">
                    Vehicle Make/type: {vehicle?.model?.name}
                  </div>
                  <div className="col-span-4">Operator:</div>
                  <div className="col-span-4">Date:</div>
                </div>
              </div>

              <div className="mt-4">
                <div className="grid grid-cols-12 gap-x-2">
                  <div className="col-span-6">
                    <CheckSegment
                      data={check[1]}
                      isFirst={true}
                      start={1}
                      check1={check1}
                      check2={check1}
                      check3={check1}
                    />

                    <CheckSegment
                      data={check[2]}
                      isFirst={false}
                      start={2}
                      check1={check1}
                      check2={check1}
                      check3={check1}
                    />
                  </div>
                  <div className="col-span-6">
                    <CheckSegment
                      data={check[4]}
                      isFirst={false}
                      start={35}
                      check1={check1}
                      check2={check1}
                      check3={check1}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-16">
                <img src={inspection} width={320} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

const CheckSegment = ({
  data,
  isFirst,
  start,
  check1,
  check2,
  check3,
}: any) => {
  return (
    <div className="mb-1">
      <div className="text-automark-pale font-bold">{data?.name}</div>

      {data?.questions?.map((record: any, index: number) => (
        <div className="flex justify-between mb-1" key={index}>
          <div className="flex items-start justify-start">
            <div className="w-3">
              {isFirst ? (
                <span>{index === 0 ? "1." : " "}</span>
              ) : (
                <span>{start + index}.</span>
              )}
            </div>
            <div className="ml-2">{record.name}</div>
          </div>
          <div className="flex">
            <input
              type="checkbox"
              className="ml-1"
              onChange={(e: any) => check1(e, record?.id)}
              checked={record.option1}
              defaultValue={record.option1}
            />
            <input
              type="checkbox"
              className="ml-1"
              onChange={(e: any) => check2(e, record?.id)}
              checked={record.option2}
              defaultValue={record.option2}
            />
            <input
              type="checkbox"
              className="ml-1"
              onChange={(e: any) => check3(e, record?.id)}
              checked={record.option3}
              defaultValue={record.option3}
            />
          </div>
        </div>
      ))}
      {/* <table>
        {data?.questions?.map((record: any, index: number) => (
          <tr key={index}>
            {isFirst ? (
              <td>{index === 0 ? "1." : ""}</td>
            ) : (
              <td>{start + index}.</td>
            )}
            <td className="mr-2">{record.name}</td>
            <td align="center">
              <input
                type="checkbox"
                onChange={(e: any) => check1(e, record?.id)}
                checked={record.option1}
                defaultValue={record.option1}
              />
            </td>
            <td align="center">
              <input
                type="checkbox"
                onChange={(e: any) => check2(e, record?.id)}
                checked={record.option2}
                defaultValue={record.option2}
              />
            </td>
            <td align="center">
              <input
                type="checkbox"
                onChange={(e: any) => check3(e, record?.id)}
                checked={record.option3}
                defaultValue={record.option3}
              />
            </td>
          </tr>
        ))}
      </table> */}
    </div>
  );
};
