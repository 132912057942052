import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchIndustries = async () => {
  const uri = `api/support/industries`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateIndustry = async (data: any) => {
  const uri = `api/support/industries`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateIndustry = async ({ data, _id }: any) => {
  const uri = `api/support/industries/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteIndustry = async ({ _id }: any) => {
  const uri = `api/support/industries/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchIndustries,
  handleCreateIndustry,
  handleUpdateIndustry,
  handleDeleteIndustry,
};
