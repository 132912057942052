import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message } from "antd";
import checkRotting from "../../../../utils/checkRotting";
import formatMoney from "../../../../utils/formatMoney";
import { Link } from "react-router-dom";
import { AiFillFire, AiOutlineFire } from "react-icons/ai";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  handleGoToAnyDealStage,
  handleGoToNextDealStage,
  handleGoToPrevDealStage,
  handleMakeDealLost,
  handleMakeDealWon,
} from "../../../../api/deals";

export default function DealItem({ deal, last, stages }: any) {
  const queryClient = useQueryClient();

  const { stage_id, last_action } = deal;

  const { mutate: goToNextStage } = useMutation({
    mutationFn: handleGoToNextDealStage,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["newDeals"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: goToPrevStage } = useMutation({
    mutationFn: handleGoToPrevDealStage,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["newDeals"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: goToAnyStage } = useMutation({
    mutationFn: handleGoToAnyDealStage,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["newDeals"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: makeDealWon } = useMutation({
    mutationFn: handleMakeDealWon,
    onSuccess: () => {
      message.success("Deal marked as Won ");
      queryClient.invalidateQueries({ queryKey: ["newDeals"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: makeDealLost } = useMutation({
    mutationFn: handleMakeDealLost,
    onSuccess: () => {
      message.success("Deal marked as Lost");
      queryClient.invalidateQueries({ queryKey: ["newDeals"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleShiftDeal = async ({ deal_id, stage_id, data }: any) => {
    await goToAnyStage({ deal_id, stage_id, data });
  };

  const handleShiftUpDeal = async ({ deal_id }: any) => {
    await goToNextStage({ data: [], deal_id });
  };

  const handleShiftDownDeal = async ({ deal_id }: any) => {
    await goToPrevStage({ data: [], deal_id });
  };

  const handleMakeWon = async ({ deal_id }: any) => {
    await makeDealWon({ data: [], deal_id });
  };

  const handleMakeLost = async ({ deal_id }: any) => {
    await makeDealLost({ data: [], deal_id });
  };

  const { isRotten } = checkRotting(last_action);

  const menu = (
    <Menu>
      {stages.map((stage: any) => (
        <Menu.Item
          onClick={() =>
            handleShiftDeal({
              deal_id: deal?._id,
              stage_id: stage?._id,
              data: { state: stage._id, remark: "" },
            })
          }
        >
          {stage.name}
        </Menu.Item>
      ))}
      <div className="border-b-4 pb-2"></div>
      <Menu.Item
        onClick={() =>
          handleMakeWon({
            deal_id: deal?._id,
            data: { remarks: "The lead was won" },
          })
        }
      >
        Won
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          handleMakeLost({
            deal_id: deal?._id,
            data: { remarks: "The lead was lost" },
          })
        }
      >
        Lost
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`${
        isRotten ? "bg-red-100" : "bg-white"
      } border px-2 pt-3 pb-1 mb-1 flex justify-between items-center`}
    >
      <div className="">
        <div className="flex items-center font-bold">
          <div className="flex justify-between">
            <Link to={`/crm/deals/${deal?._id}/detailed`}>
              <div className="flex">
                <div>
                  {deal?.flags?.isWarm ? (
                    <AiFillFire style={{ fontSize: 18, color: "orange" }} />
                  ) : (
                    <AiOutlineFire style={{ fontSize: 18, color: "gray" }} />
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="ml-1 text-sm">{deal?.title}</div>
                  <div className="ml-1 text-sm">
                    $ {formatMoney(deal?.value ? deal?.value : 0)}
                  </div>
                </div>
              </div>
            </Link>
            <div className="ml-2 0">
              <Dropdown overlay={menu} placement="bottomCenter" arrow>
                <DownOutlined />
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="flex justify-between text-gray-600">
          <div className="text-gray-800 ml-5">{deal?.contact?.name}</div>
        </div>

        <div className="flex justify-between text-gray-600 w-full border-t pt-2 mt-2">
          <div className="text-gray-800 flex justify-center items-center ml-4">
            <UserOutlined />
            <span className="ml-1">{deal?.creator?.name}</span>
          </div>

          {last.id === stage_id ? (
            <div className="flex justify-center items-center py-2">
              <button
                onClick={() =>
                  handleMakeWon({
                    deal_id: deal?._id,
                    data: { remarks: "The lead was won" },
                  })
                }
                className="flex justify-center items-center   focus:outline-none hover:bg-green-50"
              >
                Won
              </button>
              <button
                onClick={() =>
                  handleMakeLost({
                    deal_id: deal?._id,
                    data: { remarks: "The lead was lost" },
                  })
                }
                className="flex justify-center items-center ml-3  focus:outline-none hover:bg-red-50"
              >
                Lost
              </button>
            </div>
          ) : (
            <div className="flex justify-center items-center py-2">
              <button
                onClick={() =>
                  handleShiftDownDeal({
                    deal_id: deal?._id,
                  })
                }
                className="flex justify-center items-center   focus:outline-none hover:text-green-500"
              >
                Prev
              </button>

              <button
                onClick={() =>
                  handleShiftUpDeal({
                    deal_id: deal?._id,
                  })
                }
                className="flex justify-center items-center ml-4  focus:outline-none hover:text-green-500"
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
