import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function Reports() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/reports/deals");
  });
  return <div className="h-screen flex"></div>;
}
