import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, DatePicker, Form, Input, message } from "antd";
import moment from "moment";
import {
  handleUpdateLeadTask,
  handleDeleteLeadTask,
} from "../../../../api/leads";
import { AiOutlineDelete } from "react-icons/ai";

export default function UpdateTask({
  setUpdateTask,
  lead_id,
  selectedTask,
}: any) {
  const { description, dates } = selectedTask;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate: update, isLoading } = useMutation({
    mutationFn: handleUpdateLeadTask,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: remove } = useMutation({
    mutationFn: handleDeleteLeadTask,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const dats = {
    date: moment(dates?.expected),
  };

  const handleUpdate = async () => {
    const values = await form.validateFields();
    const data = {
      ...values,
    };
    await update({ data, lead_id, task_id: selectedTask?._id });
    setUpdateTask(false);
  };

  const handleDelete = async () => {
    await remove({ lead_id, task_id: selectedTask?._id });
    setUpdateTask(false);
  };

  return (
    <div>
      <Form
        name="normal_login"
        className="login-form"
        form={form}
        onFinish={handleUpdate}
      >
        <Form.Item
          name="description"
          initialValue={description}
          rules={[
            {
              required: true,
              message: "Please enter task description",
            },
          ]}
        >
          <Input placeholder="Title" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="expected"
          initialValue={dats?.date}
          rules={[
            {
              required: true,
              message: "Please enter task date",
            },
          ]}
        >
          <DatePicker placeholder="Date" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item>
          <Button
            onClick={() => setUpdateTask(false)}
            type="default"
            danger
            className="login-form-button mr-2"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete()}
            type="primary"
            danger
            htmlType="submit"
            className="login-form-button mx-2"
          >
            <AiOutlineDelete />
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="bg-green-600"
            loading={isLoading}
            onClick={() => handleUpdate()}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
