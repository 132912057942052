import { useQuery } from "@tanstack/react-query";
import { handleFetchStockAgeSourceReport } from "../../../api/reports";
import AgedWidget from "./AgedWidget";
import { useState } from "react";
import StockPanel from "./StockPanel";

export default function AgedWrapper({ dates }: any) {
  const { data: stock } = useQuery({
    queryKey: ["stockAgeSourceReport"],
    queryFn: handleFetchStockAgeSourceReport,
  });


  const [selected, setSelected] = useState<any>([]);
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleSelect = ({ item }: any) => {
    setSelected(item);
    setVisible(true);
  };

const ordered = stock?.sort((a: any, b: any) => {
  const aFirstPart = a?._id.split(' ')[0];
  const bFirstPart = b?._id.split(' ')[0];
  
  if (aFirstPart.startsWith('>=')) return 1;
  if (bFirstPart.startsWith('>=')) return -1;
  
  return parseInt(aFirstPart, 10) - parseInt(bFirstPart, 10);
});


  return (
    <div className="">
      <div className="grid grid-cols-12 gap-4 mt-3">
        {ordered?.map((item: any, index: number) => (
          <AgedWidget
            title="Hello"
            item={item}
            key={index}
            handleSelect={handleSelect}
          />
        ))}
      </div>
      <StockPanel
        title="AGED STOCK"
        subtitle={selected?._id?.source}
        vehicles={selected?.list || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
