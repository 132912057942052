import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import CreateHobby from "./CreateHobby";
import UpdateHobby from "./UpdateHobby";
import DeleteHobby from "./DeleteHobby";

export default function HobbyDetails({ contact, editMode }: any) {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  return (
    <div className="">
      <div className="flex justify-between pb-2 border-b-2">
        <div className="font-bold">Personal Hobbies</div>
        {editMode && (

          <button onClick={() => setCreateModal(true)} className="border p-2">
          <AiOutlinePlus />
        </button>
          )}
      </div>

      {contact?.particular?.hobbies.length === 0 ? (
        <div className="">
          <div className="text-gray-700 py-8">No Hobbies Found</div>
        </div>
      ) : (
        <div className="">
          {contact?.particular?.hobbies?.map((item: any, index: number) => (
            <div className="flex justify-between  my-4" key={index}>
              <div className="flex flex-col justify-center">
                {index + 1}. {item}
              </div>
              {editMode && (

              <div className="flex items-center justify-center">
                <button
                  onClick={() => {
                    setSelected(item);
                    setUpdateModal(true);
                  }}
                  className=""
                >
                  <FiEdit />
                </button>
                <button
                  onClick={() => {
                    setSelected(item);
                    setDeleteModal(true);
                  }}
                  className="ml-1 text-red-600"
                >
                  <FiTrash2 />
                </button>
              </div>
              )}

            </div>
          ))}
        </div>
      )}

      <CreateHobby
        isVisible={createModal}
        setVisible={setCreateModal}
        contact={contact}
      />

      <UpdateHobby
        isVisible={updateModal}
        setVisible={setUpdateModal}
        contact={contact}
        selected={selected}
      />

      <DeleteHobby
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        contact={contact}
        selected={selected}
      />
    </div>
  );
}
