import FeatureItem from "../../pages/manage/vehicles/components/FeatureItem";

export default function FeatureSetThree({ vehicle }: any) {
  return (
    <div className="">
      <div className="border-t-4 mt-1"></div>
          <FeatureItem
            title={"Turning Radius"}
            detail={vehicle?.specifications?.turning_radius || "-"}
          />
          <FeatureItem
            title={"Ground Clearance"}
            detail={vehicle?.specifications?.ground_clearance || "-"}
          />
          <FeatureItem
            title={"Drive-Train"}
            detail={vehicle?.specifications?.drive_train || "-"}
          />
          <FeatureItem
            title={"Interior Trim"}
            detail={vehicle?.specifications?.interior_trim || "-"}
          />
          <FeatureItem
            title={"No of Speakers"}
            detail={vehicle?.specifications?.speakers || "-"}
          />

          <FeatureItem
            title={"Tyre Size"}
            detail={vehicle?.specifications?.tyre_size || "-"}
          />
    </div>
  );
}
