import { Button, Form, Input, message, Popover, Tooltip } from "antd";
import { useState } from "react";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import Manage from "..";
import { postDataMail } from "../../../services/mail";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { BsChevronDown } from "react-icons/bs";
import CreateYearBudget from "./components/CreateYearBudget";
import { handleFetchYearBudget } from "../../../api/budget";
import MonthBudget from "./components/MonthBudget";
import UpdateMonthBudget from "./components/UpdateMonthBudget";
import { IoWarningOutline } from "react-icons/io5";

export default function AllBudgets() {
  const initialYear = new Date().getFullYear();
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [opened, setOpened] = useState<boolean>(false);
  const [year, setYear] = useState<any>(initialYear);
  const [selected, setSelected] = useState<any>({});
  const [number, setNumber] = useState<any>({});

  const {
    data: budget,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["budgets", year],
    queryFn: () => handleFetchYearBudget({ year }),
  });

  const handleUpdateMonth = ({ month, number }: any) => {
    setSelected(month);
    setNumber(number);
    setUpdateModal(true);
  };

  const [emailForm] = Form.useForm();

  const handleSelectYear = async (year: any) => {
    setYear(year);
    await refetch();
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const mapped = budget?.months?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Description: item?.description === null ? "-" : item?.description,
        Status: item?.isActive ? "Active" : "In Active",
      };
    });

    const raw = getExportData(mapped);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const years = [
    { id: 2, name: initialYear + 2 },
    { id: 3, name: initialYear + 1 },
    { id: 4, name: initialYear },
    { id: 5, name: initialYear - 1 },
    { id: 6, name: initialYear - 2 },
  ];

  return (
    <Manage
      title={"Budget"}
      subtitle={year}
      description={"List of all budgets and Targets"}
      actions={
        <div className="flex">
          <Popover
            className="cursor:pointer focus:outline-none hover:text-toyota"
            trigger="click"
            placement="bottom"
            content={
              <div className="flex flex-col w-40">
                {years?.map((item: any) => (
                  <button
                    className="flex cursor-pointer hover:text-toyota text-left focus:outline-none py-1"
                    onClick={() => {
                      handleSelectYear(item?.name);
                      setOpened((o) => !o);
                    }}
                    key={item.id}
                  >
                    <div className={`${year === item?.name && "text-red-600"}`}>
                      {item?.name}
                    </div>
                  </button>
                ))}
              </div>
            }
          >
            <div
              onClick={() => setOpened((o) => !o)}
              className="cursor-pointer"
            >
              <div className="flex flex-row justify-center items-center hover:text-blue-800 text-gray-600 border p-1">
                <div className="">{year}</div>
                <div className="ml-2">
                  <BsChevronDown color={"#4145A8"} />
                </div>
              </div>
            </div>
          </Popover>
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  budget?.months?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Description:
                        item?.description === null ? "-" : item?.description,
                      Status: item?.isActive ? "Active" : "In Active",
                    };
                  }),
                  "Remarks.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
        </div>
      }
    >
      <>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            {budget ? (
              <div className="grid gap-x-0 grid-cols-12">
                <MonthBudget
                  month={budget?.months?.[1]}
                  year={year}
                  number={1}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[2]}
                  year={year}
                  number={2}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[3]}
                  year={year}
                  number={3}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[4]}
                  year={year}
                  number={4}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[5]}
                  year={year}
                  number={5}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[6]}
                  year={year}
                  number={6}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[7]}
                  year={year}
                  number={7}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[8]}
                  year={year}
                  number={8}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[9]}
                  year={year}
                  number={9}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[10]}
                  year={year}
                  number={10}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[11]}
                  year={year}
                  number={11}
                  handleUpdateMonth={handleUpdateMonth}
                />
                <MonthBudget
                  month={budget?.months?.[12]}
                  year={year}
                  number={12}
                  handleUpdateMonth={handleUpdateMonth}
                />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center mt-20">
                <div className="mb-4">
                  <IoWarningOutline size={30} />
                </div>

                <div className="text-gray-600">
                  No Budget Set For Year {year}
                </div>
              </div>
            )}
          </div>
        )}

        <CreateYearBudget
          isVisible={createModal}
          setVisible={setCreateModal}
          handleSelectYear={handleSelectYear}
        />
        <UpdateMonthBudget
          isVisible={updateModal}
          setVisible={setUpdateModal}
          year={year}
          selected={selected}
          number={number}
          _id={budget?._id}
          handleUpdateMonth={handleUpdateMonth}
        />
      </>
    </Manage>
  );
}
