import { Button, Modal, Form, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleUpdateVehicleFeatures } from "../../api/vehicles";
import { handleFetchFeatures } from "../../api/features";
import { useEffect, useState } from "react";
import { GoCheck } from "react-icons/go";

export default function FeaturesUpdate({
  isVisible,
  setVisible,
  chassis,
  vehicle,
}: any) {
  const [selected, setSelected] = useState<any>([]);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateVehicleFeatures,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: features } = useQuery({
    queryKey: ["features"],
    queryFn: handleFetchFeatures,
  });

  const onSubmit = async () => {
    const data = {
      features: selected,
    };
    await mutate({ chassis, data });
    setVisible(false);
  };

  function extractField(array: any, field: any) {
    return array?.map((item: any) => item[field]);
  }

  const handleSelect = (item: any) => {
    if (selected?.includes(item)) {
      setSelected(selected.filter((i: any) => i !== item));
    } else {
      setSelected([...selected, item]);
    }
  };

  useEffect(() => {
    const names = extractField(vehicle?.specifications?.features, "feature");
    setSelected(names || []);
  }, []);

  return (
    <Modal
      open={isVisible}
      title="Features Vehicle"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateVehicleFeatures"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateVehicleFeatures"
        form={form}
        name="normal_login"
        className="login-form"
        layout="vertical"
        onFinish={onSubmit}
      >
        <div className="flex flex-row, justify-between"></div>
        <div className="">
          {features?.map((item: any, index: number) => (
            <button
              onClick={() => handleSelect(item?._id)}
              type="button"
              className="flex items-center"
              key={index}
            >
              <div className="w-4 h-4 border border-gray-400">
                {selected?.includes(item?._id) && (
                  <div className="w-4 h-4 bg-green-600 flex justify-center items-center text-white">
                    <GoCheck />
                  </div>
                )}
              </div>
              <div className="mx-2">{item?.name}</div>
            </button>
          ))}
        </div>
      </Form>
    </Modal>
  );
}
