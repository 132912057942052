import { useState } from "react";
import UpdatePrice from "./UpdatePrice";
import PriceLine from "./PriceLine";
import { FiEdit } from "react-icons/fi";
import roundUSD from "../../../../utils/roundUSD";

export default function Prices({ prices, history, chassis, editable, source }: any) {
  const [priceModal, setPriceModal] = useState<boolean>(false);

  return (
    <div className="">
      <div className="border-b border-t px-4 py-2 flex justify-between bg-gray-100">
        <div className=""> Price </div>
        {editable && (
          <button
            onClick={() => setPriceModal(true)}
            className="border p-1 rounded-md"
          >
            <FiEdit />
          </button>
        )}
      </div>
      <div className="border-t-4"></div>
      <div className="p-4">
        <div className="flex justify-between">
          <div className="">VAT Exclusive:</div>
          <div className="text-gray-600 text-sm">
            {prices?.exclusive ? (
              <span>{roundUSD(prices?.exclusive)} USD</span>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="">VAT Inclusive:</div>
          <div className="text-gray-600 text-sm">
            {prices?.inclusive ? (
              <span>{roundUSD(prices?.inclusive)} USD</span>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
      <div className="">
        {history?.map((price: any, index: number) => (
          <PriceLine price={price} />
        ))}
      </div>
      <UpdatePrice
        isVisible={priceModal}
        setVisible={setPriceModal}
        chassis={chassis}
      />
    </div>
  );
}
