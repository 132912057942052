import { Outlet } from "react-router";
import HomeHeader from "../components/shared/HomeHeader";
import SideBar from "../components/sidebar/SideBar";

export default function MainLayout() {
  return (
    <div className="flex bg-white min-h-screen font-toyota">
      <div className="">
        <SideBar />
      </div>
      <div className="flex-grow overflow-x-auto">
        <HomeHeader />
        <Outlet />
      </div>
    </div>
  );
}
