import FeatureItem from "../../pages/manage/vehicles/components/FeatureItem";

export default function FeatureSetFour({ vehicle }: any) {
  return (
    <div className="">
      <div className="border-t-4 mt-1"></div>
      <div className="grid grid-cols-12 gap-2 mt-2">
        {vehicle?.specifications?.features?.map((item: any, index: number) => (
          <div className="col-span-12 sm:col-span-3 md:col-span-3 border bg-green-50" key={index}>
            <div className="px-4 py-1 text-center">{item?.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
