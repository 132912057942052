import { useState } from "react";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import getBase64 from "../../../../utils/getBase64";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUploadLeadDoc } from "../../../../api/leads";

const { TextArea } = Input;

export default function UploadDocument({
  lead_id,
  isVisible,
  setVisible,
}: any) {
  const queryClient = useQueryClient();
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState();

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUploadLeadDoc,
    onSuccess: () => {
      message.success("Uploaded Successfully");
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
      form.resetFields();
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.validateFields();
    const base64 = await getBase64(selectedFile);

    await mutate({
      lead_id,
      data: { document: base64, ...values },
    });
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile();
    setIsSelected(false);
  };

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  return (
    <Modal
      open={isVisible}
      title={`Upload Document`}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" danger onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="UploadLeadDoc"
          htmlType="submit"
          danger
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Upload
        </Button>,
      ]}
    >
      <Form
        id="UploadLeadDoc"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="Title" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="file"
              rules={[
                {
                  required: true,
                  message: "Please select file",
                },
              ]}
            >
              <Input
                type="file"
                placeholder="Document"
                onChange={changeHandler}
              />
            </Form.Item>
          </Col>
        </Row>
        {isSelected ? (
          <div>
            <p>Filename: {selectedFile?.name}</p>
            <p>Size : {selectedFile?.size / 1000} kb</p>
          </div>
        ) : null}
      </Form>
    </Modal>
  );
}
