import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchLocations = async () => {
  const uri = `api/locations`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateLocation = async (data: any) => {
  const uri = `api/locations`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateLocation = async ({ data, _id }: any) => {
  const uri = `api/locations/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteLocation = async ({ _id }: any) => {
  const uri = `api/locations/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchLocations,
  handleCreateLocation,
  handleUpdateLocation,
  handleDeleteLocation,
};
