export default function ChartHeader({ title, subtitle }: any) {
  return (
    <div className="flex flex-row justify-between items-center  border-b border-gray-200 px-6">
      <div className="py-2">
        <h4 className="text-blue-800">
          <span className="text-gray-900 font-bold text-lg">{title}</span>
          <span className="ml-1"></span>
        </h4>
        <p className="text-gray-600 text-sm">{subtitle}</p>
      </div>

      <div className="">:</div>
    </div>
  );
}
