import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchAllowances = async () => {
  const uri = `api/support/transfers`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateAllowance = async (data: any) => {
  const uri = `api/support/transfers`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateAllowance = async ({ data, _id }: any) => {
  const uri = `api/support/transfers/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteAllowance = async ({ _id }: any) => {
  const uri = `api/support/transfers/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchAllowances,
  handleCreateAllowance,
  handleUpdateAllowance,
  handleDeleteAllowance,
};
