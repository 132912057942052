import { Button, Modal, Form, Input, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUploadVehicles } from "../../../../api/vehicles";
import { useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import formatSize from "../../../../utils/formatSize";

export default function UploadStock({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();

  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    const file = event.target.files[0];

    if (!file.name.includes("xlsx", "xls", "csv")) {
      message.error("Please select excel file");
      return;
    }

    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUploadVehicles,
    onSuccess: () => {
      message.success("Uploaded Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    if (!isSelected) {
      message.error("Please select excel file");
      return;
    }
    const data = new FormData();
    data.append("file", selectedFile);
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setIsSelected(false);
  };

  return (
    <Modal
      open={isVisible}
      title="Upload Stock"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createVehicle"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createVehicle"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col justify-center items-center bg-gray-50 py-4 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <SiMicrosoftexcel color={"#16a34a"} size={80} />
                <div className="text-gray-600 mt-2">File Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
                <div className="">{formatSize(selectedFile?.size)}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Excel"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Excel"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
