import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { handleFetchAllowances } from "../../../../api/allowances";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import roundTZS from "../../../../utils/roundTZS";
import formatMoney from "../../../../utils/formatMoney";
import { GrRefresh } from "react-icons/gr";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import CreateContact from "../../../stock/components/CreateContact";
import CreateLocation from "../../../stock/components/CreateLocation";
import { handleCreateLeadProforma } from "../../../../api/leads";

const { Option } = Select;

export default function CreateProforma({
  setVisible,
  isVisible,
  prepick,
  lead,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isPercentage, setPercentage] = useState<boolean>(true);
  const [contactModal, setContactModal] = useState<boolean>(false);
  const [locationModal, setLocationModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [vehicles, setVehicles] = useState<any>([prepick]);
  const [selectedDis, setSelectedDis] = useState<number>(0);
  const [isAllSelected, setAllSelected] = useState(false);
  const [form] = Form.useForm();
  const [selectedVat, setSelectedVat] = useState<any>({
    category: "S",
    rate: 18,
  });

  const { data: allowances } = useQuery({
    queryKey: ["allowances"],
    queryFn: handleFetchAllowances,
  });

  const { mutate } = useMutation({
    mutationFn: handleCreateLeadProforma,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      clearForm();
      queryClient.invalidateQueries({ queryKey: ["proformas"] });
      queryClient.invalidateQueries({ queryKey: ["leads", lead?._id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleAddItem = (item: any) => {
    setVehicles([...vehicles, item]);
  };

  const onSubmit = async () => {
    let discount: any = {};
    const { transfer, availability } = await form.getFieldsValue();

    discount = {
      amount: Number(selectedDis),
      isPercentage,
    };

    const extracted = vehicles?.map((item: any) => item.chassis);

    const data = {
      user: auth?._id,
      contact: lead?.contact?.entity,
      transfer,
      vat: selectedVat,
      discount,
      availability,
      vehicles: extracted,
      lead: lead?._id,
    };

    await mutate({ data, lead_id: lead?._id });
    setVisible(false);
  };

  const vats = [
    { _id: 1, code: "S", name: "Standard - ( 18 % )" },
    { _id: 2, code: "E", name: "Zero Rated - ( 0 % )" },
  ];

  const onChangeVat = (e: any) => {
    switch (e) {
      case 1:
        setSelectedVat({ category: "S", rate: 18 });
        form.setFieldsValue({ partial_vat: "" });
        break;
      case 2:
        setSelectedVat({ category: "E", rate: 0 });
        break;
      default:
    }
  };

  const allowanceOptions = allowances?.map((allowance: any, index: number) => (
    <Option value={allowance?._id} key={index}>
      {allowance?.location}
    </Option>
  ));

  const availabilityOptions = vehicles?.map((vehicle: any, index: number) => (
    <Option value={vehicle?.car?.availability?.entity} key={index}>
      {vehicle?.car?.availability?.title},{" "}
      {vehicle?.car?.availability?.description}
    </Option>
  ));

  const vatOptions = vats?.map((vat: any, index: number) => (
    <Option value={vat?._id} key={index}>
      {vat?.name} {vat?.value}
    </Option>
  ));

  const clearForm = async () => {
    setPercentage(false);
    form.resetFields();
  };

  const getTotal = () => {
    let total = 0;
    if (vehicles) {
      vehicles.forEach((vehicle: any) => {
        total = total + vehicle?.prices?.exclusive;
      });
    }
    return total;
  };

  const handleRemoveItem = (item: any) => {
    var filtered = vehicles?.filter(function (value: any) {
      return value?.chassis !== item?.chassis;
    });
    setAllSelected(false);
    setVehicles(filtered);
  };

  const handleAddAll = () => {
    var filtered = lead?.vehicles?.filter(function (value: any) {
      return value;
    });
    setVehicles(filtered);
    setAllSelected(true);
    getTotal();
  };

  const handleRemoveAll = () => {
    setVehicles([]);
    setAllSelected(false);
  };

  const getCalculations: any = () => {
    let discount = 0;
    let vat = 0;
    let unit = 0;
    let net = 0;
    let exchange = 0;

    const total = getTotal();
    if (vehicles) {
      if (isPercentage) {
        discount = (total * selectedDis) / 100;
      } else {
        discount = Number(selectedDis);
      }
      vat = ((total - discount) * selectedVat?.rate) / 100;

      exchange = vehicles[0]?.car?.source?.exchange;

      unit = total - discount;

      net = unit + vat;
    }

    return { total, discount, vat, unit, net, exchange };
  };

  const { discount, vat, unit, net, exchange } = getCalculations();

  useEffect(() => {
    setVehicles([prepick] || []);
    form.setFieldsValue({ availability: prepick?.car?.availability?.entity });
  }, [prepick]);

  return (
    <Drawer
      title={`CREATE PROFORMA`}
      placement={"right"}
      width={window.innerWidth > 1000 ? "48%" : "auto"}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="createAllowance"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="grid grid-cols-12">
          <div className="col-span-6">
            <div className="mb-2">
              <span className="text-red-500">*</span> Transport
            </div>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 15 }}>
                <Form.Item
                  name="transfer"
                  rules={[
                    {
                      required: true,
                      message: "Please select transport allowance",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search and select allowance"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allowanceOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Button onClick={() => setLocationModal(true)}>
                  <MdOutlineAddLocationAlt />
                </Button>
              </Col>
              <Col>
                <Button>
                  <GrRefresh />
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="availability"
                  label="Availability"
                  rules={[
                    {
                      required: false,
                      message: "Please select availability",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search and select availability"
                    optionFilterProp="children"
                    className="w-full"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {availabilityOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="col-span-6">
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <Form.Item
                  name="vat_category"
                  label="VAT Category"
                  rules={[
                    {
                      required: false,
                      message: "Please select VAT category",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select VAT category"
                    defaultValue={1}
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => onChangeVat(e)}
                  >
                    {vatOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="discount_amount"
                  label={
                    <div className="flex">
                      <div className="">Discount</div>
                      <div className="ml-2">
                        <Checkbox
                          onChange={(e: any) => {
                            setPercentage(!isPercentage);
                          }}
                          defaultChecked={isPercentage}
                        >
                          in Percentage
                        </Checkbox>
                      </div>
                    </div>
                  }
                  rules={[
                    {
                      required: false,
                      message: "Please enter discount",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    addonAfter={isPercentage ? "%" : "USD"}
                    defaultValue={selectedDis}
                    value={selectedDis}
                    onChange={(e: any) => setSelectedDis(e?.target?.value)}
                    placeholder={`Enter Discount  ${
                      isPercentage ? "( in % )" : "( in amount )"
                    }  `}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>

        <div className="flex items-center justify-between border-b-2 border-gray-300 py-4">
          <div className="flex items-center">
            <div className="">
              {isAllSelected ? (
                <button
                  type="button"
                  className="flex justify-center items-center h-8 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                  onClick={() => handleRemoveAll()}
                >
                  <AiOutlineCheck color="#FFFFFF" />
                </button>
              ) : (
                <button
                  type="button"
                  className="flex justify-center items-center  h-8 w-8 border border-gray-600 hover:bg-gray-500"
                  onClick={() => handleAddAll()}
                >
                  <AiOutlineCheck color="#FFFFFF" />
                </button>
              )}
            </div>
            <div className="ml-2">Select All Vehicles</div>
          </div>
          <div className="">{vehicles?.length}</div>
        </div>

        {lead?.vehicles?.map((vehicle: any, index: number) => (
          <div
            className={`flex justify-between border-t pt-4 ${
              vehicles?.includes(vehicle) ? "bg-green-50" : ""
            }`}
            onClick={() => {
              vehicles?.includes(vehicle)
                ? handleRemoveItem(vehicle)
                : handleAddItem(vehicle);
            }}
            key={index}
          >
            <div className="flex">
              <div className="">
                {vehicles?.includes(vehicle) ? (
                  <button
                    type="button"
                    className="flex justify-center items-center h-8 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                    onClick={() => handleRemoveItem(vehicle)}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="flex justify-center items-center h-8 w-8 border border-gray-600 hover:bg-gray-500"
                    onClick={() => handleAddItem(vehicle)}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                )}
              </div>
              <div className="flex justify-start mx-2">
                <div className="w-16 mx-2">
                  <img
                    src={vehicle?.car?.thumbnail}
                    className="w-full aspect-[4/3] object-contain"
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>

                <div className="ml-2">
                  <div className="">
                    {vehicle?.car?.year} {vehicle?.model?.name}
                  </div>

                  <div className="">Chassis No: {vehicle?.chassis}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="">{formatMoney(vehicle?.prices?.inclusive)}</div>
              <div className="">USD</div>
            </div>
          </div>
        ))}

        <div className="mb-8 mt-4 border-b">
          <div className="">
            <div className="border-b flex justify-between bg-gray-100 p-2">
              <div className="flex">
                <div className="">S/n. </div>
                <div className="ml-2">Vehicle</div>
              </div>

              <div className="flex">
                <div className="w-40 text-right"></div>
                <div className="w-32 text-right">Amount ( USD )</div>
                <div className="w-40 text-right">Amount ( TZS )</div>
              </div>
            </div>
            {vehicles?.map((item: any, index: number) => (
              <div className="border-b flex justify-between p-2" key={index}>
                <div className="flex">
                  <div className="">{index + 1}. </div>
                  <div className="ml-2">
                    {item?.year} {item?.model?.name} - Chassis : {item?.chassis}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-40 text-left"></div>
                  <div className="w-32 text-right">
                    {formatMoney(item?.prices?.exclusive)}
                  </div>
                  <div className="w-40 text-right">
                    {roundTZS(item?.prices?.exclusive * exchange)}
                  </div>
                </div>
              </div>
            ))}

            <div className="border-t flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">
                Discount ( {isPercentage ? `${selectedDis} %` : "Amount"} )
              </div>
              <div className="w-32 text-right">{formatMoney(discount)}</div>
              <div className="w-40 text-right">
                {roundTZS(discount * exchange)}
              </div>
            </div>

            <div className="border-t  flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Sub Total</div>
              <div className="w-32 text-right">{formatMoney(unit)}</div>
              <div className="w-40 text-right">{roundTZS(unit * exchange)}</div>
            </div>

            <div className="border-t border-gray-300 flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Vat ( {selectedVat?.rate} %)</div>
              <div className="w-32 text-right">{formatMoney(vat)}</div>
              <div className="w-40 text-right">{roundTZS(vat * exchange)}</div>
            </div>

            <div className="border-t-2 border-b border-gray-600  flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Grand Total</div>
              <div className="w-32 text-right">{formatMoney(net)}</div>
              <div className="w-40 text-right">{roundTZS(net * exchange)}</div>
            </div>
          </div>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit">
            Create
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
      <CreateContact isVisible={contactModal} setVisible={setContactModal} />
      <CreateLocation isVisible={locationModal} setVisible={setLocationModal} />
    </Drawer>
  );
}
