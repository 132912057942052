import formatMoney from "../../../utils/formatMoney";

export default function InvoiceItemRead({ item }: any) {
  return (
    <div className="grid grid-cols-12 border-t border-black items-center">
      <div className="col-span-5 p-1 border-r border-black  text-left">
        {item?.name}
      </div>
      <div className="col-span-1 p-1 border-r border-black  text-center">
        {item?.quantity || 1}
      </div>
      <div className="col-span-2 p-1  border-r border-black  text-right ">
        {formatMoney(item?.price)}
      </div>
      <div className="col-span-1 p-1 border-r border-black  px-2 text-center">
        {item?.unit || "EA"}
      </div>
      <div className="col-span-1 p-1 border-r border-black  px-2 text-center">
        {formatMoney(item?.discount || 0)}
      </div>
      <div className="col-span-2 p-1  text-right">
        {formatMoney(Number(item?.quantity * item?.price || 0))}
      </div>
    </div>
  );
}
