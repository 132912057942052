import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Col, Form, Row, Button, Input, Modal, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { handleCreateLeadSMS } from "../../../../api/leads";

const CreateSMS = ({ isVisible, setVisible, lead, lead_id }: any) => {

  const [form] = Form.useForm();
    const queryClient = useQueryClient();


    const { mutate, isLoading } = useMutation({
      mutationFn: handleCreateLeadSMS,
      onSuccess: () => {
        message.success("Created Successfully");
        form.resetFields();
        queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
      },
      onError: () => {
        message.error("Something went wrong");
      },
    });

  const formatMobile = (mobile: any) => {
    let num = mobile ? mobile : "";
    let mo = num?.replace(/\s/g, "");
    let trim = mo?.trim();
    let mob = trim?.slice(-9);
    return mob;
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    form.resetFields();
    setVisible(false);
  };


  return (
    <Modal
      open={isVisible}
      title={`Send SMS `}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" danger onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="sendSMS"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Send
        </Button>,
      ]}
    >
      <Form
        id="AddLeadSMS"
        form={form}
        name="add_sms"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="recipient"
              initialValue={`${formatMobile(lead?.contact?.phone)}`}
              rules={[
                {
                  required: true,
                  message: "Please enter recipient",
                },
              ]}
            >
              <Input
                type="text"
                addonBefore="+255"
                placeholder="Recipient"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please enter message",
                },
              ]}
            >
              <TextArea placeholder="Message" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateSMS;
