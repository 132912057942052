import formatMoney from "../../utils/formatMoney";

export default function ServiceBudget({ services }: any) {
  const getTotals = (data: any) => {
    let actual = 0;
    let budget = 0;
    if (data) {
      data?.forEach(async (service: any) => {
        let sub = 0;
        service?.lines?.forEach((line: any) => {
          sub = sub + Number(line?.quantity) * Number(line?.price);
        });
        actual += sub;
        budget += service?.vehicle?.source?.budget;
      });
    }
    let remain = budget - actual;
    let usage = budget === 0 ? 0 : 100 - Math.round((remain / budget) * 100);

    return { actual, budget, remain, usage };
  };


  const { actual, budget, usage }: any = getTotals(services);

  return (
    <div className=" border-gray-300" style={{ fontSize: 14 }}>
      <div className="p-2 border-b border-t bg-gray-100">
        Service Costs vs Budget
      </div>
      <div className="border-t-4"></div>

      <div className="grid grid-cols-12 gap-0  border-gray-300">
        <div className="col-span-10 border-r border-gray-300 px-2 py-1">
          Total Costs To Date
        </div>
        <div className="col-span-2  border-gray-300 px-2 py-1 text-right ">
          {formatMoney(actual)}
        </div>
      </div>

      <div className="grid grid-cols-12 gap-0  border-gray-300">
        <div className="col-span-10 border-r border-gray-300 px-2 py-1">
          Allocated Budget
        </div>
        <div className="col-span-2 border-gray-300 px-2 py-1 text-right ">
          {formatMoney(budget)}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-0 border-t-2 border-b border-gray-300">
        <div className="col-span-10 border-r border-gray-300 px-2 py-1">
          Utilization ( in % )
        </div>
        <div className="col-span-2 border-gray-300 px-2 py-1 text-right ">
          {usage} %
        </div>
      </div>
    </div>
  );
}
