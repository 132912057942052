import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchRemarks = async () => {
  const uri = `api/support/remarks`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateRemark = async (data: any) => {
  const uri = `api/support/remarks`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateRemark = async ({ data, _id }: any) => {
  const uri = `api/support/remarks/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteRemark = async ({ _id }: any) => {
  const uri = `api/support/remarks/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchRemarks,
  handleCreateRemark,
  handleUpdateRemark,
  handleDeleteRemark,
};
