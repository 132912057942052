import { message } from "antd";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext<any>(null!);

function getInitialUser() {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
}

function getInitialAuth() {
  const auth = localStorage.getItem("auth");
  return auth ? JSON.parse(auth) : null;
}

function getInitialToken() {
  const token = localStorage.getItem("token");
  return token ? token : null;
}

const AuthProvider = ({ children, location }: any) => {
  const [user, setUser] = useState<any>(getInitialUser);
  const [token, setToken] = useState<any>(getInitialToken);
  const [auth, setAuth] = useState<any>(getInitialAuth);

  const navigate = useNavigate();

  const signin = (payload: any) => {
    const { user, auth, token } = payload;
    if (token) {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("auth", JSON.stringify(auth));
      localStorage.setItem("token", token);
      setToken(token);
      setUser(user);
      setAuth(auth);
    }
    navigate("/dashboard");
  };

  const updateUser = (user: any) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  const updateAuth = (auth: any) => {
    localStorage.setItem("auth", JSON.stringify(auth));
    setAuth(auth);
  };

  const signout = () => {
    localStorage.setItem("user", "");
    localStorage.setItem("auth", "");
    localStorage.setItem("token", "");
    setUser(null);
    setToken(null);
    setAuth(null);
    navigate("/");
    message.success("Logged out successfully");
  };

  const handleFetchAuth = async () => {
    if (token) {
      setToken(token);
    }
  };

  let value = {
    user,
    setUser,
    token,
    setToken,
    auth,
    setAuth,
    updateAuth,
    signin,
    signout,
    updateUser,
  };

  useEffect(() => {
    handleFetchAuth();
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
