import { AiOutlineCloseSquare } from "react-icons/ai";
import { BsCheck2Square } from "react-icons/bs";
import Moment from "react-moment";
import formatMoney from "../../../../utils/formatMoney";

const DealColumns = [
  {
    title: "S/N",
    width: 40,
    render: (record: any, text: any, index: any) => (
      <span className="text-gray-700 ml-2">{index + 1}</span>
    ),
  },
  {
    title: "Title",
    width: 220,
    render: (record: any) => {
      return (
        <span className="text-gray-700">
          {record?.title ? record?.title : "-"}
        </span>
      );
    },
  },
  {
    title: "Contact",
    width: 100,
    render: (record: any) => {
      return (
        <span className="text-gray-700">
          {record?.contact?.name ? record?.contact?.name : "-"}
        </span>
      );
    },
  },
  {
    title: "Source",
    width: 120,
    render: (record: any) => {
      return (
        <span className="text-gray-700">
          {record?.source?.name ? record?.source?.name : "-"}
        </span>
      );
    },
  },
  {
    title: "Value (USD)",
    width: 120,
    render: (record: any) => {
      return (
        <span className="text-gray-700">
          {record?.value ? formatMoney(record?.value) : "-"}
        </span>
      );
    },
  },
  {
    title: "Next Activity",
    width: 120,
    render: (record: any) => {
      return (
        <span className="text-gray-700">
          {record?.next_activity ? record?.next_activity : "-"}
        </span>
      );
    },
  },

  {
    title: "Participant",
    width: 120,
    render: (record: any) => {
      return <span className="text-gray-700">{record?.creator?.name}</span>;
    },
  },
  {
    title: "Created",
    width: 120,
    render: (record: any) => {
      return <Moment format={"Do MMM,YYYY"}>{record?.createdAt}</Moment>;
    },
  },
  {
    title: "Status",
    width: 120,
    render: (record: any) => {
      return (
        <span className="text-gray-700 capitalize">
          {record?.status ? record?.status?.state : "-"}
        </span>
      );
    },
  },
];

export default DealColumns;
