import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import { useEffect } from "react";
import { handleDoneLeadActivity } from "../../../../api/leads";

const { TextArea } = Input;

export default function DoneActivity({
  lead_id,
  isVisible,
  setVisible,
  selected,
}: any) {


  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleDoneLeadActivity,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const [form] = Form.useForm();

  const dates = {
    date: moment(selected?.date),
    start: moment(selected?.start),
    finish: moment(selected?.end),
  };

  const defaultValues = {
    description: selected?.description,
    feedback: selected?.feedback,
    start_time: dates.start,
    finish_time: dates.finish,
    date: dates.date,
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [selected, form, defaultValues]);

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ data, lead_id, activity_id: selected?._id });
    setVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title={`Schedule is done`}
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="UpdateForm"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="UpdateForm"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="feedback"
              label="Feedback"
              rules={[
                {
                  required: true,
                  message: "Please enter feedback",
                },
              ]}
            >
              <TextArea placeholder="Feedback" style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="date"
              label="Fulfield Date"
              rules={[
                {
                  required: true,
                  message: "Please enter date",
                },
              ]}
            >
              <DatePicker placeholder="Date" format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
