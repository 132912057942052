import { useQuery } from "@tanstack/react-query";
import { handleFetchStockSourceReport } from "../../../../api/reports";
import StockItem from "./StockItem";
import StockPanel from "./StockPanel";
import { useState } from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import Moment from "react-moment";
import { Tooltip as Tip } from "antd";
import { exportStockBySource } from "../../../../utils/stockDataExport";

export default function StockSource({ dates }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const { data: stock } = useQuery({
    queryKey: ["stockSourceReport"],
    queryFn: handleFetchStockSourceReport,
  });

  const handleSelect = ({ item }: any) => {
    setSelected(item);
    setVisible(true);
  };

  function getTop5ByTotal({ sources }: any) {
    if (sources === undefined) {
      return [];
    }
    const sortedData = sources?.sort((a: any, b: any) => b.total - a.total);
    return sortedData.slice(0, 7);
  }

  const top5 = getTop5ByTotal({ sources: stock });

  return (
    <div className="border bg-white text-gray-500 mb-2">
      <div className="flex flex-row justify-between items-center  border-b border-gray-200 pl-6">
        <div className="py-2">
          <h4 className="text-gray-800">
            <span className="text-gray-900 font-bold text-lg">
              Stock By Source
            </span>
          </h4>
          <p className="text-gray-600 text-sm">Vehicles Grouped By Source</p>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center text-gray-500 text-xs">
            {dates?.isRange ? (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment> -{" "}
                <Moment format={"Do MMM, YYYY"}>{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment>
              </div>
            )}
          </div>
          <div className="mx-2">
            <Tip title="Export To Excel">
              <button
                color="#E50000"
                className="flex justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={() => exportStockBySource({ data: stock })}
              >
                <RiFileExcel2Line size={18} />
              </button>
            </Tip>
          </div>
        </div>
      </div>
      <div className="">
        {top5?.map((item: any, index: number) => (
          <StockItem
            item={item}
            name={item?._id}
            count={item?.total}
            index={index}
            handleSelect={handleSelect}
          />
        ))}
      </div>
      <StockPanel
        title="STOCK BY SOURCE"
        subtitle={selected?._id}
        vehicles={selected?.list || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
