import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import Wrap from "../Wrap";
import Main from "./Main";
import { handleFetchPipelineDeals } from "../../../api/deals";
import DealItem from "./components/DealItem";
import { useEffect, useState } from "react";
import AdvancedSearch from "../leads/components/AdvancedSearch";
import { Button } from "antd";
import { RiSearch2Line } from "react-icons/ri";

export default function OngoingDeals() {
  const [advSearch, setAdvSearch] = useState<boolean>(false);

  const initials = {
    customer: "",
    consultant: "",
    model: "",
    source: "",
    status: "",
    startDate: "",
    endDate: "",
    minValue: "",
    maxValue: "",
  };
  const [filters, setFilters] = useState<any>({ ...initials });
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 24,
    total_pages: 1,
    total_count: 60,
  });

  const clearAllFilters = () => {
    setFilters({ ...initials });
  };

  const params = `&customer=${filters?.customer}&consultant=${filters?.consultant}&source=${filters?.source}&status=${filters?.status}&startDate=${filters?.startDate}&endDate=${filters?.endDate}&model=${filters?.model}&minValue=${filters?.minValue}&maxValue=${filters?.maxValue}`;
  const {
    data: crm,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["newDeals", { pagination, params }],
    queryFn: handleFetchPipelineDeals,
  });

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("header");
      if (!header) return;

      const currentScrollY = window.scrollY;
      const headerHeight = header.offsetHeight;

      if (currentScrollY > lastScrollY && currentScrollY > headerHeight) {
        setIsSticky(true);
      } else if (
        currentScrollY < lastScrollY &&
        currentScrollY <= headerHeight
      ) {
        setIsSticky(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Wrap active="Deals" child={<></>} actions={<></>}>
      <Main
        active="New Deals"
        actions={
          <Button
            className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
            onClick={() => {
              setAdvSearch(!advSearch);
            }}
          >
            <div className="flex justify-center items-center">
              <div className="mr-2">Advanced Search</div>
              <div className="border-l px-2">
                <RiSearch2Line color="gray" size={14} />
              </div>
            </div>
          </Button>
        }
      >
        {advSearch && (
          <AdvancedSearch
            setVisible={setAdvSearch}
            clearAllFilters={clearAllFilters}
            handleAdvancedSearch={handleAdvancedSearch}
          />
        )}
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <div className="flex-grow overflow-x-auto">
              <div className="flex">
                {crm?.pipeline?.map((item: any, index: number) => (
                  <div className="flex-shrink-0 w-96 relative" key={index}>
                    <header
                      id="header"
                      className={`bg-gray-50 p-4 border-r border-l border-t border-b-2 ${
                        isSticky ? "fixed w-96 top-0 shadow-md" : ""
                      }`}
                    >
                      <div className="font-bold text-sm text-black">
                        {item?.name}
                      </div>
                    </header>
                    <div className="">
                      {crm?.deals[item?._id]?.map((deal: any) => (
                        <DealItem
                          deal={deal}
                          last={{ id: 1 }}
                          key={deal.id}
                          stages={crm?.pipeline}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Main>
    </Wrap>
  );
}
