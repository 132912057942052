import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, message } from "antd";
import { useEffect } from "react";
import { handleDeleteRemark } from "../../../../api/remarks";

export default function DeleteRemark({ isVisible, setVisible, selected }: any) {
  const { _id } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleDeleteRemark,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["remarks"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { name } = await form.getFieldsValue();
    const data = {
      name,
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [selected, form]);

  return (
    <Modal
      open={isVisible}
      title="Delete Remark"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="deleteRemark"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="deleteRemark"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center justify-center my-8">
          <div>
            Are you Sure you want to
            <span className="text-automark-dark font-bold mx-1">Delete</span>
            Remark
          </div>
          <div className="font-bold text-lg">{selected?.description}?</div>
        </div>
      </Form>
    </Modal>
  );
}
