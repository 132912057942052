import { useQuery } from "@tanstack/react-query";
import { Button, Drawer, Space } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleFetchVehicle } from "../../../api/vehicles";
import Something from "../../../components/shared/Something";

import Documents from "../../manage/vehicles/components/Documents";
import Prices from "../../manage/vehicles/components/Prices";
import Locations from "../../manage/vehicles/components/Locations";
import VehicleImages from "../../manage/vehicles/components/VehicleImages";
import FeatureHeader from "../../../components/vehicle/FeatureHeader";
import FeatureMainImage from "../../../components/vehicle/FeatureMainImage";
import FeatureSetOne from "../../../components/vehicle/FeatureSetOne";
import FeatureSetTwo from "../../../components/vehicle/FeatureSetTwo";
import Shipment from "../../../components/vehicle/Shipment";
import ServiceBudget from "../../../components/vehicle/ServiceBudget";
import ServiceHistory from "../../../components/vehicle/ServiceHistory";
import AgeingWidget from "../../manage/vehicles/components/AgeingWidget";
import Availability from "../../../components/vehicle/Availability";
import FeatureSetThree from "../../../components/vehicle/FeatureSetThree";
import FeatureSetFour from "../../../components/vehicle/FeatureSetFour";
export default function VehicleDetails({
  setVisible,
  isVisible,
  selected,
}: any) {
  const {
    data: vehicle,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["vehicles", selected?.chassis],
    queryFn: () => handleFetchVehicle(selected?.chassis),
  });

  return (
    <Drawer
      title={`VEHICLE DETAILS`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <div className="border-b mb-2 -mt-4">
              <FeatureHeader vehicle={vehicle} editable={false} />
            </div>
            <div className="grid grid-cols-12 gap-x-2">
              <div className="col-span-12 sm:col-span-6">
                <FeatureMainImage thumbnail={vehicle?.thumbnail} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <VehicleImages vehicle={vehicle} editable={false} />
              </div>
            </div>

            <div className="grid grid-cols-12 gap-x-2 mt-2">
              <div className="col-span-12 sm:col-span-6">
                <div className="bg-gray-100 p-2 flex justify-between items-center">
                  <div className="">Vehicle Details</div>
                </div>
                <FeatureSetOne vehicle={vehicle} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <div className="bg-gray-100 p-2 flex justify-between items-center">
                  <div className="">Vehicle Specs</div>
                </div>
                <FeatureSetTwo vehicle={vehicle} />
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-6">
              <div className="bg-gray-100 p-2 flex justify-between items-center">
                <div className="">Vehicle Other Specs</div>
              </div>
              <FeatureSetThree vehicle={vehicle} />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-6">
              <div className="bg-gray-100 p-2 flex justify-between items-center">
                <div className="">Vehicle Features</div>
              </div>
              <FeatureSetFour vehicle={vehicle} />
            </div>

            <div className="grid grid-cols-12 gap-x-2">
              <div className="col-span-12 sm:col-span-6">
                <Shipment
                  arrival={vehicle?.arrival}
                  shipment={vehicle?.shipment}
                  chassis={vehicle?.chassis}
                  editable={false}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Documents
                  documents={vehicle?.documents}
                  chassis={vehicle?.chassis}
                  editable={false}
                />
              </div>
            </div>
            <div className="pb-2">
              <AgeingWidget vehicle={vehicle} />
            </div>

            <div className="pb-2">
              <Availability
                editable={false}
                chassis={vehicle?.chassis}
                availability={vehicle?.availability}
              />
            </div>
            <div className="grid grid-cols-12 gap-x-2">
              <div className="col-span-12 sm:col-span-6">
                <Prices
                  prices={vehicle?.prices}
                  history={vehicle?.priceHistory}
                  chassis={vehicle?.chassis}
                  source={vehicle?.source}
                  editable={false}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Locations
                  prices={vehicle?.prices}
                  movement={vehicle?.movement}
                  vehicle={vehicle}
                  chassis={vehicle?.chassis}
                  editable={false}
                />
              </div>
            </div>
            <div className="mt-8" style={{ fontSize: 14 }}>
                  <ServiceHistory chassis={vehicle?.chassis} />
                </div>
          </div>
        )}
      </div>
    </Drawer>
  );
}
