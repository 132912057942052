import { useQuery } from "@tanstack/react-query";
import { handleFetchLeadModelsReport } from "../../../../api/reports";
import ModelWidget from "./ModelWidget";
import { useState } from "react";
import LeadPanel from "./LeadPanel";
import Moment from "react-moment";
import { Tooltip as Tip } from "antd";
import { RiFileExcel2Line } from "react-icons/ri";

export default function LeadModels({ dates }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const { data: stock } = useQuery({
    queryKey: ["leadModelReport"],
    queryFn: handleFetchLeadModelsReport,
  });

  const handleSelect = ({ item }: any) => {
    setSelected(item);
    setVisible(true);
  };

  return (
    <div className="border bg-white text-gray-500 mb-2">
      <div className="flex flex-row justify-between items-center  border-b border-gray-200 pl-2">
        <div className="py-2">
          <h4 className="text-gray-800">
            <span className="text-gray-900 font-bold text-lg">
              Leads By Model
            </span>
          </h4>
          <p className="text-gray-600 text-sm">Deals Grouped By Model</p>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center text-gray-500 text-xs">
            {dates?.isRange ? (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment> -{" "}
                <Moment format={"Do MMM, YYYY"}>{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment>
              </div>
            )}
          </div>
          <div className="mx-2">
            <Tip title="Export To Excel">
              <button
                color="#E50000"
                className="flex justify-center items-center border px-4 py-1 hover:bg-gray-100"
                // onClick={() => exportStockByFuel({ data: stock })}
              >
                <RiFileExcel2Line size={18} />
              </button>
            </Tip>
          </div>
        </div>
      </div>
      {stock?.map((item: any, index: number) => (
        <ModelWidget item={item} index={index} handleSelect={handleSelect} />
      ))}

      <LeadPanel
        title="LEADS BY VEHICLE MODEL"
        subtitle={selected?._id}
        leads={selected?.list || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
