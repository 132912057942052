import Something from "../../../components/shared/Something";
import Wrap from "../Wrap";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import BackTool from "../../../components/toolbar/BackTool";
import VehicleDetails from "./components/VehicleDetails";
import ActivityHeader from "./components/ActivityHeader";
import DealBasics from "./components/DealBasics";
import DealContacts from "./components/DealContacts";
import DealTasks from "./components/DealTasks";
import DealParticipants from "./components/DealParticipants";
import DealRemarks from "./components/DealRemarks";
import DealActivities from "./components/DealActivities";
import { handleFetchDeal } from "../../../api/deals";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

export default function DetailedDeal() {
  const { id } = useParams();

  const { auth } = useContext(AuthContext);
  const updateAccess = [2, 3, 4];

  const access = updateAccess.includes(auth?.accessLevel);

  const {
    data: deal,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["deals", id],
    queryFn: () => handleFetchDeal({ _id: id }),
  });

  return (
    <Wrap active="Deals" actions={<></>}>
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <>
          <BackTool />
          <div className="m-2 p-2 border bg-white">
            <div className="grid gap-x-4 grid-cols-12 bg-white">
              <div className="col-span-12 sm:col-span-3 md:col-span-3">
                <DealBasics deal={deal || {}} deal_id={id} access={access} />
                <DealContacts deal={deal || {}} />

                <DealTasks deal={deal || {}} deal_id={id} access={access} />

                <DealParticipants deal={deal || {}} deal_id={id} />

                <DealRemarks deal={deal || {}} deal_id={id} />

                <VehicleDetails deal={deal || {}} deal_id={id} />
              </div>
              <div className="col-span-12 sm:col-span-9 md:col-span-9">
                <ActivityHeader
                  deal={deal || {}}
                  deal_id={id}
                  access={access}
                />
                <DealActivities deal={deal || {}} deal_id={id} />
              </div>
            </div>
          </div>
        </>
      )}
    </Wrap>
  );
}
//
