import {
  Button,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Tooltip,
  message,
} from "antd";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
  RiSearch2Line,
} from "react-icons/ri";
import {
  ExportToExcel,
  blobToDataUrl,
  getExportData,
} from "../../../utils/exportExcel";
import { postDataMail } from "../../../services/mail";
import { useQuery } from "@tanstack/react-query";
import { handleFetchModels } from "../../../api/models";
import { handleFetchLocations } from "../../../api/locations";
import moment from "moment";
const { Search } = Input;
const { Option } = Select;

export default function ServiceSearch({
  services,
  clearAllFilters,
  handleAdvancedSearch,
}: any) {
  const [advSearch, setAdvSearch] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [stdForm] = Form.useForm();
  const [advForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  const { data: models } = useQuery({
    queryKey: ["models"],
    queryFn: handleFetchModels,
  });

  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: handleFetchLocations,
  });

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = services?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        "Service Type": item?.service_type === null ? "-" : item?.service_type,
        Chassis: item?.vehicle?.chassis === null ? "-" : item?.vehicle?.chassis,
        Model:
          item?.vehicle?.model?.name === null
            ? "-"
            : item?.vehicle?.model?.name,
        Approval:
          item?.approval?.user === null
            ? "-"
            : item?.approval?.isApproved
            ? "Approved"
            : "Rejected",
        "Service Site":
          item?.location?.name === null ? "-" : item?.location?.name,
        Technician:
          item?.technician?.name === null ? "-" : item?.technician?.name,
        "Vehicle Source":
          item?.vehicle?.source?.name === null
            ? "-"
            : item?.vehicle?.source?.name,

        "Created By": item?.creator?.name === null ? "-" : item?.creator?.name,

        "Created At":
          item?.createdAt === null
            ? "-"
            : moment(item?.createdAt).format("Do, MMM YYYY"),

        Status: item?.status === null ? "-" : item?.status,
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const clearFilters = () => {
    setFilters({});
    clearAllFilters();
  };

  const modelOptions = models?.map((model: any, index: number) => (
    <Option value={model?._id} key={index}>
      {model?.name}
    </Option>
  ));

  const locationOptions = locations?.map((location: any, index: number) => (
    <Option value={location?._id} key={index}>
      {location?.name}
    </Option>
  ));

  return (
    <div className="py-2 px-2 border-b bg-white">
      <div className="flex justify-between w-full">
        <Form id="stdForm" name="stdForm" layout="inline" form={stdForm}>
          <div className="flex">
            <Form.Item name="regNumber">
              <Search
                placeholder="Registration number"
                className="w-52"
                allowClear
                onSearch={(e: any) => handleAdvancedSearch({ registration: e })}
              />
            </Form.Item>
            <Form.Item name="chaNumber">
              <Search
                placeholder="Chassis Number"
                className="w-48"
                allowClear
                onSearch={(e: any) => handleAdvancedSearch({ chassis: e })}
              />
            </Form.Item>
          </div>
          <div className="flex items-center">
            <Button
              className="ml-2 focus:outline-none hover:bg-red-100"
              onClick={() => {
                setAdvSearch(!advSearch);
              }}
            >
              <div className="flex justify-center items-center">
                <div className="mr-2">Advanced Search</div>
                <div className="border-l px-2">
                  <RiSearch2Line color="gray" size={14} />
                </div>
              </div>
            </Button>
            <Button
              className="ml-2 focus:outline-none hover:bg-red-100 "
              onClick={() => {
                stdForm.resetFields();
                advForm.resetFields();
                emailForm.resetFields();
                clearFilters();
              }}
            >
              <RiFilterOffLine color="gray" />
            </Button>
          </div>
        </Form>
        <div className="">
          <div className="flex">
            <Popover
              content={
                <>
                  <Form
                    id="emailForm"
                    name="emailForm"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-end border-t pt-2">
                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                          danger
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                      <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
                        <Button type="primary" danger htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="left"
              open={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={18} />
                </button>
              </Tooltip>
            </Popover>
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={() =>
                  ExportToExcel(
                    services?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        "Service Type":
                          item?.service_type === null
                            ? "-"
                            : item?.service_type,
                        Chassis:
                          item?.vehicle?.chassis === null
                            ? "-"
                            : item?.vehicle?.chassis,
                        Model:
                          item?.vehicle?.model?.name === null
                            ? "-"
                            : item?.vehicle?.model?.name,
                        Approval:
                          item?.approval?.user === null
                            ? "-"
                            : item?.approval?.isApproved
                            ? "Approved"
                            : "Rejected",
                        "Service Site":
                          item?.location?.name === null
                            ? "-"
                            : item?.location?.name,
                        Technician:
                          item?.technician?.name === null
                            ? "-"
                            : item?.technician?.name,
                        "Vehicle Source":
                          item?.vehicle?.source?.name === null
                            ? "-"
                            : item?.vehicle?.source?.name,

                        "Created By":
                          item?.creator?.name === null
                            ? "-"
                            : item?.creator?.name,

                        "Created At":
                          item?.createdAt === null
                            ? "-"
                            : moment(item?.createdAt).format("Do, MMM YYYY"),

                        Status: item?.status === null ? "-" : item?.status,
                      };
                    }),
                    "Services.xlsx"
                  )
                }
              >
                <RiFileExcel2Line size={18} />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>

      {advSearch ? (
        <div className="h-auto  px-2 bg-white">
          <div className="flex justify-between items-center py-4 border-b">
            <div className="text-lg">Advanced Search</div>
            <div className="">
              <button
                onClick={() => setAdvSearch(false)}
                className="focus:outline-none hover:bg-red-100  p-2"
              >
                <AiOutlineClose />
              </button>
            </div>
          </div>
          <div className="h-auto mt-4 flex-1">
            <Form
              id="advForm"
              name="advForm"
              form={advForm}
              onFinish={() => handleAdvancedSearch(filters)}
              layout="vertical"
            >
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <Form.Item
                    name="model"
                    label="Model"
                    rules={[
                      {
                        required: false,
                        message: "Please select model",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Model"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e: any) => {
                        setFilters({ ...filters, model: e });
                      }}
                    >
                      <Option selected value="">
                        All
                      </Option>
                      {modelOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <Form.Item
                    name="status"
                    label="Status"
                    rules={[
                      {
                        required: false,
                        message: "Please select status",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Status"
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e: any) => {
                        setFilters({ ...filters, status: e });
                      }}
                    >
                      <Option selected value="">
                        All
                      </Option>
                      <Option value="new">New</Option>
                      <Option value="paused">Paused</Option>
                      <Option value="pending">Pending</Option>
                      <Option value="progress">Inprogress</Option>
                      <Option value="completed">Completed</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 3 }}>
                  <Form.Item
                    name="approval"
                    label="Approval"
                    rules={[
                      {
                        required: false,
                        message: "Please select approval",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Approval"
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e: any) => {
                        setFilters({ ...filters, approval: e });
                      }}
                    >
                      <Option selected value="">
                        All
                      </Option>
                      <Option value="accepted">Accepted</Option>
                      <Option value="rejected">Rejected</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 3 }}>
                  <Form.Item
                    name="location"
                    label="Location"
                    rules={[
                      {
                        required: false,
                        message: "Please select location",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Location"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e: any) => {
                        setFilters({ ...filters, location: e });
                      }}
                    >
                      <Option selected value="">
                        All
                      </Option>
                      {locationOptions}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <Form.Item
                    name="has_document"
                    label="With PDS Document"
                    rules={[
                      {
                        required: false,
                        message: "Please select option",
                      },
                    ]}
                  >
                    <Select
                      placeholder="With PDS Document"
                      onChange={(e: any) => {
                        setFilters({ ...filters, pds: e });
                      }}
                    >
                      <Option selected value="">
                        PDS Document :
                      </Option>
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" danger htmlType="submit">
                  Search
                </Button>

                <Button
                  type="default"
                  danger
                  className="ml-4"
                  onClick={() => {
                    stdForm.resetFields();
                    advForm.resetFields();
                    emailForm.resetFields();
                    clearFilters();
                  }}
                >
                  Clear
                </Button>
                <Button
                  type="default"
                  className="ml-4"
                  onClick={() => setAdvSearch(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : null}
    </div>
  );
}
