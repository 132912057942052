export default function ConvWidget({ dates }: any) {
  const won = 12;
  const all = 24;

  const perce = (won / all) * 100;

  //   const { data, refetch, isLoading, isFetching, isError } = useQuery({
  //     queryKey: ["dealConvertions"],
  //     queryFn: handleFetchDealConvertionReport,
  //   });

  return (
    <div className="col-span-3 border border-gray-300 p-2 bg-green-100 h-20">
      <div className="">Convertion Rate - [ Won Deals ] </div>
      <div className="text-lg">
        <span className="text-3xl">-</span> / -{" "}
        <span className="font-bold">
          {/* {Math.round(isNaN(perce) ? 0 : perce)} % */}- %
        </span>
      </div>
    </div>
  );
}
