import { upperCase } from "lodash";
import formatMoney from "../../utils/formatMoney";
import getProformaTotals from "../../utils/proformaTotals";

export default function DocumentPricing({ proforma }: any) {
  const { vat, unit, net, exchange } = getProformaTotals(proforma);
  return (
    <div className="mt-2">
      {/* HEADING */}
      <div className="grid grid-cols-12 border border-black bg-automark-dark text-white">
        <div className="col-span-1 text-center font-bold">Sr.no</div>
        <div className="col-span-2 text-center  border-l border-white  font-bold">
          Registration
        </div>
        <div className="col-span-5 text-center  border-l border-white  font-bold">
          Invoice Details / Description
        </div>
        <div className="col-span-2 text-center border-l border-white font-bold">
          Value (USD)
        </div>
        <div className="col-span-2 text-center border-l border-white font-bold">
          Value (TZS)
        </div>
      </div>

      {/* VEHICLES */}
      <div className="">
        {proforma?.vehicles.map((item: any, index: number) => (
          <div className="grid grid-cols-12 border-t border-r border-l border-black">
            <div className="col-span-1 text-center">1</div>
            <div className="col-span-2 border-l border-black pl-2 text-center">
              {item?.registration}
            </div>
            <div className="col-span-5  border-l border-black pl-2">
              {item?.year} {upperCase(item?.model?.name)} chassis No.{" "}
              {item?.chassis}
            </div>
            <div className="col-span-2 text-right border-l border-black pr-2">
              {formatMoney(item?.prices?.exclusive)}
            </div>
            <div className="col-span-2 text-right border-l border-black pr-2">
              {formatMoney(
                item?.prices?.exclusive * item?.source?.exchange ||
                  item?.source?.entity?.exchange
              )}
            </div>
          </div>
        ))}
      </div>

      {/* FEATURES */}

      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 text-center"></div>
        <div className="col-span-2 border-l border-black pl-2 text-center"></div>
        <div className="col-span-5  border-l border-black pl-2">
          Fuel Type : {proforma?.vehicles[0]?.fuel?.fuel_type}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
      </div>
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 text-center"></div>
        <div className="col-span-2 border-l border-black pl-2 text-center"></div>
        <div className="col-span-5  border-l border-black pl-2">
          Engine Type : {proforma?.vehicles[0]?.engine?.engine_type}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
      </div>
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 text-center"></div>
        <div className="col-span-2 border-l border-black pl-2 text-center"></div>
        <div className="col-span-5  border-l border-black pl-2">
          Color : {proforma?.vehicles[0]?.specifications?.fuel?.fuel_type}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
      </div>
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 text-center"></div>
        <div className="col-span-2 border-l border-black pl-2 text-center"></div>
        <div className="col-span-5  border-l border-black pl-2">
          Transmission : {proforma?.vehicles[0]?.specifications?.transmission}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
      </div>

      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 text-center"></div>
        <div className="col-span-2 border-l border-black pl-2 text-center"></div>
        <div className="col-span-5  border-l border-black pl-2">
          Mileage : {proforma?.vehicles[0]?.mileage} KM
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
      </div>

      {/* NOTHING */}

      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 pl-2 text-white">-</div>
        <div className="col-span-2 text-center border-l border-black"></div>
        <div className="col-span-5 text-center border-l border-black"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
      </div>
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 pl-2 text-white">-</div>
        <div className="col-span-2 text-center border-l border-black"></div>
        <div className="col-span-5 text-center border-l border-black"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
      </div>

      {/* SUB TOTALS */}

      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 text-center "></div>
        <div className="col-span-2 text-center border-l border-black"></div>
        <div className="col-span-5 text-right border-l border-black px-2">
          Sub Total
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {formatMoney(unit)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {formatMoney(unit * exchange)}
        </div>
      </div>
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-1 text-center "></div>
        <div className="col-span-2 text-center border-l border-black"></div>
        <div className="col-span-5 text-right border-l border-black px-2">
          VAT 18%
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {formatMoney(vat)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {formatMoney(vat * exchange)}
        </div>
      </div>
      <div className="grid grid-cols-12 border-t-2 border-r border-l border-b border-black">
        <div className="col-span-1 text-center "></div>
        <div className="col-span-2 text-center border-l border-black"></div>
        <div className="col-span-5 text-right border-l border-black px-2">
          Grand Total
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {formatMoney(net)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {formatMoney(net * exchange)}
        </div>
      </div>
    </div>
  );
}
