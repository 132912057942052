import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchAvailabilities = async () => {
  const uri = `api/availability`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateAvailability = async (data: any) => {
  const uri = `api/availability`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateAvailability = async ({ data, _id }: any) => {
  const uri = `api/availability/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteAvailability = async ({ _id }: any) => {
  const uri = `api/availability/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchAvailabilities,
  handleCreateAvailability,
  handleUpdateAvailability,
  handleDeleteAvailability,
};
