export default function DocumentBanks() {
  return (
    <div className="flex flex-col mt-28">
      <div className="bg-automark-dark px-2 text-white">
        Comments or Special Instructions
      </div>
      <div className="italic">
        If making payment by direct deposit, details of the bank account are as
        follows:
      </div>
      <div className="">
        <div className="flex">
          <div className="font-bold w-32">Back Name</div>
          <div className="">Diamond Trust Bank Tanzania Ltd</div>
        </div>
        <div className="flex">
          <div className="font-bold w-32">Branch Name</div>
          <div className="">Nyerere Road Branch</div>
        </div>
        <div className="flex">
          <div className="font-bold w-32">Account Name</div>
          <div className="">Salute Finance Limited</div>
        </div>
        <div className="flex">
          <div className="font-bold w-32">Account Numbers</div>
          <div className="flex">
            <div className="flex">
              <div className="font-bold mr-1">TZS - </div>
              <div className="">7147040001 </div>
            </div>
            <div className="flex ml-2">
              <div className="font-bold mr-1">USD - </div>
              <div className="">0147040002 </div>
            </div>
            <div className="flex ml-2">
              <div className="font-bold mr-1">SWIFT CODE - </div>
              <div className=""> DTKETZTZ</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
