import { TRACE_BASE_URL } from "../utils/urls";
import Cookies from "js-cookie";
const fetchDataTrace = async ({ uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${TRACE_BASE_URL}/${uri}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

const postDataTrace = async ({ data, uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${TRACE_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 201) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

export { fetchDataTrace, postDataTrace };
