import { upperCase } from "lodash";
import getProformaTotals from "../../utils/proformaTotals";
import roundTZS from "../../utils/roundTZS";
import roundUSD from "../../utils/roundUSD";

export default function DocumentPricing({ proforma }: any) {
  const { discount, vat, unit, net, exchange } =
    getProformaTotals(proforma);

  return (
    <div className="mt-2">
      {/* HEADING */}
      <div className="grid grid-cols-12 border border-black">
        <div className="col-span-7 text-center font-bold">Description</div>
        <div className="col-span-1 text-center border-l border-black font-bold">
          Qty
        </div>
        <div className="col-span-2 text-center border-l border-black font-bold">
          Price USD
        </div>
        <div className="col-span-2 text-center border-l border-black font-bold">
          Price TZS
        </div>
      </div>

      {/* VEHICLES */}
      <div className="">
        {proforma?.vehicles?.map((vehicle: any, index: number) => (
          <div className="grid grid-cols-12 border-t border-r border-l border-black" key={index}>
            <div className="col-span-7 pl-2">
              {vehicle?.year} {upperCase(vehicle?.model?.name)} chassis No.{" "}
              {vehicle?.chassis}
            </div>
            <div className="col-span-1 text-center border-l border-black">
              1
            </div>
            <div className="col-span-2 text-right border-l border-black pr-2">
              {roundUSD(vehicle?.prices?.inclusive / 1.18)}
              
            </div>
            <div className="col-span-2 text-right border-l border-black pr-2">
              {roundTZS(
                vehicle?.prices?.inclusive / 1.18 * proforma?.source?.exchange ||
                  vehicle?.source?.entity?.exchange
              )}
            </div>
          </div>
        ))}
      </div>

      {/* DISCOUNT */}
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-7 pl-2">Disount</div>
        <div className="col-span-1 text-center border-l border-black">
          {proforma?.discount?.isPercentage ? (
            <div className="">{proforma?.discount?.amount} %</div>
          ) : (
            <div className="">{proforma?.discount?.amount}</div>
          )}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundUSD(discount)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundTZS(discount * exchange)}
        </div>
      </div>

      {/* UNIT */}
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-7 pl-2">Unit Price</div>
        <div className="col-span-1 text-center border-l border-black"></div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundUSD(unit)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundTZS(unit * exchange)}
        </div>
      </div>

      {/* NOTHING */}
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-7 pl-2 text-white">-</div>
        <div className="col-span-1 text-center border-l border-black"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
        <div className="col-span-2 text-right border-l border-black pr-2"></div>
      </div>

      {/* UNIT PRICE VAT EXCLUSIVE */}
      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-7 pl-2">
          Total Unit Price ( VAT Exclusive )
        </div>
        <div className="col-span-1 text-center border-l border-black"></div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundUSD(unit)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundTZS(unit * exchange)}
        </div>
      </div>

      <div className="grid grid-cols-12 border-t border-r border-l border-black">
        <div className="col-span-7 pl-2">
          Total Unit Price ( VAT Exclusive ) * Quantity
        </div>
        <div className="col-span-1 text-center border-l border-black">1</div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundUSD(unit)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundTZS(unit * exchange)}
        </div>
      </div>

      {/* VAT  */}
      <div className="grid grid-cols-12 border border-black">
        <div className="col-span-7 pl-2">VAT</div>
        <div className="col-span-1 text-center border-l border-black"></div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundUSD(vat)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundTZS(vat * exchange)}
        </div>
      </div>

      {/* VAT INCLUSIVE  */}
      <div className="grid grid-cols-12 border border-black">
        <div className="col-span-7 pl-2 font-bold">
          Total Price ( VAT Inclusive )
        </div>
        <div className="col-span-1 text-center border-l border-black"></div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundUSD(net)}
        </div>
        <div className="col-span-2 text-right border-l border-black pr-2">
          {roundTZS(net * exchange)}
        </div>
      </div>
    </div>
  );
}
