import { useQuery } from "@tanstack/react-query";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import Something from "../../../components/shared/Something";
import { handleFetchContact } from "../../../api/contacts";
import { useParams } from "react-router";
import Manage from "..";
import { Link } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { FiEdit, FiUser } from "react-icons/fi";
import { MdCorporateFare } from "react-icons/md";
import Moment from "react-moment";
import { IoTrashBinOutline } from "react-icons/io5";
import { useState } from "react";
import DatesDetails from "./components/DatesDetails";
import ContactHobbies from "./components/ContactHobbies";
import DeleteContact from "./components/DeleteContact";
import UpdateContact from "./components/UpdateContact";
import ParticularDetails from "./components/ParticularDetails";
import BusinessDetails from "./components/BusinessDetails";
import SocialDetails from "./components/SocialDetails";
import HobbyDetails from "./components/HobbyDetails";
import FileDetails from "./components/FileDetails";

export default function OneContact() {
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(true)
  const { id } = useParams();

  const {
    data: contact,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["contacts", id],
    queryFn: () => handleFetchContact(id),
  });

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Particulars`,
      children: contact?.isCompany ? (
        <BusinessDetails contact={contact} editMode={editMode} />
      ) : (
        <ParticularDetails contact={contact} editMode={editMode} />
      ),
    },
    {
      key: "2",
      label: `Social Networks`,
      children: <SocialDetails contact={contact} editMode={editMode}/>,
    },
    {
      key: "3",
      label: `Important Dates`,
      children: <DatesDetails contact={contact} editMode={editMode}/>,
    },
    {
      key: "4",
      label: `Files`,
      children: <FileDetails contact={contact} editMode={editMode}/>,
    },
    {
      key: "5",
      label: `Hobbies`,
      children: <HobbyDetails contact={contact} editMode={editMode} />,
    },
  ];

  const onChange = (key: string) => {};

  return (
    <Manage
      title={"Contacts"}
      subtitle={"All"}
      description={"List of all contacts"}
      actions={<></>}
    >
      <>
        <div className="">
          <div className="h-8 bg-gray-50 flex items-center border-b px-3">
            <Link to={"/manage/crm/contacts"}>
              <div className="flex items-center">
                <BsChevronLeft />
                <span className="ml-1 text-xs">Back</span>
              </div>
            </Link>
          </div>
        </div>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="px-4 py-2">
            <div className="flex justify-between">
              <div className="flex">
                <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-lg">
                  {contact?.isCompany ? (
                    <div className="flex flex-col justify-center items-center">
                      <MdCorporateFare size={40} />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <FiUser size={40} />
                    </div>
                  )}
                </div>

                <div className="ml-4">
                  <div className="text-lg ">
                    <span className="font-bold mr-1">Customer :</span>
                    <span>{contact?.name}</span>
                  </div>
                  <div className="">
                    <div className="flex items-center">
                      <div className="">
                        Category :
                        {contact?.isCompany ? (
                          <span className="text-green-500"> Company</span>
                        ) : (
                          <span className="text-green-500"> Individual</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm">
                    Registered :
                    <span className="text-gray-500 mx-2">
                      {" "}
                      <Moment format={"Do MMM, YYYY"}>
                        {contact?.registered?.date}
                      </Moment>{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => {
                    setUpdateModal(true);
                  }}
                  className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-blue-100 hover:bg-gray-200 cursor-pointer"
                >
                  <FiEdit />
                </button>

                <button
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                  className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
                >
                  <IoTrashBinOutline />
                </button>
              </div>
            </div>

            <div className="">
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
          </div>
        )}

        <DeleteContact
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={contact || {}}
        />

        <UpdateContact
          isVisible={updateModal}
          setVisible={setUpdateModal}
          contact={contact || {}}
        />
      </>
    </Manage>
  );
}
