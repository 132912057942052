import Wrap from "../Wrap";
import INewInquiries from "./INewInquiries";

export default function NewInquiries() {
  return (
    <Wrap active="Inquiries" child={<></>} actions={<></>}>
      <INewInquiries inCRM={true} />
    </Wrap>
  );
}
