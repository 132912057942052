import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchDealTitles = async () => {
  const uri = `api/crm/titles`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateDealTitle = async (data: any) => {
  const uri = `api/crm/titles`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleUpdateDealTitle = async ({ data, _id }: any) => {
  const uri = `api/crm/titles/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteDealTitle = async ({ _id }: any) => {
  const uri = `api/crm/titles/${_id}`;
  const response = deleteDataAuto({ uri });
  return response;
};

export {
  handleFetchDealTitles,
  handleCreateDealTitle,
  handleUpdateDealTitle,
  handleDeleteDealTitle,
};
