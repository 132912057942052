import { FireOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import {
  handleUpdateDealCold,
  handleUpdateDealWarm,
} from "../../../../api/deals";

export default function WarmWidget({ deal, deal_id, access }: any) {
  const isWarm = deal?.flags?.isWarm;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: isWarm ? handleUpdateDealCold : handleUpdateDealWarm,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["deals", deal_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleWarmColdState = async () => {
    await mutate({ deal_id });
  };
  return (
    <button
      className="flex items-center justify-center"
      onClick={() =>
        access ? handleWarmColdState() : message.error("Sorry, Access Denied")
      }
    >
      <div className="mr-1 capitalize">
        {deal?.flags?.isWarm ? "Warm" : "Cold"}
      </div>
      <div className="focus:outline-none hover:text-gray-700">
        <FireOutlined
          style={{
            fontSize: 15,
            color: `${deal?.flags?.isWarm ? "orange" : ""}`,
          }}
        />
      </div>
    </button>
  );
}
